<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>{{ 'DISTRIBUTION.batch.list.heading' | translate }}</h1>
    </mat-card-title>
    <div class="header-actions">
      <a *ngIf="componentType === 'admin'" mat-raised-button color="primary"
         [routerLink]="['/distribution', 'batches', 'create']">
        + {{ 'SHARED.create' | translate }}
      </a>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'admin'" mat-raised-button class="ml-3"
              (click)="changeComponentType('distribution')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_provider_view' | translate }}
      </button>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'distribution'" mat-raised-button class="ml-3"
              (click)="changeComponentType('admin')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_admin_view' | translate }}
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <mat-expansion-panel class="mb-3">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "PAYMENTS.advanced-filters" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row" [formGroup]="form">
        <!-- Batch Status -->
        <div class="col-4">
          <app-input type="select" formControlName="statuses" appearance="standard" fullWidth="false"
                     [label]="'DISTRIBUTION.cases.list.filters.batch_status' | translate" multiple
                     [selectOptions]="batchStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">
          </app-input>
        </div>
        <!-- Providers -->
        <div class="col-4">
          <app-input type="select" formControlName="distribution_provider_ids" appearance="standard" fullWidth="false"
                     [label]="'DISTRIBUTION.batch.editor.form.distribution_provider_id' | translate" multiple
                     [selectOptions]="distributionProviders" [selectLabel]="'company_name'" [selectValue]="'id'">
          </app-input>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!-- Table List -->
        <mat-table [dataSource]="batches" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch.model.name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }} </td>
          </ng-container>
          <!-- Cases Count -->
          <ng-container matColumnDef="cases_count">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch.list.table.cases_count' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.case_distributions.length }}</td>
          </ng-container>
          <!-- Status -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch.model.status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-left">{{ element.status }}</td>
          </ng-container>
          <!-- Status Updated At -->
          <ng-container matColumnDef="status_updated_at">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch.model.status_updated_at' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.status_updated_at | date }}</td>
          </ng-container>
          <!-- Total Phase 2 Monthly Fee -->
          <ng-container matColumnDef="total_monthly_fee_amount">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.cases.list.table.phase_2_installment_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.total_monthly_fee_amount  | currency: 'EUR'}}</td>
          </ng-container>
          <!-- Total Contract Amount -->
          <ng-container matColumnDef="total_batch_contract_amount">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.cases.list.table.contract_amount' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.total_batch_contract_amount  | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Total Amount Distributed -->
          <ng-container matColumnDef="total_distributed_amount">
            <th mat-header-cell
                *matHeaderCellDef>{{ "DISTRIBUTION.batch.list.table.total_distributed_amount" | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.total_distributed_amount | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Total Funding Amount -->
          <ng-container matColumnDef="total_funded_amount">
            <th mat-header-cell
                *matHeaderCellDef>{{ "DISTRIBUTION.batch.list.table.total_funding_amount" | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.total_funded_amount | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Total Cash Hurdle -->
          <ng-container matColumnDef="total_cash_hurdle_amount">
            <th mat-header-cell
                *matHeaderCellDef>{{ "DISTRIBUTION.batch.list.table.total_cash_hurdle_amount" | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.total_cash_hurdle_amount | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Total Fees Retained -->
          <ng-container matColumnDef="total_fees_retained">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.fees_retained' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <span *ngIf="componentType ==='admin'">{{ element.total_fees_retained  | currency:'EUR' }}</span>
              <span *ngIf="componentType ==='distribution'">
                {{ element.total_provider_fees_retained  | currency:'EUR' }}
              </span>
            </td>
          </ng-container>
          <!-- Progress -->
          <ng-container matColumnDef="progress_bar">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.progress' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <div class="d-flex justify-content-center mt-3">
                <mat-progress-bar class="cash-hurdle-progress" mode="determinate"
                                  [matTooltip]="(element.total_distributed_amount | currency:'EUR') + ' / ' +
                                   (element.total_cash_hurdle_amount | currency:'EUR')"
                                  [value]="element.total_distributed_amount / (element.total_cash_hurdle_amount / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'admin'" class="fees-retained-progress" mode="determinate"
                                  color="accent" [matTooltip]="(element.total_fees_retained | currency:'EUR') + ' / ' +
                                   (element.total_fees_obtainable | currency:'EUR')"
                                  [value]="element.total_fees_retained / (element.total_fees_obtainable / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'distribution'" class="fees-retained-progress"
                                  mode="determinate" color="accent"
                                  [matTooltip]="(element.total_provider_fees_retained | currency:'EUR') + ' / ' +
                                   (element.total_provider_fees_obtainable | currency:'EUR')"
                                  [value]="element.total_provider_fees_retained / (element.total_provider_fees_obtainable / 100)">
                </mat-progress-bar>
              </div>
            </td>
          </ng-container>
          <!-- Actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef style="width: 10%">{{ "SHARED.actions" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="pt-2 pb-2" style="display: flex">
                <a mat-stroked-button [routerLink]="[element.id, 'cases']">
                  {{ 'SHARED.view' | translate }}
                </a>
                <a *ngIf="componentType === 'admin'" class="ml-3" mat-stroked-button
                   [routerLink]="[element.id, 'edit']">
                  {{ 'SHARED.edit' | translate }}
                </a>
              </div>
            </td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && batches && batches.data.length < 1" style="padding: 50px">
          <h3 class="text-center">{{ 'SHARED.no_data' | translate:{models: 'DISTRIBUTION.batch.model_name.plural' | translate} }}</h3>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="footer-actions ml-3"></div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
