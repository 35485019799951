<form class="container" *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
  <div class="row" [formArrayName]="'categories'">
    <div class="col-12" *ngFor="let documentCategory of formCategories.controls; let i=index">
      <div [formGroupName]="i">
        <div [formGroupName]="'document_types'">
          <h2 class="text-primary m-0 mt-3">
            <b>{{ documentCategory.value.name }}</b>
          </h2>
          <div class="row">
            <div class="col"><b>Document Name</b></div>
            <div class="col-2"><b>Document</b></div>
            <div class="col-2"><b>Request Status</b></div>
            <div class="col-1"><b>Request</b></div>
          </div>
          <!-- Document Types -->
          <div class="pl-4" *ngFor="let documentType of documentCategory.value.document_types; let j=index">
            <div class="row justify-content-between align-items-center" [formGroupName]="j">
              <div class="col">
                <h4 class="m-0">{{ documentType.name }}</h4>
                <small *ngIf="documentType.description">({{ documentType.description }})</small>
                <small class="text-warning" *ngIf="documentType.expires_after">
                  Expires in {{ documentType.expires_after }} {{ documentType.expires_after_unit }}s
                </small>
              </div>
              <div class="col-2">
                    <span *ngIf="documentType.documents?.length && documentType.documents[0]">
                      <a (click)="viewDocument(documentType.documents[0].id)">View Document</a>
                    </span>
              </div>
              <div class="col-2">
                <div *ngIf="documentType.document_type_requests?.length">
                  <span *ngIf="documentType.document_type_requests[0].approved === true">Accepted</span>
                  <span *ngIf="documentType.document_type_requests[0].approved === false">Rejected</span>
                  <span *ngIf="documentType.document_type_requests[0].approved === null &&
                        documentType.document_type_requests[0].notified_client">
                        Pending Approval
                      </span>
                  <span *ngIf="documentType.document_type_requests[0].approved === null &&
                        !documentType.document_type_requests[0].notified_client">
                        Pending Notification
                      </span>
                </div>
              </div>
              <div class="col-1 mat-checkbox mat-accent">
                <input class="mat-checkbox-input" type="checkbox" formControlName="preselected">
              </div>
            </div>
          </div>
        </div>
        <!-- Child Categories -->
        <div [formGroupName]="'child_categories'">
          <div class="row" *ngFor="let childCategory of documentCategory.value.child_categories; let j=index">
            <div class="col pl-4" [formGroupName]="j">
              <div [formGroupName]="'document_types'">
                <h4 class="text-primary m-0 mt-3"><b>{{ childCategory.name }}</b></h4>
                <!-- Document Types -->
                <div class="pl-4" *ngFor="let documentType of childCategory.document_types; let k=index">
                  <div class="row justify-content-between align-items-center" [formGroupName]="k">
                    <div class="col">
                      <h4 class="m-0">{{ documentType.name }}</h4>
                      <small *ngIf="documentType.description">({{ documentType.description }})</small>
                      <small class="text-warning" *ngIf="documentType.expires_after">
                        Expires in {{ documentType.expires_after }} {{ documentType.expires_after_unit }}s
                      </small>
                    </div>
                    <div class="col-2">
                    <span *ngIf="documentType.documents?.length && documentType.documents[0]">
                      <a (click)="viewDocument(documentType.documents[0].id)">View Document</a>
                    </span>
                    </div>
                    <div class="col-2">
                      <div *ngIf="documentType.document_type_requests?.length">
                        <span *ngIf="documentType.document_type_requests[0].approved === true">Accepted</span>
                        <span *ngIf="documentType.document_type_requests[0].approved === false">Rejected</span>
                        <span *ngIf="documentType.document_type_requests[0].approved === null &&
                        documentType.document_type_requests[0].notified_client">
                        Pending Approval
                      </span>
                        <span *ngIf="documentType.document_type_requests[0].approved === null &&
                        !documentType.document_type_requests[0].notified_client">
                        Pending Notification
                      </span>
                      </div>
                    </div>
                    <div class="col-1 mat-checkbox mat-accent">
                      <input class="mat-checkbox-input" type="checkbox" formControlName="preselected">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Submit -->
  <div class="row mt-5">
    <div class="m-auto">
      <button mat-raised-button color="primary" type="submit">
        Request Documents
      </button>
    </div>
  </div>
</form>
