import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PaymentPlanType } from '../../../../../../_base-shared/models/Product/PaymentPlanType';
import { OneTimeFeeService } from '../one-time-fee.service';

@Component({
  selector: 'app-one-time-fee-editor',
  templateUrl: './one-time-fee-editor.component.html',
  styleUrls: ['./one-time-fee-editor.component.scss']
})
export class OneTimeFeeEditorComponent implements OnInit, OnDestroy{
  public isLoading = 0;
  public form: UntypedFormGroup;
  public editorType: 'create' | 'edit';
  public oneTimeFee: PaymentPlanType;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private oneTimeFeeService: OneTimeFeeService,
    private toastr: ToastrService,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editor;
    if (this.editorType === 'edit') {
      this.fetchOneTimeFee(+this.route.snapshot.paramMap.get('id'));
    } else {
      this.oneTimeFee = new PaymentPlanType();
      this.buildForm();
    }
  }

  private fetchOneTimeFee(oneTimeFeeId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.oneTimeFeeService.get(oneTimeFeeId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.oneTimeFee = result.data;
        this.buildForm();
      })
    );
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name_en: [this.oneTimeFee.name, Validators.required],
    });
  }

  public onSubmit(): void {
    const observable$ = this.editorType === 'create' ?
      this.oneTimeFeeService.store(this.form.value) :
      this.oneTimeFeeService.update(this.oneTimeFee.id, this.form.value);

    this.subscriptions.push(
      observable$
        .subscribe(
          () => {
            this.router.navigateByUrl('/one-time-fee');
            const messageKey = this.editorType === 'create' ? 'SHARED.item-added' : 'SHARED.item-edited';
            this.toastr.success(this.translateService.instant(messageKey));
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
