import { AppointedCourt } from '../Entity/AppointedCourt';
import { Creditor } from '../Entity/Creditor';
import { CaseCreditorProduct } from '../Product/CaseCreditorProduct';
import { CaseCreditorInvoiceStatus } from '../Status/CaseCreditorInvoiceStatus';
import { CaseCreditorPaymentStatus } from '../Status/CaseCreditorPaymentStatus';
import { CaseCreditorStatus } from '../Status/CaseCreditorStatus';
import { CaseCreditorEntity } from './CaseCreditorEntity';
import { CaseCreditorStatusLog } from './CaseCreditorStatusLog';

export class CaseCreditor {
  id?: number;
  case_id?: number;
  creditor_id?: number;
  status_id?: number;
  payment_status_id?: number;
  case_creditor_invoice_status_id?: number;
  case_creditor_product_id?: number;

  reference_number?: string;
  type: 'claim';
  ownership?: string;
  verified?: boolean | 0 | 1;
  debt_type?: string;
  initial_balance?: number;
  current_balance?: number;
  monthly_payments?: number;
  total_repaid?: number;
  making_payments?: number;
  contract_number: string;
  year_of_hire: number;
  interest: number;
  insurance: boolean | 0 | 1;
  insurance_type?: string;
  insurance_amount_paid: number;
  mortgage_type?: string;
  mortgage_currency?: string;
  mortgage_clause?: boolean | 0 | 1;
  mortgage_expenses_paid: boolean | 0 | 1;
  extrajudicial_claim_sent_at: string | Date;

  created_at?: Date | string;
  updated_at?: Date | string;

  // App helpers
  lender_type: 'collections' | 'standard' | 'high_interest';

  // Relations
  status?: CaseCreditorStatus;
  payment_status?: CaseCreditorPaymentStatus;
  case_creditor_invoice_status?: CaseCreditorInvoiceStatus;
  case_creditor_product?: CaseCreditorProduct;
  creditor?: Creditor;
  appointed_court?: AppointedCourt;
  case_creditor_entities?: CaseCreditorEntity;
  case_creditor_status_changes?: Array<CaseCreditorStatusLog>;
  new_reference: string;
}

export class CaseCreditorPivot extends Creditor {
  pivot?: any;
  case_creditor_product_id: any;
}
