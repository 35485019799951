<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>
          <span *ngIf="editorType === 'create'">{{ 'DISTRIBUTION.batch.editor.heading_create' | translate }}</span>
          <span *ngIf="editorType === 'edit'">{{ 'DISTRIBUTION.batch.editor.heading_edit' | translate }}</span>
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
      </div>
      <!-- Batch Editor -->
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
        <!-- Name -->
        <app-input type="text" formControlName="name" appearance="standard" fullWidth="true"
                   [label]="'DISTRIBUTION.batch.editor.form.name' | translate">
        </app-input>
        <!-- Provider -->
        <app-input type="select" formControlName="distribution_provider_id" appearance="standard" fullWidth="true"
                   [label]="'DISTRIBUTION.batch.editor.form.distribution_provider_id' | translate"
                   [selectOptions]="distributionProviders" [selectLabel]="'company_name'" [selectValue]="'id'">
        </app-input>
        <!-- Status -->
        <app-input type="select" formControlName="status" appearance="standard" fullWidth="true"
                   [label]="'DISTRIBUTION.batch.editor.form.status' | translate"
                   [selectOptions]="batchStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">
        </app-input>
        <!-- Submit -->
        <div class="row mt-5 mb-2">
          <div class="col-12 text-right">
            <app-spinner-btn type="submit" [loading]="isSubmitting"
                             [name]="(editorType === 'create' ? 'SHARED.save' :  'SHARED.update') | translate">
            </app-spinner-btn>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
