import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DripCampaign } from '../../../../../../_base-shared/models/Notification/DripCampaign';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DripCampaignService extends MainBaseApiService {

  index(params = {}) {
    return this.http.get<LaravelResourceResponse<Array<DripCampaign>>>(this.apiUrl + '/drip-campaigns', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  store(dripCampaign: FormData, params = {}) {
    return this.http.post<LaravelResourceResponse<DripCampaign>>(this.apiUrl + '/drip-campaigns', dripCampaign, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number, relations = []): Observable<LaravelResourceResponse<DripCampaign>> {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<DripCampaign>>(this.apiUrl + '/drip-campaigns/' + id, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, dripCampaign: FormData) {
    const params = {_method: 'patch'};
    return this.http.post<LaravelResourceResponse<DripCampaign>>(
      this.apiUrl + '/drip-campaigns/' + id, dripCampaign, {params},
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  destroy(id: number, params = {}) {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/drip-campaigns/' + id, {params})
      .pipe(catchError(response => this.handleError(response)));
  }
}
