import { EntityAdministrator } from '../Entity/EntityAdministrator';
import { Notary } from '../Entity/Notary';
import { Solicitor } from '../Entity/Solicitor';
import { CaseCreditor } from './CaseCreditor';

export class CaseCreditorEntity {
  case_creditor_id?: number;
  administrator_id: number;
  notary_id: number;
  solicitor_id: number;

  // Relations
  case_creditor?: CaseCreditor;
  administrator?: EntityAdministrator;
  notary?: Notary;
  solicitor?: Solicitor;
}
