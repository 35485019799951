import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { DialerTriggersComponent } from './dialer-config-triggers/dialer-triggers.component';
import { DialerListsComponent } from './dialer-configuration/dialer-lists.component';
import { DialerComponent } from './dialer.component';

const routes: Routes = [
  {
    path:        'config/dialer',
    canActivate: [MainPermissionGuard], component: DialerComponent, children: [
      {path: 'triggers', component: DialerTriggersComponent},
      {path: 'lists', component: DialerListsComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DialerRoutingModule {
}
