<!--Desktop version-->
<ng-container *ngIf="desktopVersion">
  <div class="payment-desktop-container client-typography">
    <div class="d-flex flex-row justify-content-center align-items-center h-100 ">
      <div class="row payment-desktop">

        <!--<div *ngIf="isSuccessful" class="pop-up-desktop pt-2" [@fadeAnimation]
             [ngStyle]="{'background-color': isSuccessful ? 'rgb(232 241 238)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
          <p style="color:#479D84" class="d-flex flex-row justify-content-center align-items-center">
            <mat-icon class="mr-2">check</mat-icon>
            {{paymentResponse}}</p>
          <p>{{'CLIENT.auth-payment.success_msg' | translate}}.</p>
          <p style="color:#B60000;cursor: pointer" (click)="dismissPopUp()"
             class="d-flex flex-row justify-content-center align-items-center">
            <mat-icon>cancel</mat-icon>
            {{'CLIENT.auth-payment.dismiss' | translate}}
          </p>
        </div>-->
        <!--<div class="col-md-12 pop-up-desktop pt-2" [@fadeAnimation] *ngIf="isError"-->
        <!--     [ngStyle]="{'background-color': isSuccessful ? 'rgba(71, 157, 132, 0.1)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">-->
        <!--  <p style="color:#B60000" class="text-center">Payment failed.</p>-->
        <!--  <p style="color:#B60000;cursor: pointer"-->
        <!--     class="text-center d-flex flex-row justify-content-center align-items-center"-->
        <!--     (click)="dismissPopUp()">-->
        <!--    <mat-icon class="mr-2">cancel</mat-icon>-->
        <!--    {{'CLIENT.auth-payment.dismiss' | translate}}-->
        <!--  </p>-->
        <!--</div>-->

        <div class="col-6 payment-action px-5 py-5">
          <div class="container-fluid client-typography" style="padding-right: 0px !important;padding-left: 0px;">
            <div class="payment-heading">
              <span class="text-muted">Pagos</span>
              <h2>{{'CLIENT.auth-payment.make_a_payment' | translate}}</h2>
            </div>
          </div>
          <div class="container" style="padding: 0px;">
            <!-- Card Form -->
            <div class="mt-3 w-100">
              <div class="w-100">
                <!--New card form-->
                <div *ngIf="newCardForm" class="w-100">
                  <form [formGroup]="newCardForm" class="w-100"
                        (ngSubmit)="chargeNewPaymentCard(newCardForm)">
                    <!--Amount-->
                    <app-input type="number" formControlName="amount" appearance="standard" [requiredStar]="true"
                               [clientTypography]="true" [showClear]="false" [readonly]="true"
                               [label]="'PAYMENTS.amount' | translate" [fullWidth]="true" [extraLabel]="false">
                    </app-input>
                    <!-- Card Number -->
                    <div class="position-relative w-100">
                      <app-input type="text" formControlName="card_number" appearance="standard"
                                 [label]="'CARD-INFO.card-number' | translate" [requiredStar]="true"
                                 [fullWidth]="true" [clientTypography]="true" [showClear]="false">
                      </app-input>
                      <mat-error class="text-right"
                                 *ngIf="newCardForm.get('card_number').touched && newCardForm.get('card_number').errors?.cardInvalid">
                        Número de tarjeta no válido
                      </mat-error>
                      <img *ngIf="newCardForm.get('brand').value === 'VISA'" class="brand-img" style="width: 50px;"
                           src="../../../../assets/img/visaIcon.png" alt="">
                      <img *ngIf="newCardForm.get('brand').value === 'MASTER'" class="brand-img" style="width: 50px;"
                           src="../../../../assets/img/masterCardIcon.png" alt="">
                    </div>
                    <!-- Expiration Date -->
                    <div class="d-flex flex-row w-100">
                      <div class="w-50 mr-1">
                        <app-input type="text" formControlName="expiry_month" [extraLabel]="false"
                                   [label]="'CLIENT.auth-payment.exp_month'| translate" class="mr-2" [fullWidth]="true"
                                   appearance="standard" [requiredStar]="true"  [clientTypography]="true"></app-input>
                      </div>
                      <div class="w-50">
                        <app-input type="text" formControlName="expiry_year" appearance="standard"
                                   [requiredStar]="true" [clientTypography]="true"
                                   [label]="'CLIENT.auth-payment.exp_year'| translate" [fullWidth]="true"
                                   [extraLabel]="false"></app-input>
                      </div>
                    </div>
                    <mat-error class="text-right" *ngIf="(newCardForm.get('expiry_month').touched && newCardForm.get('expiry_month').errors?.expiryMonthInvalid) ||
                  (newCardForm.get('expiry_year').touched && newCardForm.get('expiry_year').errors?.expiryYearInvalid) ||
                  (newCardForm.get('expiry_year').touched && newCardForm.get('expiry_month').touched && newCardForm.errors?.expiryDateInPast)">
                      Expiración no válida
                    </mat-error>
                    <!-- CVV -->
                    <div>
                      <app-input type="text" formControlName="cvv" label="CVV" appearance="standard"
                                 [requiredStar]="true" [clientTypography]="true"
                                 [fullWidth]="true" [extraLabel]="false">
                      </app-input>
                      <mat-error class="text-right"
                                 *ngIf="newCardForm.get('cvv').touched && newCardForm.get('cvv').errors?.cvcInvalid">
                        Número de control de tarjeta no válido
                      </mat-error>
                    </div>
                    <!-- Submit -->
                    <div class="text-right d-flex">
                      <button type="submit" class="payment-btn" [disabled]="executingPayment || isSuccessful">
                        {{'CASES.single.pay' | translate}}
                      </button>
                    </div>
                  </form>
                  <div *ngIf="executingPayment">
                    <div class="text-center" *ngIf="!paymentResponse">
                      <h2>{{'CARD-INFO.processing' | translate}}</h2>
                      <mat-spinner style="margin: auto"></mat-spinner>
                    </div>
                  </div>
               <!--   <div>
                    <div >
                      <h1 class="text-center">{{paymentResponse}}</h1>
                      <p class="text-center">
                        <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary" ligature="home">
                          check_circle
                        </mat-icon>
                      </p>
                    </div>
                    <div *ngIf="paymentResponse && !isSuccessful">
                      <h1 class="text-center">{{paymentResponse}}</h1>
                      <p class="text-center">
                        <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="warn">report</mat-icon>
                      </p>
                    </div>
                  </div>-->
                </div>
                <!--New card form end-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 payment-info px-5 py-5">
          <div class="heading d-flex flex-row w-100">
            <div class="d-flex flex-column mr-5 mb-2">
              <span class="text-muted">{{'CLIENT.auth-payment.amount_due' | translate}}</span>
              <h2 class="payment-info-value">&euro;{{chargeAmount}}</h2>
            </div>
          </div>
          <!--Pay safe text container-->
          <div
            class="col-icons pay-safe-text d-flex flex-column justify-content-center align-items-start">
            <p style="color: #479D84;"><i class="fas fa-lock mr-2"></i>Pago seguro.</p>
            <p style="color: #479D84;"><i class="fas fa-shield-alt mr-2"></i>Su información es segura.</p>
            <p style="color: #479D84;"><i class="fab fa-cc-visa mr-2"></i>Aceptamos Visa/Mastercard</p>
            <img src="../../../../../assets/img/visa-master.png" alt="" width="50">
          </div>
        </div>
      </div>
    </div>
    <app-client-public-footer></app-client-public-footer>
  </div>
</ng-container>

<!--Mobile version-->
<ng-container *ngIf="!desktopVersion">
  <div class="container-fluid client-typography" style="padding-right: 0px !important;padding-left: 0px;">
    <div *ngIf="isSuccessful" class="pop-up" [@fadeAnimation]
         [ngStyle]="{'background-color': isSuccessful ? 'rgb(232 241 238)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
      <p style="color:#479D84" class="d-flex flex-row justify-content-center align-items-center">
        <mat-icon>check</mat-icon>
        {{paymentResponse}}</p>
      <p>{{'CLIENT.auth-payment.success_msg' | translate}}.</p>
      <p style="color:#B60000; cursor:pointer;" class="d-flex flex-row justify-content-center align-items-center"
         (click)="dismissPopUp()">
        <mat-icon>cancel</mat-icon>
        {{'CLIENT.auth-payment.dismiss' | translate}}
      </p>
    </div>
    <div class="pop-up" [@fadeAnimation] *ngIf="isError"
         [ngStyle]="{'background-color': isSuccessful ? 'rgba(71, 157, 132, 0.1)' : '#f8e5e5', 'border-color': isSuccessful ? '#479D84' : '#B60000'}">
      <p style="color:#B60000">{{'CLIENT.auth-payment.payment_failed' | translate}}.</p>
      <p style="color:#B60000; cursor:pointer;" class="d-flex flex-row justify-content-center align-items-center"
         (click)="dismissPopUp()">
        <mat-icon>cancel</mat-icon>
        {{'CLIENT.auth-payment.dismiss' | translate}}
      </p>
    </div>

    <div class="payment-heading mx-2">
      <h2>{{'CLIENT.auth-payment.make_a_payment' | translate}}</h2>
    </div>
  </div>
  <div class="container">
    <!-- Card Form -->
    <div class="row mt-3">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="w-100">
          <!-- ID Card Authorization -->
          <div class="row flex-column" *ngIf="idCardForm && false">
            <div class="col-12 col-md-6">
              <form [formGroup]="idCardForm" (ngSubmit)="submitIdCardAuthorization(idCardForm)">
                <!-- ID Card -->
                <app-input type="text" formControlName="id_card" label="DNI/NIE"
                           [fullWidth]="true" appearance="standard" [extraLabel]="false"
                           [requiredStar]="true" [clientTypography]="true">
                </app-input>
                <!-- Submit -->
                <div class="text-right d-flex">
                  <app-spinner-btn type="submit" [loading]="submittingIdCard"
                                   [name]="'SHARED.submit' | translate">
                  </app-spinner-btn>
                </div>
              </form>
            </div>
          </div>
          <!-- Card Form -->
          <div *ngIf="cardFormActive">
            <div class="w-100">
              <!-- Existing Card Form -->
              <form [formGroup]="paymentCardsForm" (ngSubmit)="chargeExistingCard(paymentCardsForm)">
                <!-- Card ID -->
                <mat-radio-group *ngIf="paymentCards.length > 0" formControlName="card_id"
                                 class="card-radio-group">
                  <ng-container>
                    <div *ngFor="let card of paymentCards">
                      <mat-radio-button class="card-radio-button client-radio" [value]="card.id"
                                        [disabled]="card.expire === 'expired'">
                        <div class="d-flex card-list-item justify-content-between align-items-center">
                          <div>
                            <img *ngIf="card.card_brand === 'Visa'" class="img-fluid"
                                 src="../../../../assets/img/visaIcon.png" alt="">
                            <img *ngIf="card.card_brand === 'Mastercard'" class="img-fluid"
                                 src="../../../../assets/img/masterCardIcon.png" alt="">
                          </div>
                          <div class="text-right">
                            <mat-hint class="d-block">**** **** **** {{ card.card_last_four }}</mat-hint>
                            <div>
                              <mat-hint class="pr-3" *ngIf="card.expire === 'soon'" style="color: #ff8100">Soon
                                expiring
                              </mat-hint>
                              <mat-hint class="pr-3" *ngIf="card.expire === 'expired'" style="color: red">Card
                                expired
                              </mat-hint>
                              <mat-hint>{{ card.card_exp_month}} / {{card.card_exp_year }}</mat-hint>
                            </div>
                          </div>
                        </div>
                      </mat-radio-button>
                    </div>
                  </ng-container>
                  <!--<div class="row">
                    <div class="col-lg-12">
                      <mat-radio-button class="card-radio-button" [value]="null">
                        {{"CARD-INFO.new-card" | translate}}
                      </mat-radio-button>
                    </div>
                  </div>-->
                </mat-radio-group>
                <!-- Amount -->
                <app-input type="number" formControlName="amount" [hidden]="paymentCardsForm.get('card_id').value === null"
                           [label]="'PAYMENTS.amount' | translate" [suffix]="'€'" [fullWidth]="true"
                           [extraLabel]="true"
                           [readonly]="true">
                </app-input>
                <!-- Submit -->
                <div class="text-right d-flex">
                  <app-spinner-btn type="submit" class="mt-3"
                                   *ngIf="paymentCardsForm.get('card_id').value !== null"
                                   [loading]="executingPayment" [name]="'CASES.single.pay' | translate"
                                   [disabled]="executingPayment || isSuccessful">
                  </app-spinner-btn>
                </div>
              </form>
              <div *ngIf="executingPayment">
                <div class="text-center" *ngIf="!paymentResponse">
                  <h2>{{'CARD-INFO.processing' | translate}}</h2>
                  <mat-spinner style="margin: auto"></mat-spinner>
                </div>
              </div>
              <!--<div>
                <div *ngIf="paymentResponse && isSuccessful">
                  <h1 class="text-center">{{paymentResponse}}</h1>
                  <p class="text-center">
                    <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary"
                              ligature="home">
                      check_circle
                    </mat-icon>
                  </p>
                </div>
                <div *ngIf="paymentResponse && !isSuccessful">
                  <h1 class="text-center">{{paymentResponse}}</h1>
                  <p class="text-center">
                    <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="warn">report</mat-icon>
                  </p>
                </div>
              </div>-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 col-icons">
              <p class="pb-3"><i class="fas fa-lock"></i>Pago seguro.</p>
              <p class="pb-3"><i class="fas fa-shield-alt"></i>Su información es segura.</p>
              <p class="pb-1"><i class="fab fa-cc-visa"></i>Aceptamos Visa/Mastercard</p>
              <img src="../../../../assets/img/visa-master.png" alt="" width="100" style="padding-top: 50px">
              <!--        <app-trustbox></app-trustbox>-->
            </div>
          </div>
          <!--New card form-->
          <div *ngIf="newCardForm" class="w-100">
            <form [formGroup]="newCardForm" class="w-100"
                  (ngSubmit)="chargeNewPaymentCard(newCardForm)">
              <!--Amount-->
              <app-input type="number" formControlName="amount" appearance="standard" [requiredStar]="true"
                         [clientTypography]="true" [showClear]="false" [readonly]="true"
                         [label]="'PAYMENTS.amount' | translate" [fullWidth]="true" [extraLabel]="false">
              </app-input>
              <!-- Card Number -->
              <div class="position-relative w-100">
                <app-input type="text" formControlName="card_number" appearance="standard"
                           [label]="'CARD-INFO.card-number' | translate" [requiredStar]="true"
                           [clientTypography]="true" [fullWidth]="true" [showClear]="false">
                </app-input>
                <mat-error class="text-right"
                           *ngIf="newCardForm.get('card_number').touched && newCardForm.get('card_number').errors?.cardInvalid">
                  Número de tarjeta no válido
                </mat-error>
                <img *ngIf="newCardForm.get('brand').value === 'VISA'" class="brand-img" style="width: 50px;"
                     src="../../../../assets/img/visaIcon.png" alt="">
                <img *ngIf="newCardForm.get('brand').value === 'MASTER'" class="brand-img" style="width: 50px;"
                     src="../../../../assets/img/masterCardIcon.png" alt="">
              </div>
              <!-- Expiration Date -->
              <div class="d-flex flex-row w-100">
                <div class="w-50 mr-1">
                  <app-input type="text" formControlName="expiry_month" [extraLabel]="false"
                             [label]="'CLIENT.auth-payment.exp_month'| translate" class="mr-2" [fullWidth]="true"
                             appearance="standard" [requiredStar]="true"  [clientTypography]="true"></app-input>
                </div>
                <div class="w-50">
                  <app-input type="text" formControlName="expiry_year" appearance="standard"
                             [requiredStar]="true" [clientTypography]="true"
                             [label]="'CLIENT.auth-payment.exp_year'| translate" [fullWidth]="true"
                             [extraLabel]="false"></app-input>
                </div>
              </div>
              <mat-error class="text-right" *ngIf="(newCardForm.get('expiry_month').touched && newCardForm.get('expiry_month').errors?.expiryMonthInvalid) ||
                  (newCardForm.get('expiry_year').touched && newCardForm.get('expiry_year').errors?.expiryYearInvalid) ||
                  (newCardForm.get('expiry_year').touched && newCardForm.get('expiry_month').touched && newCardForm.errors?.expiryDateInPast)">
                Expiración no válida
              </mat-error>
              <!-- CVV -->
              <div>
                <app-input type="text" formControlName="cvv" label="CVV" appearance="standard"
                           [requiredStar]="true" [clientTypography]="true"
                           [fullWidth]="true" [extraLabel]="false">
                </app-input>
                <mat-error class="text-right"
                           *ngIf="newCardForm.get('cvv').touched && newCardForm.get('cvv').errors?.cvcInvalid">
                  Número de control de tarjeta no válido
                </mat-error>
              </div>
              <!-- Submit -->
              <div class="text-right d-flex">
                <button type="submit" class="correct-btn w-100" [disabled]="executingPayment || isSuccessful">
                  {{'CASES.single.pay' | translate}}
                </button>
              </div>
            </form>
            <div *ngIf="executingPayment">
              <div class="text-center" *ngIf="!paymentResponse">
                <h2>{{'CARD-INFO.processing' | translate}}</h2>
                <mat-spinner style="margin: auto"></mat-spinner>
              </div>
            </div>
           <!-- <div>
              <div *ngIf="paymentResponse && isSuccessful">
                <h1 class="text-center">{{paymentResponse}}</h1>
                <p class="text-center">
                  <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary" ligature="home">
                    check_circle
                  </mat-icon>
                </p>
              </div>
              <div *ngIf="paymentResponse && !isSuccessful">
                <h1 class="text-center">{{paymentResponse}}</h1>
                <p class="text-center">
                  <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="warn">report</mat-icon>
                </p>
              </div>
            </div>-->
          </div>
          <!--New card form end-->
        </div>
      </div>
    </div>
  </div>
  <app-client-public-footer></app-client-public-footer>
</ng-container>
<!--End new form-->
<!--<app-client-public-footer></app-client-public-footer>-->
