import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector:    'app-mymoid-iframe-redirect',
  templateUrl: './mymoid-iframe-redirect.component.html',
  styles:      [],
})
export class MymoidIFrameRedirectComponent implements OnInit {
  private appUrl: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.appUrl               = environment.APP_URL;
    const transactionableUuid = decodeURI(this.route.snapshot.queryParamMap.get('transactionableUuid'));

    if (!!this.route.snapshot.data.success) {
      window.top.location.href = this.appUrl +
          '/client/authorization-callbacks/mymoid/success?transactionableUuid=' + transactionableUuid;
    } else {
      window.top.location.href = this.appUrl +
          '/client/authorization-callbacks/mymoid/error?transactionableUuid=' + transactionableUuid;
    }
  }

}
