import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseContract } from '../../../../../../../_base-shared/models/CaseDocument/CaseContract';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { VerifySignatureComponent } from '../../../../_shared/components/verify-signature/verify-signature.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-document-list',
  templateUrl: './case-document-list.component.html',
  styleUrls:   ['./case-document-list.component.scss']
})
export class CaseDocumentListComponent implements OnInit, OnDestroy {
  public authUser: User;
  public case: Case;

  public storageUrl = environment.STORAGE_URL + '/';
  public contractPdfLocation: string;
  public mandatePdfLocation: string;
  public mandatePdfLocationPartner: string;

  // Loaders
  public isLoading            = 0;
  public regeneratingContract = false;
  public requestingResign     = false;
  public sendingContract      = false;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['contracts']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.case                      = result.data;
        this.contractPdfLocation       = this.case.contracts[0]?.pdf_location;
        this.mandatePdfLocation        = this.case.contracts[0]?.mandate_location;
        this.mandatePdfLocationPartner = this.case.contracts[0]?.mandate_location_1;
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public requestDebtCancellationRequestSignature() {
    this.caseDocumentService.requestDebtCancellationRequestSignature(this.case.id).subscribe(res => {
      this.toastr.success(
        this.translate.instant(
          'CASES.single.documents.debt_cancellation_request.request_signature.response.success'),
      );
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public verifySignature(client: User): void {
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width: '50%',
      height: '50%',
      data: {
        case: this.case,
        user: client
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data?.result) {
        if (this.case.user_id === client.id) {
          this.case.client.signature_verified_at = result?.data.created_at;
        } else {
          this.case.partner.signature_verified_at = result?.data.created_at;
        }
      }
    });
  }

  public regenerateContract(): void {
    let contractToRegen: CaseContract;
    this.case.contracts.every(contract => {
      if (contract.signature) {
        contractToRegen = contract;
        return false;
      }
      return true;
    });

    if (!contractToRegen) {
      contractToRegen = this.case.contracts[0];
    }

    this.regeneratingContract = true;
    this.caseDocumentService.regenerateContract(this.case.id, contractToRegen.id)
      .pipe(finalize(() => this.regeneratingContract = false))
      .subscribe(
        next => {
          this.contractPdfLocation       = next.data.pdf_location;
          this.mandatePdfLocation        = next.data.mandate_location;
          this.mandatePdfLocationPartner = next.data.mandate_location_1;
          this.toastr.success(
            this.translate.instant('CASES.single.regenerate-contract-response'),
            this.translate.instant('SHARED.success')
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.regenerate-contract-error'),
            this.translate.instant('SHARED.error')
          );
        }
      );
  }

  public resignContract($event: MouseEvent, type: string, client_type: string): void {
    $event.preventDefault();
    const data            = {
      type,
      client_type
    };
    this.requestingResign = true;
    this.caseDocumentService.resignContract(this.case.id, data)
      .pipe(finalize(() => this.requestingResign = false))
      .subscribe(
        next => {
          this.toastr.success(
            this.translate.instant('CASES.single.resign-contract-response'),
            this.translate.instant('SHARED.success')
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.resign-contract-error'),
            this.translate.instant('SHARED.error')
          );
        }
      );
  }

  public sendSignedContract(): void {
    this.sendingContract = true;
    this.caseDocumentService.sendSignedContract(this.case.id).pipe(finalize(() => this.sendingContract = false))
      .subscribe(
        res => this.toastr.success(this.translate.instant('CASES.single.successfully_sent')),
        err => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }
}
