import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BankiaErrorComponent } from './bankia/bankia-error/bankia-error.component';
import { BankiaSuccessComponent } from './bankia/bankia-success/bankia-success.component';
import { MymoidIFrameRedirectComponent } from './mymoid/mymoid-iframe-redirect/mymoid-iframe-redirect.component';
import { MymoidResponseComponent } from './mymoid/mymoid-response/mymoid-response.component';

const routes: Routes = [
  {
    path: 'client', children: [
      {
        path:     'authorization-callbacks',
        children: [
          {
            path: 'bankia', children: [
              {path: 'success', component: BankiaSuccessComponent},
              {path: 'error', component: BankiaErrorComponent},
            ],
          },
          {
            path: 'mymoid', children: [
              {path: 'success', component: MymoidResponseComponent, data: {success: true}},
              {path: 'error', component: MymoidResponseComponent, data: {success: false}},
              {
                path: 'iframe', children: [
                  {path: 'success', component: MymoidIFrameRedirectComponent, data: {success: true}},
                  {path: 'error', component: MymoidIFrameRedirectComponent, data: {success: false}},
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class IntegrationRoutingModule {
}
