import { Status } from './Status/Status';
import { User } from './User/User';
import { Packager } from "./Packager/Packager";

export class Affiliate {
  public id?: number;
  public packager_id?: number;
  public name: string;
  public overload_status_id?: number;
  public overwrite_cliente_regreso_status_id?: number;
  public track_id: any;

  public active: boolean;
  public allow_without_debt: boolean;
  public allow_under_5000: boolean;
  public do_not_allow_overwrite: boolean;
  public allow_duplicates: boolean;
  public is_df_affiliate = false;
  public allow_overwrite_df_affiliates = false;
  public allow_overwrite_non_df_affiliates = false;

  created_at?: string | Date;
  updated_at?: string | Date;

  overload_status?: Status;
  postback_url: any;
  tracking_type: any;
  user?: any;
  users?: Array<User>;
  packager?: Packager;
}
