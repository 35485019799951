import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-creditor-document-list',
  templateUrl: './case-creditor-document-list.component.html',
  styleUrls:   []
})
export class CaseCreditorDocumentListComponent implements OnInit, OnDestroy {
  public isLoading                           = 0;
  public case: Case;
  public caseCreditor: CaseCreditor;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private caseService: CaseService,
              private caseCreditorService: CaseCreditorService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseCreditorId = +params.get('id');
      this.route.parent.parent.parent.paramMap.subscribe(p => {
        const caseId = +p.get('id');
        this.fetchCase(caseId);
        this.fetchCaseCreditor(caseId, caseCreditorId);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchCase(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, ['case_creditors']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case = result.data;
        }),
    );
  }

  private fetchCaseCreditor(caseId: number, caseCreditorId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.showCaseCreditor(caseId, caseCreditorId,
        ['case_creditor_entities.administrator', 'case_creditor_entities.notary', 'case_creditor_entities.solicitor'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseCreditor = result.data),
    );
  }

}
