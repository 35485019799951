import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../case.service';
import { DocumentRequestListComponent } from './document-request-list/document-request-list.component';
import {
  RequestedDocumentUploaderComponent,
} from './requested-document-uploader/requested-document-uploader.component';

@Component({
  selector:    'app-case-document-list-deprecated-concept',
  templateUrl: './case-document-list-deprecated-concept.component.html',
  styles:      [],
})
export class CaseDocumentListDeprecatedConceptComponent implements OnInit {
  @ViewChild(DocumentRequestListComponent) documentRequestListComponent: DocumentRequestListComponent;
  @ViewChild(RequestedDocumentUploaderComponent) requestedDocumentUploaderComponent: RequestedDocumentUploaderComponent;

  public case: Case;
  public isLoading = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.case = result.data;
      });
    });
  }

  public reloadRequestedList() {
    this.requestedDocumentUploaderComponent.reloadRequestedDocumentsList();
  }

  public rebuildRequestForm() {
    this.documentRequestListComponent.rebuildForm();
  }
}
