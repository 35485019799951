import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { AppDocument } from '../../../../../../_base-shared/models/Document/AppDocument';
import { User } from '../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../environments/environment';
import { CaseDocumentService } from '../../../admin/case/case-document.service';
import { CaseService } from '../../../admin/case/case.service';
import { DocumentTypeService } from '../../../admin/document/document-type.service';

@Component({
  selector: 'app-verify-signature',
  templateUrl: './verify-signature.component.html',
  styleUrls: ['./verify-signature.component.scss'],
})
export class VerifySignatureComponent implements OnInit {
  public isLoading  = 0;
  public case: Case;
  public clientRole: 'client' | 'partner';
  public user: User;
  public userSignature: string;
  public dniDocument: AppDocument;
  public verifyCreditor: boolean;
  public storageUrl = environment.STORAGE_URL + '/';

  constructor(
    private toast: ToastrService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<VerifySignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
    private documentTypeService: DocumentTypeService) {
  }

  ngOnInit(): void {
    this.case           = this.data.case;
    this.user           = this.data.user;
    this.clientRole     = this.case.user_id === this.user.id ? 'client' : 'partner';
    this.verifyCreditor = this.data.verifyCreditor;
    this.fetchUserDniDocument(this.case, this.user);
    this.fetchUserSignature(this.case);
  }

  public closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  public verifySignature() {
    this.caseService.verifySignature(this.data.case.id, {signature_verified: true}).subscribe(
      result => {
        this.toast.success(this.translate.instant('CASES.details.signature-verified'));
        this.closeDialog(result.data);
      },
      error => this.toast.error(this.translate.instant('CASES.details.signature-verified-error'))
    );
  }

  private fetchUserDniDocument(clientCase: Case, client: User) {
    this.isLoading++;
    this.documentTypeService.getClientCaseDocumentViaType(clientCase.id, client.id, 'dni', ['files'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.dniDocument = result.data);
  }

  private fetchUserSignature(clientCase: Case) {
    this.isLoading++;
    this.caseService.get(clientCase.id, ['contracts']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.userSignature = this.clientRole === 'client' ?
        result.data.contracts[0]?.signature :
        result.data.contracts[0]?.signature_1
    });
  }
}
