import { NgModule } from '@angular/core';
import { CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule } from 'angular-calendar';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from '../../_shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { MiDashboardRoutingModule } from './mi-dashboard/mi-dashboard-routing.module';
import { MiDashboardComponent } from './mi-dashboard/mi-dashboard.component';
import { ClaimsDashboardComponent } from './claims-dashboard/claims-dashboard.component';
import { ClaimsDashboardRoutingModule } from './claims-dashboard/claims-dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardComponent,
    MiDashboardComponent,
    ClaimsDashboardComponent
  ],
  imports:      [
    SharedModule,
    ChartsModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    CalendarCommonModule,
    MiDashboardRoutingModule,
    ClaimsDashboardRoutingModule
  ],
  exports:      [
    DashboardComponent,
  ],
})
export class DashboardModule {
}
