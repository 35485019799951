<div class="row">
  <div class="col-12">
    <div [formGroup]="form">
  <div class="row pb-3">
    <div class="col-12">
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="from" formControlName="from" (dateChange)="onDateChange($event, 'from')">
        <button
          type="button"
          *ngIf="form.value.from"
          mat-button matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'from')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from color="primary"></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input id="to" matInput [matDatepicker]="to" formControlName="to" (dateChange)="onDateChange($event, 'to')">
        <button
          type="button"
          *ngIf="form.value.to"
          mat-button matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'to')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to color="primary"></mat-datepicker>
      </mat-form-field>

      <div class="d-inline-block pl-5">
        <mat-radio-group formControlName="dateRadio" aria-label="Select an option" (change)="onRadioChange($event)">
          <mat-radio-button value="today" class="mr-2">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="this-week" class="mr-2">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="this-month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <table mat-table [dataSource]="claimsData" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="ppi_claims_sent">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.ppi_claims_sent" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ppi_claims_sent}} </td>
      </ng-container>

      <ng-container matColumnDef="ppi_claims_accepted">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.ppi_claims_accepted" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ppi_claims_accepted}} </td>
      </ng-container>

      <ng-container matColumnDef="value_of_ppi_accepted">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.ppi_accepted_win_amount" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ppi_accepted_win_amount}} </td>
      </ng-container>

      <ng-container matColumnDef="value_of_ppi_in_default">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.ppi_default_win_amount" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ppi_default_win_amount}} </td>
      </ng-container>

      <ng-container matColumnDef="ppi_claims_older_60_days">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.ppi_claims_older_60_days" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.ppi_claims_older_60_days}} </td>
      </ng-container>

      <ng-container matColumnDef="ppi_demands_submitted">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.demanda_presentada_cases" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.demanda_presentada_cases}} </td>
      </ng-container>

      <ng-container matColumnDef="ppi_court_wins">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.sentece_estimated_cases" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.sentece_estimated_cases}} </td>
      </ng-container>

      <ng-container matColumnDef="right_of_access_older_30_days">
        <th mat-header-cell *matHeaderCellDef> {{"DASHBOARD.right_of_access_older_30_days" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.right_of_access_older_30_days}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div *ngIf="isLoading" class="text-center" style="padding: 50px">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
