import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NotificationFromEvent } from '../../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { SmsTemplate } from '../../../../../../../_base-shared/models/Notification/SmsTemplate';
import { SmsService } from '../sms.service';

@Component({
  selector:    'app-sms-list',
  templateUrl: './sms-list.component.html',
  styleUrls:   ['./sms-list.component.scss'],
})
export class SmsListComponent implements OnInit {

  public notifications: NotificationFromEvent[];
  displayedColumns: string[] = [
    'id',
    'label',
    'description',
    // 'fromable',
    'subject',
    'active',
    'actions',
  ];
  actions: string[]          = ['Edit', 'Delete'];
  public isLoading           = 0;
  public dataSource: MatTableDataSource<SmsTemplate>;
  public paginatorConfig     = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search              = new UntypedFormControl('');
  public searchFocus         = false;

  constructor(
      private smsService: SmsService,
      private toastr: ToastrService,
      private translate: TranslateService,
  ) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.fetchSmsTemplates();
    this.search.valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged(),
        )
        .subscribe(() => this.fetchSmsTemplates());
  }

  fetchSmsTemplates() {
    this.dataSource = new MatTableDataSource<SmsTemplate>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };

    this.smsService.index(data)
        .pipe(
            finalize(() => this.isLoading--),
        )
        .subscribe(res => {
          this.notifications          = res.data;
          this.dataSource             = new MatTableDataSource<SmsTemplate>(res.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = res.data.total;
        });
  }

  openDeleteDialog(smsId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CONFIG.sms-template.delete-sms-template-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteSms(smsId);
      }
    });
  }

  deleteSms(smsId: number) {
    this.smsService.delete(smsId)
        .subscribe(res => {
              this.fetchSmsTemplates();
              this.toastr.success(
                  this.translate.instant('CONFIG.sms-template.delete-sms-template-success'),
                  this.translate.instant('SHARED.success'),
              );
            },
            error => {
              this.toastr.error(this.translate.instant('CONFIG.sms-template.delete-sms-template-error'),
                  this.translate.instant('SHARED.error'),
              );
            });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchSmsTemplates();
  }

  onChange() {
    this.fetchSmsTemplates();
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

}
