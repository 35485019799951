<div *ngIf="isLoading" class="pt-4 pl-4">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<div *ngIf="serverResponse" class="row">
  <div class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<div class="mb-4">
  <div class="card-header" style="border: 1px solid #e4e4e4;">
    <h2 class="text-center m-0">{{ "CASES.single.payment-history" | translate }}</h2>
  </div>
  <div class="card-body p-0">
    <div class="mat-elevation-z5" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
      <table style="width: 100%" mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.date" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.payment_date | toDateObject | date}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>{{"CASES.single.amount" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.amount | number: '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="splitting_type">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.split-type" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.splitting_type === 'even' ?
              'Even Split' :
              element.splitting_type === 'reduce' ? 'Reduce Terms' : 'Custom'}}</td>
        </ng-container>

        <ng-container matColumnDef="payment_method">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.payment-method" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ !element.payment_method ?
              'Not Selected' :
              ('PAYMENTS.payment_method.options.' + element.payment_method) | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.note" | translate }}</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.note"></td>
        </ng-container>

        <ng-container matColumnDef="done_by">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.done-by" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.user?.name}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading && !dataSource?.data.length">
        <h3 class="text-center pt-3">{{ "CASES.single.no-payments" | translate }}</h3>
      </div>

      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize"
                     [pageSizeOptions]="[5, 10, 20]" (page)="paginatorChange($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
