<div class="custom-dropzone" *ngIf="type === 'dni'">
  <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="justify-content: center">
    <ngx-dropzone-label>{{"DOCUMENTS." + side | translate}}</ngx-dropzone-label>
    <ngx-dropzone-image-preview
      class="custom-dropzone-img"
      ngProjectAs="ngx-dropzone-preview"
      *ngFor="let f of files" [file]="f"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </ngx-dropzone>
  <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
    <mat-spinner diameter="30" color="primary"></mat-spinner>
  </div>
  <div class="d-flex justify-content-between align-items-center" *ngIf="(responseError || responseSuccess) && files.length">
    <div class="d-flex align-items-center" *ngIf="responseSuccess && files.length">
      <p class="m-0" style="color: #79a303">{{ responseSuccess }}</p>
      <mat-icon color="primary" aria-hidden="false" aria-label="Example home icon">check</mat-icon>
    </div>
    <div class="d-flex align-items-center" *ngIf="responseError && files.length">
      <p class="m-0" style="color: #fd3995 ">{{ responseError }}</p>
    </div>
    <div>
      <button mat-icon-button color="warn" (click)="onRemove($event)">
        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
          remove_circle
        </mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="custom-dropzone" *ngIf="type !== 'dni'">
  <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="justify-content: center">
    <ngx-dropzone-label>{{"DOCUMENTS.upload-files-note" | translate}}</ngx-dropzone-label>
    <ngx-dropzone-preview
      class="custom-dropzone-img"
      ngProjectAs="ngx-dropzone-preview"
      *ngFor="let f of files" [file]="f"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
  <div class="d-flex justify-content-center align-items-center" *ngIf="isLoading">
    <mat-spinner diameter="30" color="primary"></mat-spinner>
  </div>
  <div class="d-flex justify-content-between align-items-center" *ngIf="(responseError || responseSuccess) && files.length">
    <div class="d-flex align-items-center" *ngIf="responseSuccess && files.length">
      <p class="m-0" style="color: #79a303">{{ responseSuccess }}</p>
      <mat-icon color="primary" aria-hidden="false" aria-label="Example home icon">check</mat-icon>
    </div>
    <div class="d-flex align-items-center" *ngIf="responseError && files.length">
      <p class="m-0" style="color: #fd3995 ">{{ responseError }}</p>
    </div>
    <div>
      <button mat-icon-button color="warn" (click)="onRemove($event)">
        <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
          remove_circle
        </mat-icon>
      </button>
    </div>
  </div>
</div>
