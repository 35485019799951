import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { QuillImageUploader } from '../../../../_base-shared/components/quill-image-uploader';
import { SharedModule } from '../_shared/shared.module';
import { AddressBookModule } from './address-book/address-book.module';
import { AdminPackagerModule } from './admin-packager/admin-packager.module';
import { PackagerStatusModule } from './admin-packager/packager-status/packager-status.module';
import { AffiliateModule } from './affiliate/affiliate.module';
import { CaseModule } from './case/case.module';
import { CreditorProposalPreviewComponent } from './case/creditor-proposal-preview/creditor-proposal-preview.component';
import { ChangelogModule } from './changelog/changelog.module';
import { ConfigModule } from './config/config.module';
import { CreditorModule } from './creditor/creditor.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DialerModule } from './dialer/dialer.module';
import { DistributionModule } from './distribution/distribution.module';
import { IntegrationModule } from './integration/integration.module';
import { LeadModule } from './lead/lead.module';
import { LegalEntityModule } from './legal-entity/legal-entity.module';
import { NotificationModule } from './notification/notification.module';
import { PaymentModule } from './payment/payment.module';
import { ReportsModule } from './reports/reports.module';
import { TaskTemplateModule } from './task-template/task-template.module';
import { TaskModule } from './task/task.module';
import { TeamModule } from './team/team.module';
import { UserModule } from './user/user.module';

@NgModule({
    imports: [
        SharedModule,
        QuillModule.forRoot({
            customModules: [
                {implementation: QuillImageUploader, path: 'modules/imageUploader'}
            ]
        }),
        AffiliateModule,
        CreditorModule,
        CaseModule,
        PaymentModule,
        UserModule,
        ConfigModule,
        ReportsModule,
        TaskTemplateModule,
        DashboardModule,
        ChangelogModule,
        AddressBookModule,
        TaskModule,
        NotificationModule,
        IntegrationModule,
        LeadModule,
        LegalEntityModule,
        DistributionModule,
        TeamModule,
        DialerModule,
        AdminPackagerModule,
        PackagerStatusModule
    ],
    declarations: [
        CreditorProposalPreviewComponent,
    ]
})
export class AdminModule {
}
