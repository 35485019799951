import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ClaimsDashboardComponent} from './claims-dashboard.component';

const routes: Routes = [
  {path: 'claims-dash', component: ClaimsDashboardComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class ClaimsDashboardRoutingModule {
}
