import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditorPivot } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-creditor-note-list',
  templateUrl: './case-creditor-note-list.component.html',
  styles:      [],
})
export class CaseCreditorNoteListComponent implements OnInit {
  @Input() case: Case;
  @Output() caseCreditorsLoaded: EventEmitter<Case>         = new EventEmitter<Case>();
  public isLoading                                               = 0;
  public allCreditors: Array<CaseCreditorPivot | CasePublicDebt> = [];

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.loadCaseCreditors(this.case);
  }

  private loadCaseCreditors(clientCase: Case) {
    this.isLoading++;
    this.caseService.get(clientCase.id)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => {
              this.caseCreditorsLoaded.emit(this.case);
            },
        );
  }
}
