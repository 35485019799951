import { NgModule } from '@angular/core';
import { SharedClientModule } from '../_shared/shared-client.module';
import { BankiaErrorComponent } from './bankia/bankia-error/bankia-error.component';
import { BankiaSuccessComponent } from './bankia/bankia-success/bankia-success.component';
import { IntegrationRoutingModule } from './integration-routing.module';
import { MymoidIFrameRedirectComponent } from './mymoid/mymoid-iframe-redirect/mymoid-iframe-redirect.component';
import { MymoidResponseComponent } from './mymoid/mymoid-response/mymoid-response.component';

@NgModule({
  imports:      [
    SharedClientModule,
    IntegrationRoutingModule,
  ],
  declarations: [
    BankiaSuccessComponent,
    BankiaErrorComponent,
    MymoidIFrameRedirectComponent,
    MymoidResponseComponent,
  ],
})
export class IntegrationModule {
}
