import { Injectable } from '@angular/core';
import { MainBaseApiService } from "../../_shared/services/main-base-api.service";
import { LaravelResourceResponse } from "../../../../../_base-shared/contracts/laravel-response.interface";
import { catchError } from "rxjs/operators";
import { ConfigurationCategory } from "../../../../../_base-shared/models/System/ConfigurationCategory";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends MainBaseApiService {
  public indexCategories(requestData, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData, with: relations, with_count: relationsCount}, 'get',
    );
    return this.http.get<LaravelResourceResponse<Array<ConfigurationCategory>>>(this.apiUrl + '/configuration-categories', {params})
      .pipe(catchError(response => this.handleError(response)));
  }
}
