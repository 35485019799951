import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../client.service';

// TODO: Refactor case => user
@Component({
  selector:    'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls:   ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
  public case: string;
  public type: string;
  public signature: string;

  public landingMessage: string;
  public successMessage: string;
  public errorMessage: string;

  public isLoading = 0;

  constructor(
      private route: ActivatedRoute,
      public translate: TranslateService,
      private clientService: ClientService,
  ) {
  }

  ngOnInit(): void {
    this.case      = this.route.snapshot.queryParamMap.get('case');
    this.type      = this.route.snapshot.queryParamMap.get('type');
    this.signature = this.route.snapshot.queryParamMap.get('signature');

    if (this.type === 'email') {
      this.landingMessage = this.translate.instant('UNSUBSCRIBE.landing-message-email');
    } else {
      this.landingMessage = this.translate.instant('UNSUBSCRIBE.landing-message-sms');
    }

    this.sendData();
  }

  sendData() {
    const data = {
      case:      this.case,
      type:      this.type,
      signature: this.signature,
    };
    this.isLoading++;
    this.clientService.optOut(data).pipe(finalize(() => this.isLoading--)).subscribe(
        next => {
          this.successMessage = this.translate.instant('UNSUBSCRIBE.opt-out-success');
        },
        error => {
          this.errorMessage = this.translate.instant('UNSUBSCRIBE.opt-out-error');
        },
    );
  }

}
