<div class="d-flex flex-column h-100">
  <h4 class="text-center">{{"CASE_CREDITOR.model.court_fees" | translate}}</h4>

  <form class="row" [formGroup]="form" (ngSubmit)="submitForm(form)">
    <div class="col-lg-6">
      <app-input type="number" formControlName="court_fees" [suffix]="'€'" [fullWidth]="true"
                 [label]="'CASE_CREDITOR.model.court_fees' | translate">
      </app-input>
    </div>
    <div class="col-lg-6">
      <app-input type="select" formControlName="court_fees_status" appearance="standard" fullWidth="true"
                 [label]="'CASE_CREDITOR.model.court_fees_status' | translate" [showClear]="true"
                 [selectOptions]="courtFeesOptions" [selectLabel]="'name'" [selectValue]="'value'">
      </app-input>
    </div>
    <div class="col-12">
      <app-spinner-btn type="submit" [loading]="isSubmitting" color="primary">
        {{ 'SHARED.save' | translate }}
      </app-spinner-btn>
    </div>
  </form>
</div>
