import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { AppDocumentTypeCategory } from '../../../../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { AppDocumentService } from '../../../../app-document/app-document.service';

@Component({
  selector:    'app-requested-document-uploader',
  templateUrl: './requested-document-uploader.component.html',
  styles:      [],
})
export class RequestedDocumentUploaderComponent implements OnInit {
  @Input() case: Case;
  @Output() documentsUploaded = new EventEmitter<boolean>();
  public isLoading             = 0;
  public requestedDocumentTypeCategories: Array<AppDocumentTypeCategory>;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;

  constructor(private appDocumentService: AppDocumentService) {
  }

  ngOnInit(): void {
    this.fetchClientDocumentRequests();
  }

  public reloadRequestedDocumentsList() {
    console.log('TODO: reload requested documents list');
  }

  public refreshClientView() {
    this.fetchClientDocumentRequests();
  }

  private fetchClientDocumentRequests() {
    this.isLoading++;
    this.appDocumentService.listClientDocumentRequests(this.case.uuid, this.case.client.uuid)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.requestedDocumentTypeCategories = result.data;
          this.documentsUploaded.emit(true);
        });
  }

}
