import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { NotificationChannel } from '../../../../../../_base-shared/models/Notification/NotificationChannel';
import { User } from '../../../../../../_base-shared/models/User/User';
import { UploadService } from '../../app-file/upload.service';
import { DepartmentService } from '../../department/department.service';
import { UserService } from '../../user/user.service';
import { NotificationService } from '../notification.service';

@Component({
  selector:    'app-group-notifications',
  templateUrl: './user-notification.component.html',
  styles:      [],
})
export class UserNotificationComponent implements OnInit {
  public form: UntypedFormGroup;
  public formReady = false;
  public formSubmitted: boolean;

  public isLoading                                       = 0;
  public channels: Array<NotificationChannel>            = [];
  public departmentCategories: Array<DepartmentCategory> = [];
  public users: Array<User>                              = [];

  public quillModules = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  constructor(private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<UserNotificationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toastr: ToastrService,
              private notificationService: NotificationService,
              private userService: UserService,
              private departmentService: DepartmentService,
              private uploadService: UploadService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchChannels();
    this.fetchUsers();
    this.fetchDepartments();
  }

  private fetchChannels() {
    this.isLoading++;
    this.notificationService.indexChannels().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.channels = result.data);
  }

  private fetchUsers() {
    this.isLoading++;
    this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.users = result.data);
  }

  private fetchDepartments() {
    this.isLoading++;
    this.departmentService.categoryIndex(['departments']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.departmentCategories = result.data);
  }

  private buildForm() {
    this.form = this.fb.group({
      channels:             [[], Validators.required],
      assigned_users:       [[]],
      assigned_departments: [[]],
      subject:              [null, Validators.required],
      message:              [null, Validators.required],
    });

    this.formReady = true;
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public submit(form) {
    this.formSubmitted = true;
    if (form.invalid) {
      return;
    }

    this.isLoading++;
    this.notificationService.store(form.value).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.toastr.success('Notification sent.');
          this.dialogRef.close();
        },
        error => this.toastr.error('Failed to send notification.'),
    );
  }
}
