import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { AppDocumentType } from '../../../../../../../../_base-shared/models/Document/AppDocumentType';
import { AppDocumentTypeCategory } from '../../../../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { AppDocumentService } from '../../../../app-document/app-document.service';

@Component({
  selector:    'app-document-request-list',
  templateUrl: './document-request-list.component.html',
  styles:      [],
})
export class DocumentRequestListComponent implements OnInit {
  @Input() case: Case;
  @Output() documentsRequested = new EventEmitter<boolean>();

  public documentTypeCategories: Array<AppDocumentTypeCategory> = [];
  public isLoading                                              = 0;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;

  constructor(private fb: UntypedFormBuilder,
              private appDocumentService: AppDocumentService) {
  }

  get formCategories() {
    return this.form.get('categories') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.fetchDocumentTypes();
  }

  public rebuildForm() {
    console.log('TODO: init form rebuild');
  }

  private fetchDocumentTypes() {
    this.isLoading++;
    this.appDocumentService.index(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.documentTypeCategories = result.data;
      this.buildForm(this.documentTypeCategories);
    });
  }

  private buildForm(documentTypeCategories: Array<AppDocumentTypeCategory>) {
    this.form = this.fb.group({
      categories: this.fb.array([]),
    });

    const categories = this.form.get('categories') as UntypedFormArray;

    documentTypeCategories.forEach(documentTypeCategory => {
      categories.push(this.initDocumentTypeCategory(documentTypeCategory, true));
    });
  }

  private initDocumentTypeCategory(documentTypeCategory: AppDocumentTypeCategory, hasChildCategories = false) {
    const categoryGroup = this.fb.group({
      id:               [documentTypeCategory.id ? documentTypeCategory.id : null],
      name:             [documentTypeCategory.name_es ? documentTypeCategory.name_es : null],
      document_types:   this.fb.array([]),
      child_categories: this.fb.array([]),
    });

    const categoryDocumentTypesGroup = categoryGroup.get('document_types') as UntypedFormArray;

    documentTypeCategory.document_types.forEach(documentType => {
      categoryDocumentTypesGroup.push(this.initDocumentType(documentType, true));
    });

    if (hasChildCategories) {
      const childCategoriesGroup = categoryGroup.get('child_categories') as UntypedFormArray;

      documentTypeCategory.child_categories.forEach(childCategory => {
        childCategoriesGroup.push(this.initDocumentTypeCategory(childCategory, false));
      });
    }

    return categoryGroup;
  }

  private initDocumentType(documentType: AppDocumentType, hasChildDocumentTypes = false) {
    const documentTypeGroup = this.fb.group({
      id:                     [documentType.id ? documentType.id : null],
      name:                   [documentType.name ? documentType.name : null],
      description:            [documentType.description ? documentType.description : null],
      preselected:            [!!documentType.preselected],
      selectable_children:    [!!documentType.selectable_children],
      expires_after_unit:     [documentType.expires_after_unit ? documentType.expires_after_unit : null],
      expires_after:          [documentType.expires_after ? documentType.expires_after : null],
      custom_input_logic:     [documentType.custom_input_logic ? documentType.custom_input_logic : null],
      document_type_requests: [documentType.document_type_requests ? documentType.document_type_requests : null],
      documents:              [documentType.documents ? documentType.documents : null],
      child_document_types:   this.fb.array([]),
    });

    const childDocumentTypesGroup = documentTypeGroup.get('child_document_types') as UntypedFormArray;

    if (hasChildDocumentTypes && !!documentType.selectable_children && documentType.child_document_types) {
      documentType.child_document_types.forEach(childDocumentType => {
        childDocumentTypesGroup.push(this.initDocumentType(childDocumentType, false));
      });
    }

    return documentTypeGroup;
  }

  public submitForm(form: UntypedFormGroup) {
    this.formSubmitted = true;
    if (form.invalid) {
      console.error('Invalid form');
      return;
    }

    this.appDocumentService.requestDocuments(this.case.id, form.value).subscribe(result => {
      this.documentsRequested.emit(true);
    });
  }

  public viewDocument(documentId: number) {
    // TODO: show modal with document files
    console.log('Viewing document #' + documentId);
  }
}
