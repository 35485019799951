import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { AppointedCourt } from '../../../../../../../../_base-shared/models/Entity/AppointedCourt';
import { EntityAdministrator } from '../../../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { Notary } from '../../../../../../../../_base-shared/models/Entity/Notary';
import { Solicitor } from '../../../../../../../../_base-shared/models/Entity/Solicitor';
import { environment } from '../../../../../../environments/environment';
import { CourtService } from '../../../../address-book/court/court.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { AppointCourtModalComponent } from '../../case-draft/appoint-court-modal/appoint-court-modal.component';
import { CaseCreditorEntityEditorComponent } from './case-creditor-entity-editor/case-creditor-entity-editor.component';

@Component({
  selector:    'app-case-creditor-draft',
  templateUrl: './case-creditor-draft.component.html',
  styleUrls:   [],
})
export class CaseCreditorDraftComponent implements OnInit, OnDestroy {
  public isLoading                           = 0;
  public case: Case;
  public caseCreditor: CaseCreditor;
  public appointedCourt: AppointedCourt;
  public selectedAdministrator: EntityAdministrator;
  public selectedNotary: Notary;
  public selectedSolicitor: Solicitor;
  private subscriptions: Array<Subscription> = [];
  public storageUrl                          = environment.STORAGE_URL + '/';

  constructor(private route: ActivatedRoute,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private caseCreditorService: CaseCreditorService,
              private courtService: CourtService,
              private caseDocumentService: CaseDocumentService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseCreditorId = +params.get('id');
      this.route.parent.parent.parent.paramMap.subscribe(p => {
        const caseId = +p.get('id');
        this.fetchCase(caseId);
        this.fetchCaseCreditor(caseId, caseCreditorId);
        this.fetchCaseAppointedCourt(caseId, caseCreditorId);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchCase(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case                  = result.data;
          this.selectedAdministrator = this.case.case_creditor_entities?.administrator;
          this.selectedNotary        = this.case.case_creditor_entities?.notary;
          this.selectedSolicitor     = this.case.case_creditor_entities?.solicitor;
        }),
    );
  }

  private fetchCaseCreditor(caseId: number, caseCreditorId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.showCaseCreditor(caseId, caseCreditorId,
        ['case_creditor_entities.administrator', 'case_creditor_entities.notary', 'case_creditor_entities.solicitor'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseCreditor = result.data),
    );
  }

  private fetchCaseAppointedCourt(caseId, caseCreditorId): void {
    this.isLoading++;
    this.courtService.getCaseCreditorAppointedCourt(caseId, caseCreditorId)
      .pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.appointedCourt = result.data;
    });
  }

  public openCourtModal($event): void {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AppointCourtModalComponent, {
      width: '50%',
      data:  {
        case:         this.case,
        caseCreditor: this.caseCreditor,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchCase(this.case.id);
      }
    });
  }

  public removeAppointedCourt(appointedCourtId: number): void {
    this.appointedCourt = null;
  }

  public selectCaseCreditorEntity(entityType: string): void {
    const dialogRef = this.dialog.open(CaseCreditorEntityEditorComponent, {
      width: '50%',
      data:  {
        case:                 this.case,
        caseCreditorId:       this.caseCreditor.creditor_id,
        caseCreditorEntities: this.caseCreditor.case_creditor_entities,
        entityType,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data) {
        this.selectedSolicitor = result.data;
      }
    });
  }

  public getRightOfAccess(clientRole: 'client' | 'partner', creditorId: number, $event) {
    $event.preventDefault();
    this.caseDocumentService.generateRightOfAccessLetter(clientRole, creditorId)
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'right_of_access_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + 'pdf';
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded right of access letter');
        }
      }, err => {
        this.toastr.error('Failed to downloaded right of access letter');
      });
  }

  public getExtrajudicialClaim(clientRole: 'client' | 'partner', creditorId: number, $event) {
    $event.preventDefault();
    this.caseDocumentService.generateExtraJudicialClaim(clientRole, creditorId)
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'extrajudicial-claim' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + 'pdf';
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded extrajudicial claim');
        }
      }, err => {
        this.toastr.error('Failed to downloaded extrajudicial claim');
      });
  }

  public generateAEPDComplaint(caseCreditorId: number): void {
    this.caseCreditorService.generateAEPDComplaint(caseCreditorId)
      .subscribe(result => {;
          this.toastr.success(this.translate.instant('SHARED.success'));
      }, err => {
        this.toastr.error(this.translate.instant('SHARED.error'));
      });
  }

  public downloadAEPDComplaint(caseCreditorId: number): void {
    this.caseCreditorService.downloadAEPDComplaint(caseCreditorId)
      .subscribe(
        result => {
          if (result.type === 4) {
            const fileName = 'aepd-complaint' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm');
            saveAs(result.body, fileName);
            this.toastr.success(this.translate.instant('SHARED.success'));
          }
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.error'));
        },
      );
  }
}
