import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionBatch } from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { DistributionProvider } from '../../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { DistributionBatchService } from '../../distribution-batch.service';
import { DistributionProviderService } from '../../distribution-provider.service';

@Component({
  selector:    'app-distribution-batch-editor',
  templateUrl: './distribution-batch-editor.component.html',
  styles:      [],
})
export class DistributionBatchEditorComponent implements OnInit {
  public editorType: 'create' | 'edit';
  public isLoading                                  = 0;
  public isSubmitting: boolean;
  public distributionBatch: DistributionBatch;
  public distributionProviders: Array<DistributionProvider>;
  public batchStatusOptions: Array<AppSelectOption> = [];
  public serverResponse: LaravelResourceResponse;
  public form: UntypedFormGroup;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private fb: UntypedFormBuilder,
      private translate: TranslateService,
      private toastr: ToastrService,
      private distributionBatchService: DistributionBatchService,
      private distributionProviderService: DistributionProviderService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      this.buildSelectOptions();
      this.fetchDistributionProviders();
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          const batchId = +params.get('id');
          this.fetchDistributionBatch(batchId);
        });
      } else {
        this.buildForm(new DistributionBatch());
      }
    });
  }

  public submitForm(form: UntypedFormGroup) {
    this.serverResponse = null;
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    let formObserver: Observable<LaravelResourceResponse<DistributionBatch>>;
    if (this.editorType === 'create') {
      formObserver = this.distributionBatchService.store(form.value);
    } else {
      formObserver = this.distributionBatchService.update(this.distributionBatch.id, form.value);
    }

    this.isSubmitting = true;
    formObserver.pipe(finalize(() => this.isSubmitting = false)).subscribe(
        result => {
          this.editorType === 'create' ?
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  {model: this.translate.instant('DISTRIBUTION.batch.model_name.singular')})) :
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
                  {model: this.translate.instant('DISTRIBUTION.batch.model_name.singular')}));
          this.router.navigate(['/distribution', 'batches']);
        },
        error => {
          this.serverResponse = error.error;
          this.editorType === 'create' ?
              this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                  {model: this.translate.instant('DISTRIBUTION.batch.model_name.singular')})) :
              this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
                  {model: this.translate.instant('DISTRIBUTION.batch.model_name.singular')}));
        },
    );
  }

  private buildForm(batch: DistributionBatch) {
    this.form = this.fb.group({
      name:                     [batch.name, [Validators.required]],
      distribution_provider_id: [batch.distribution_provider_id, [Validators.required]],
      status:                   [batch.status ? batch.status : 'pending', [Validators.required]],
    });
  }

  private fetchDistributionBatch(batchId: number) {
    this.isLoading++;
    this.distributionBatchService.show(batchId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.distributionBatch = result.data;
          this.buildForm(this.distributionBatch);
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private buildSelectOptions() {
    this.batchStatusOptions = [
      {value: 'pending', label: 'Pending'},
      {value: 'submitted', label: 'Submitted'},
      {value: 'funded', label: 'Funded'},
      {value: 'rejected', label: 'Rejected'},
    ];
  }

  private fetchDistributionProviders() {
    this.isLoading++;
    this.distributionProviderService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.distributionProviders = result.data;
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }
}
