import { EnvironmentInterface } from './EnvironmentInterface';

export const environment: EnvironmentInterface = {
  APP_NAME:              'Devuelve',
  APP_ENV:               'production',
  production:            true,
  devMode:               false,
  API_DOMAIN:            'api.devuelve.es',
  API_SECURE_PROTOCOL:   true,
  API_URL:               'https://api.devuelve.es',
  STORAGE_URL:           'https://api.devuelve.es/storage',
  APP_URL:               'https://app.devuelve.es',
  CLIENT_APP_URL:        'https://clientes.devuelve.es',
  REFRESH_NOTIFICATIONS: true,
  GENERAL_SITE_URL:      'www.devuelve.es',
  DISTRIBUTION_USER_IDS: [1],

  COMPANY_NAME:        'Devuelve',
  COMPANY_NAME_LEGAL:  'Simply Legal, S.L',
  COMPANY_ID_CIF:      'B56253362',
  COMPANY_ADDRESS:     'Calle Diego de León, 47 (Madrid), CP 28006',
  COMPANY_BANK_NAME:   'BBVA',
  COMPANY_BANK_IBAN:   'ES10 0182 0192 7602 0165 9379',
  COMPANY_REPRESENTATIVE_NAME: 'D. Miguel Fernández Medina',
  COMPANY_PHONE_LEGAL: '910 053 393',
  VAT_PERCENTAGE:       21,

  EMAIL_INFO:                 'info@devuelve.es',
  EMAIL_LEGAL:                'legal@devuelve.es',
  EMAIL_ADMINISTRATION:       'administracion@devuelve.es',
  EMAIL_CANCELLATIONS:        'cancelaciones@devuelve.es',
  EMAIL_CONTRACT_CONTACT:     'contract@devuelve.es',
  EMAIL_SUPPORTING_DOCUMENTS: 'justificantes@devuelve.es',
  EMAIL_GDPR:                 'dataprotectionofficer@devuelve.es',

  GOOGLE_ANALYTICS_CODE:    'G-NPW7ZJLJHP',
  GOOGLE_ANALYTICS_ENABLED: false,
};
