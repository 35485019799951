<nav *ngIf="showNav" class="navbar navbar-expand-lg navigation-bar navbar-dark" [class.client-nav]="!authUser"
     [class.desktop]="desktopVersion" [class.mobile]="!desktopVersion">
  <div [ngClass]="{'d-flex flex-row w-100 justify-content-between align-items-end': !authUser}">
    <a class="navbar-brand" href="#" style="width: 125px">
      <a href='http://www.deudafix.es'>
        <img *ngIf="!authUser" class="img-fluid" src="assets/img/logos/devuelve_logo_blue.png" alt="Devuelve Logo"
             style="width: 150px;margin-left: 5rem;margin-top: 1rem;"
             [ngStyle]="{'margin-left': desktopVersion ? '150px' : '-5px'}">
      </a>
      <img *ngIf="authUser && authUser.role_id !== 4" class="img-fluid"
           src="assets/img/logos/devuelve_logo_white.png" alt="Devuelve Logo" style="width: 150px">
    </a>
  </div>
  <app-staff-navigation [authUser]="authUser"></app-staff-navigation>
  <app-non-staff-navigation [authUser]="authUser" [desktopNav]=desktopVersion>
  </app-non-staff-navigation>
  <div class="full-width">
    <div class="form-inline d-flex justify-content-end">
      <app-header *ngIf="authUser && authUser.role_id !== 4" [notifications]="notifications"></app-header>
    </div>
  </div>
</nav>

<!--Client Payment Nav-->
<nav
  class="nav-container col-12 navbar navbar-expand-lg navbar-light display-flex flex-row justify-content-lg-center justify-content-between align-items-stretch"
  *ngIf="!showNav && !showNav">
  <div class="col-7 d-flex justify-content-center">
    <div class="navbar-brand" style="width: 654px">
      <a href="https://devuelve.es/">
        <img src="assets/img/logos/devuelve_all_blue_logo.png" alt="Devuelve Logo"
             style="width: 168px;height: 27px;">
      </a>
    </div>
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation"
          (click)="openMobileNav()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse navbarToggler" *ngIf="isMobileNav">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 d-flex flex-row justify-content-start w-100 mobile-nav-public">
      <a class="nav-item nav-label nav-item-padding" href="https://devuelve.es/">Inicio</a>
      <a class="nav-item nav-label nav-item-padding" href="https://devuelve.es/">Cómo funciona</a>
      <a class="nav-item nav-label nav-item-padding nav-active" href="https://devuelve.es/">Reclamar</a>
      <a class="nav-item nav-label nav-item-padding" href="https://devuelve.es/">Contáctenos</a>
    </ul>
  </div>
</nav>
