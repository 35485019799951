import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CaseDocumentService } from '../../../admin/case/case-document.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-custom-dropzone-upload',
  templateUrl: './custom-dropzone-upload.component.html',
  styleUrls: ['./custom-dropzone-upload.component.scss']
})
export class CustomDropzoneUploadComponent implements OnInit {
  @Input() uploader;
  @Input() uuId;
  @Input() side: string;
  @Input() type;
  @Input() file;
  @Input() creditors;
  @Input() banks;
  @Input() allFiles: BehaviorSubject<any>;

  public files = [];
  public isLoading     = 0;
  public responseSuccess      = '';
  public responseError     = '';

  constructor(private documentService: CaseDocumentService) {
  }

  ngOnInit(): void {
  }

  onSelect(event) {
    if (!this.files.length) {
      this.files = [event.addedFiles[0]];
      this.isLoading++;
      //  Create form data object
      const formData = new FormData();
      formData.append('uploaded_by', this.uploader);
      formData.append('client_side_upload', '1');

      if (this.banks) {
        formData.append(`bank-${this.file.entity.id}[]`, event.addedFiles[0]);
      } else if (this.creditors) {
        //  If file has debt_type then its public debt file
        if (this.file.debt_type) {
          formData.append(`publicdebt-${this.file.id}[]`, event.addedFiles[0]);
        } else {  //  Else its creditor file
          formData.append(`creditor-${this.file.id}[]`, event.addedFiles[0]);
        }
      } else {
        formData.append(`${this.type}[]`, event.addedFiles[0]);
      }

      this.documentService.uploadFiles(this.uuId, formData)
        .pipe(finalize(() => {
          this.isLoading--;
        }))
        .subscribe(
          next => {
            this.files[0].id = next.data.new_files[0].id;
            this.allFiles.next(next);
            this.responseSuccess = 'Archivo subido.';
          },
          error => {
            this.responseError = 'Error al cargar.';
          }
        );

    }
  }

  onRemove(event) {
    this.documentService.removeUploadedFile(this.uuId, this.files[0].id)
      .subscribe(next => {
        this.allFiles.next(next);
      });
    this.files = [];
  }

}
