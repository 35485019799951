import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FinancialOverview } from '../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';

@Component({
  selector:    'app-case-finance-overview',
  templateUrl: './case-finance-overview.component.html',
  styleUrls:   ['./case-finance-overview.component.scss'],
})
export class CaseFinanceOverviewComponent implements OnInit, OnDestroy {
  @Input() caseId: number;
  public financialOverview: FinancialOverview;
  public isLoading = 0;

  @Input() debt: number;
  @Input() unsecuredDebt: number;
  @Input() monthlyPayment: number;

  private subscriptions: Array<Subscription> = [];

  constructor(private financialOverviewService: FinancialOverviewService) {
  }

  ngOnInit(): void {
    this.getFinancialOverview(this.caseId);
  }

  ngOnDestroy(): void {
    console.log('destroying case finance');
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.financialOverviewService.updateFinancialOverviewLocally(null);
  }

  public refreshFinancialOverview(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.financialOverviewService.fetchLatest(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
          console.log('fetched latest financial overview');
        }),
    );
  }

  private getFinancialOverview(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.financialOverviewService.financialOverview$.pipe(finalize(() => this.isLoading--)).subscribe(result => {
          console.log('received financial overview result');
          if (result) {
            this.financialOverview = result;
          } else {
            console.log('no financial overview, fetching latest');
            this.refreshFinancialOverview(caseId);
          }
        }),
    );
  }
}
