import { Component, OnChanges, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditorPivot } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorEntity } from '../../../../../../../_base-shared/models/Case/CaseCreditorEntity';
import { AppointedCourt } from '../../../../../../../_base-shared/models/Entity/AppointedCourt';
import { EntityAdministrator } from '../../../../../../../_base-shared/models/Entity/EntityAdministrator';
import { Notary } from '../../../../../../../_base-shared/models/Entity/Notary';
import { Solicitor } from '../../../../../../../_base-shared/models/Entity/Solicitor';
import { environment } from '../../../../../environments/environment';
import {
  ChangeCreditorStatusComponent,
} from '../../../../_shared/components/change-creditor-status/change-creditor-status.component';
import { AdministratorsService } from '../../../address-book/administrators/administrators.service';
import { CourtService } from '../../../address-book/court/court.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';
import { AdministratorModalComponent } from './administrator-modal/administrator-modal.component';
import { AppointCourtModalComponent } from './appoint-court-modal/appoint-court-modal.component';
import { NotaryModalComponent } from './notary-modal/notary-modal.component';
import { ProposalModalComponent } from './proposal-modal/proposal-modal.component';
import { SolicitorModalComponent } from './solicitor-modal/solicitor-modal.component';

@Component({
  selector: 'app-case-draft',
  templateUrl: './case-draft.component.html',
  styles: [`
    .appointed-link a {
      color: black;
    }

    .appointed-link a:hover {
      color: #79a303
    }

    a[target]:not(.btn) {
      font-weight: 400 !Important
    }
  `],
})
export class CaseDraftComponent implements OnInit, OnChanges {
  public case: Case;
  public isLoading                  = 0;
  public isSendingNotaryEmail       = 0;
  public selectedAdministrator: EntityAdministrator;
  public selectedNotary: Notary;
  public selectedSolicitor: Solicitor;
  public appointedCourt: AppointedCourt;
  public caseEntities: CaseCreditorEntity;
  public canRequestProposalSign     = false;
  public caseStatusId: number;
  public storageUrl                 = environment.STORAGE_URL + '/';
  public uniqueUnsecuredCreditors: MatTableDataSource<CaseCreditorPivot>;
  public displayedColumns: string[] = [
    'name', 'current_balance', 'status', 'actions',
  ];
  public displayedCourtColumnsData: MatTableDataSource<any>;

  public displayedCourtColumns: string[] = [
    'court', 'app_date', 'hearing_date', 'outcome', 'exoneration_amount',
  ];
  creditorListSigned                     = false;
  creditorListDocumentUrl                = '';
  assetListSigned                        = false;
  assetListDocumentUrl                   = '';
  cajaplusProduct                        = false;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private administratorsService: AdministratorsService,
    private courtService: CourtService,
    private caseDocumentService: CaseDocumentService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.fetchCase(caseId);
    });
  }

  ngOnChanges(): void {
    this.requestBtnDisabled();
  }

  private requestBtnDisabled() {
    if (this.case.product.group_slug === 'lso') {
      this.canRequestProposalSign = !!this.case.propuesta_modelo;
    }
    if (this.case.product.group_slug === 'dm' || this.case.product.group_slug === 'dgs') {
      const creditors             = this.case.unsecured_creditors;
      // this.canRequestProposalSign = !!creditors[0]?.pivot?.proposal_location;
      this.canRequestProposalSign = false; // TODO: no more unsecured creditors
    }
  }

  private mapCreditors(creditors: Array<CaseCreditorPivot>) {
    // Sum balance of duplicate creditors into one
    const filteredAll = []; // New array with unique creditors
    creditors.forEach((creditor, i) => {
      //  check if creditor is already in unique array
      const index = filteredAll.findIndex(obj => obj.id === creditor.id); //  Get creditor index
      if (index !== -1) { //  Check if index exist
        //  Sum balance
        filteredAll[index].pivot.current_balance += creditor.pivot.current_balance;
      } else {  //  If creditor is not in unique array, push it
        const cloneObject = {...creditor, pivot: {...creditor.pivot}};
        filteredAll.push(cloneObject);
      }
    });
    //  Create MatTable data with unique creditors
    this.uniqueUnsecuredCreditors = new MatTableDataSource<CaseCreditorPivot>(filteredAll);
  }

  public generateProposal() {
    const dialogRef = this.dialog.open(ProposalModalComponent, {
      width: '50%',
      height: '50%',
      data: {
        case: this.case,
        administrator: this.selectedAdministrator,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.dismissed && result.data) {
        this.case.proposal = result.data;
      }
    });
  }

  private updateDraftingStatus(id, value) {
    const item                 = this.uniqueUnsecuredCreditors.data.find(i => i.pivot.id === id);
    item.pivot.drafting_status = value;
  }

  public changeCreditorStatus($event: MouseEvent, ids: Array<number>, type: string) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(ChangeCreditorStatusComponent, {
      width: '50%',
      height: '50%',
      data: {
        case: this.case,
        ids,
        type,
        change_all: 1,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        ids.forEach(id => {
          this.updateDraftingStatus(id, result);
        });
      }
    });
  }

  public sendNotaryEmail($event: PointerEvent) {
    $event.preventDefault();
    this.isSendingNotaryEmail++;
    this.caseService.sendNotaryEmail(this.case.id).pipe(finalize(() => this.isSendingNotaryEmail--)).subscribe(
      value => {
        this.toastr.success(this.translate.instant('CASES.details.email-sent'));
      }, error => {
        this.toastr.error(error.error.data);
      });
  }

  public requestProposalSign(type: 'email') {
    this.caseService.sendProposal(this.case.id, {send_to: type}).subscribe(
      value => {
        this.toastr.success(this.translate.instant('CASES.details.send-e-sign-success'));
      }, error => {
        this.toastr.error(this.translate.instant('CASES.details.send-e-sign-error'));
      });
  }

  public requestAepSign($event) {
    $event.preventDefault();
    this.caseService.requestAepSign(this.case.id).subscribe(
      value => {
        this.toastr.success(this.translate.instant('CASES.details.email-sent'));
      }, error => {
        this.toastr.error(this.translate.instant('CASES.details.email-sent-error'));
      });
  }

  public sendProposalToAdministrator($event) {
    $event.preventDefault();
    this.administratorsService.sendEmailToAdministrator(this.case.id, {administrator_id: this.selectedAdministrator.id})
      .subscribe(value => {
        this.toastr.success(this.translate.instant('CASES.details.email-sent'));
      }, error => {
        this.toastr.error(this.translate.instant('CASES.details.email-sent-error'));
      });

  }

  public selectAdministrator($event) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AdministratorModalComponent, {
      width: '50%',
      data: {
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedAdministrator = result;
      }
    });
  }

  public selectNotary($event) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(NotaryModalComponent, {
      width: '50%',
      data: {
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedNotary = result;
      }
    });
  }

  public selectSolicitor() {
    const dialogRef = this.dialog.open(SolicitorModalComponent, {
      width: '50%',
      data: {
        case: this.case,
        selectedSolicitor: this.selectedSolicitor,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data) {
        this.selectedSolicitor = result.data;
      }
    });
  }

  public downloadAep() {
    saveAs(this.storageUrl + this.case.aep.location, this.case.aep.name);
  }

  public downloadAssetList() {
    const file           = this.case.asset_list_documents[0].files[this.case.asset_list_documents[0].files.length - 1];
    window.location.href = file.path;
  }

  public downloadCreditorList() {
    const file           = this.case.creditor_list_documents[0].files[this.case.creditor_list_documents[0].files.length -
                                                                      1];
    window.location.href = file.path;
  }

  public openCourtModal($event) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(AppointCourtModalComponent, {
      width: '50%',
      data: {
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchCase(this.case.id);
      }
    });
  }

  public requestCreditorAssetListSignature() {
    this.caseService.requestCreditorAssetListSignature(this.case.id).subscribe(
      res => {
        this.toastr.success('Creditor and asset list request sent');
      }, error => {
        this.toastr.error('Error while sending');
      });
  }

  private fetchCase(caseId: number) {
    const loadRelations = [
      'product',
      'propuesta_modelo',
      'aep',
      'appointed_court.court',
      'creditor_list_documents.client_signature',
      'creditor_list_documents.partner_signature',
      'asset_list_documents.client_signature',
      'asset_list_documents.partner_signature',
      'creditor_list_documents.files',
      'asset_list_documents.files',
    ];
    this.isLoading++;
    this.caseService.get(caseId, loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.case                  = result.data;
      this.caseEntities          = this.case.case_creditor_entities;
      this.selectedAdministrator = this.case.case_creditor_entities?.administrator;
      this.selectedNotary        = this.case.case_creditor_entities?.notary;
      this.selectedSolicitor     = this.case.case_creditor_entities?.solicitor;
      this.caseStatusId          = this.case.status_id;
      if (this.case.product.group_slug === 'cajaplus') {
        this.cajaplusProduct = true;
      }
      this.checkIfCreditorListSigned();
      this.checkIfAssetListSigned();
      this.mapCreditors(this.case.unsecured_creditors);
      this.requestBtnDisabled();
      this.fetchCaseAppointedCourt(this.case);
    });
  }

  private fetchCaseAppointedCourt(clientCase: Case) {
    this.isLoading++;
    this.courtService.getCaseAppointedCourt(clientCase.id).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.appointedCourt = result.data;
    });
  }

  private checkIfCreditorListSigned() {
    this.creditorListSigned = this.case.creditor_list_documents[0] &&
                              (this.case.creditor_list_documents[0].client_signature[0] ||
                               this.case.creditor_list_documents[0].partner_signature[0]);

    if (this.creditorListSigned) {
      this.creditorListDocumentUrl = this.storageUrl +
                                     this.case.creditor_list_documents[0].files[this.case.creditor_list_documents[0].files.length - 1].path;
    }
  }

  private checkIfAssetListSigned() {
    this.assetListSigned = this.case.asset_list_documents[0] &&
                           (this.case.asset_list_documents[0].client_signature[0] ||
                            this.case.asset_list_documents[0].partner_signature[0]);

    if (this.assetListSigned) {
      this.assetListDocumentUrl = this.storageUrl +
                                  this.case.asset_list_documents[0].files[this.case.asset_list_documents[0].files.length - 1].path;
    }
  }

  public removeAppointedCourt(appointedCourtId: number) {
    this.appointedCourt = null;
  }

  public previewProposal(clientRole: string): void {
    const url = (environment.APP_URL.replace('app.', 'clientes.')) +
      '/public/sign-proposal?uuid=' + this.case.uuid + '&sign=' + clientRole;
    window.open(url, '_blank');
  }

  public requestProposalSignature(): void {
    this.caseDocumentService.requestProposalSignature(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('CASES.single.draft.proposal.request_signature.response.success'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public sendProposalToCreditors(caseId: number) {
    this.caseDocumentService.sendProposalToCreditors(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('CASES.single.draft.proposal.request_signature.response.success'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }
}
