<div>
  <div class="row">
    <div class="col-md-6">
      <!-- Payment Plan Form -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h3>{{ 'CASES.editor.payment.payment_plan.heading' | translate}}</h3>
          <small *ngIf="casePaymentPlanOutdated" class="badge badge-danger">
            {{ 'CASES.editor.payment.payment_plan.form.errors.plan_outdated' | translate }}
          </small>
        </div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <div *ngIf="paymentPlanResponse" class="col-12">
              <app-server-response [response]="paymentPlanResponse"></app-server-response>
            </div>
          </div>
          <form *ngIf="paymentPlanForm" [formGroup]="paymentPlanForm">
            <div class="row">
              <div class="col-6">
                <!-- Initial fee -->
                <app-input type="number" formControlName="initial_fee" [extraLabel]="false" [fullWidth]="true"
                           [label]="'CASES.editor.payment.payment_plan.form.initial_fee' | translate">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Percentage agreed -->
                <app-input type="number" formControlName="percentage_agreed" [extraLabel]="false" [fullWidth]="true"
                           [label]="'CASES.editor.payment.payment_plan.form.percentage_agreed' | translate">
                </app-input>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <app-spinner-btn type="submit" [loading]="submittingPaymentPlan" [name]="'SHARED.save' | translate"
                             [disabled]="paymentPlanForm?.invalid"
                             (click)="submitPaymentPlanForm(paymentPlanForm)">
            </app-spinner-btn>
          </div>
        </div>
      </div>
      <!-- ID Card Form -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h3>{{ 'CASES.editor.payment.id_card.heading' | translate }}</h3>
        </div>
        <div class="card-body">
          <!-- Client ID Card-->
          <app-case-id-card-editor *ngIf="case?.id" [case]="case" [user]="case.client"
                                   [inputLabel]="'Client 1 ' + ('USERS.model.id_card' | translate)"
                                   (updatedIdCard)="updateUserIdCard($event, 'client')"
                                   (updatedClient)="updatedClient($event, 'client')">
          </app-case-id-card-editor>
          <!-- Partner ID Card -->
          <app-case-id-card-editor *ngIf="case?.id && case.joint_application" [case]="case" [user]="case.partner"
                                   [inputLabel]="'Client 2 ' + ('USERS.model.id_card' | translate)" class="mt-3"
                                   (updatedIdCard)="updateUserIdCard($event, 'partner')"
                                   (updatedClient)="updatedClient($event, 'partner')">
          </app-case-id-card-editor>
        </div>
      </div>
      <!-- Payment Configuration -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h3>{{ 'CASES.editor.payment.payment_config.heading' | translate }}</h3>
        </div>
        <div class="card-body">
          <form *ngIf="paymentConfigurationForm" [formGroup]="paymentConfigurationForm">
            <!-- Payment Method -->
            <app-input *ngIf="paymentMethods.length" type="select"
                       formControlName="payment_method_id" [extraLabel]="true" [fullWidth]="true"
                       [label]="'CASES.single.payment-type' | translate" [selectOptions]="paymentMethods"
                       [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
            <!-- Initial Card Fee -->
            <div class="row">
              <div class="col-12" style="margin-bottom: 24px">
                <!-- Initial Fee -->
                <app-input type="number" formControlName="initial_fee" [fullWidth]="true"
                           [label]="('CASES.editor.payment.payment_plan.form.initial_fee' | translate) + ' (inc VAT)'"
                           [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <!-- SEPA IBAN -->
            <app-input *ngIf="getPaymentMethodSlug(paymentConfigurationForm.get('payment_method_id').value) === 'sepa'"
                       formControlName="iban" [label]="'CASES.single.iban' | translate" [extraLabel]="true"
                       [fullWidth]="true">
            </app-input>
            <!-- Actions -->
            <div class="row">
              <div class="col-12 mt-3">
                <!-- Send SEPA Link -->
                <div *ngIf="getPaymentMethodSlug(paymentConfigurationForm.get('payment_method_id').value) === 'sepa'"
                     class="dropright d-inline">
                  <button type="button" [title]="'CASES.single.send-sepa-link' | translate" mat-raised-button
                          color="primary" class="btn mr-2 mb-2" [matMenuTriggerFor]="sendSepaLinkMenu">
                    {{ 'CASES.single.send-sepa-link' | translate }}
                    <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_right</mat-icon>
                  </button>
                  <mat-menu #sendSepaLinkMenu="matMenu" class="sendSepaLinkMenu">
                    <div class="dropdown-item" (click)="sendSepaLink('email')" href="#">Email</div>
                    <div class="dropdown-item" (click)="sendSepaLink('sms')" href="#">SMS</div>
                    <div class="dropdown-item" (click)="sendSepaLink('all')" href="#">Email & SMS</div>
                  </mat-menu>
                </div>
                <button [matMenuTriggerFor]="requestPaymentMenu" mat-raised-button
                        color="primary" class="mr-2" (click)="$event.preventDefault()"
                        [disabled]="!paymentConfigurationForm.get('initial_fee').value || paymentConfigurationForm.get('initial_fee').invalid">
                  {{ 'CASES.single.send-link' | translate }}
                  <ng-container *ngIf="paymentConfigurationForm.get('initial_fee').value">
                    ({{ paymentConfigurationForm.get('initial_fee').value + "€" }})
                  </ng-container>
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #requestPaymentMenu="matMenu">
                  <button mat-menu-item (click)="requestPaymentDialog('card', 'oppwa')">
                    <span>{{ "CASES.single.payments.actions.request_payment.options.oppwa" | translate }}</span>
                  </button>
                  <!--<button mat-menu-item *ngIf="appEnv === 'local' || appEnv === 'staging' ||-->
                  <!--         (this.authUser.role_id === 5 && this.allowedMymoidIds.includes(this.authUser.id))"-->
                  <!--        (click)="requestPaymentDialog('card', 'mymoid')">-->
                  <!--  <span>{{ "CASES.single.payments.actions.request_payment.options.mymoid" | translate }}</span>-->
                  <!--</button>-->
                </mat-menu>
                <!-- Charge card -->
                <button class="ml-2 mr-2"
                        [disabled]="!paymentConfigurationForm.get('initial_fee').value || paymentConfigurationForm.get('initial_fee').invalid"
                        mat-raised-button color="primary" type="button" (click)="openCardForm('charge')">
                  {{"CASES.single.pay" | translate}}
                  <ng-container *ngIf="paymentConfigurationForm.get('initial_fee').value">
                    ({{ paymentConfigurationForm.get('initial_fee').value + "€" }})
                  </ng-container>
                </button>
                <!-- Tokenize card -->
                <button mat-raised-button color="primary" type="button" (click)="openCardForm('verify')">
                  {{ "CASES.single.verify" | translate }}
                </button>
                <!-- Submit Form -->
                <div class="d-flex justify-content-end">
                  <app-spinner-btn type="submit" [loading]="submittingPaymentConfiguration"
                                   [name]="'SHARED.save' | translate"
                                   (click)="submitPaymentConfigurationForm(paymentConfigurationForm)">
                  </app-spinner-btn>
                </div>
                <div *ngIf="sendLinksError" class="alert alert-warning mt-3">
                  <strong class="text-danger mx-1">{{ sendLinksError }}</strong>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Summary -->
    <div *ngIf="paymentPlanForm && paymentConfigurationForm" class="col-6">
      <div class="card shadow mb-4">
        <div class="card-header"><h3>{{"CASES.single.summary" | translate }}</h3></div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <div *ngIf="summaryResponse" class="col-12">
              <app-server-response [response]="summaryResponse"></app-server-response>
            </div>
          </div>
          <div class="form-group pt-3">
            <!-- Preview Contract -->
            <a target="_blank" [routerLink]="['/client', 'sign-contract']"
               [queryParams]="{uuid: case.uuid}" mat-button color="primary" type="button">
              {{"CASES.single.contract-preview" | translate}}
            </a>
            <!-- Send contract button -->
            <button [disabled]="contractButtonDisabled()" mat-raised-button color="primary" class="btn" type="button"
                    [matMenuTriggerFor]="sendContractMenu">
              {{"CASES.single.send-contract" | translate}}
              <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_right</mat-icon>
            </button>
            <mat-menu #sendContractMenu="matMenu" xPosition="after" class="sendContractMenu">
              <a class="dropdown-item" (click)="sendContract($event, 'email')">Email</a>
              <a class="dropdown-item" (click)="sendContract($event, 'sms')">SMS</a>
              <a class="dropdown-item" (click)="sendContract($event, 'all')">Email & SMS</a>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
