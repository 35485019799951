import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends MainBaseApiService {

  index(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/email-templates`, {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  get(emailId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/email-templates/${ emailId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/email-templates`, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  update(templateId: number, data): Observable<LaravelResourceResponse> {
    const params = {_method: 'patch'};
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/email-templates/' + templateId, data, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  delete(emailId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/email-templates/${ emailId }`)
        .pipe(catchError(response => this.handleError(response)));
  }
}
