import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { PaymentStatus } from '../../../../../_base-shared/models/Status/PaymentStatus';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentStatusService extends MainBaseApiService {

  index(data = {}): Observable<LaravelResourceResponse<Array<PaymentStatus>>> {
    return this.http.get<LaravelResourceResponse<Array<PaymentStatus>>>(`${ this.apiUrl }/payment-statuses`, {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }
}
