<div *ngIf="case" class="card shadow card-height detail-documents mb-4">
  <div class="card-header py-4">
    <div class="d-flex">
      <h1>{{ "CASES.single.documents.heading" | translate }}</h1>
    </div>
    <div class="d-flex get-files-btns">
      <a mat-raised-button color="primary" [disabled]="!contractPdfLocation" target="_blank"
         href="{{storageUrl + contractPdfLocation}}"
         [title]="!contractPdfLocation ?
          ('CASES.single.get-contract-button-disabled' | translate) :
           ('DOCUMENTS.get-contract' | translate)">
        {{ "DOCUMENTS.get-contract" | translate }}
      </a>
      <!--Get mandate button for single case-->
      <a mat-raised-button color="primary" class="ml-3" *ngIf="!case.joint_application"
         [disabled]="!mandatePdfLocation" target="_blank" href="{{storageUrl + mandatePdfLocation}}"
         [title]="!mandatePdfLocation ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-mandates' | translate)">
        {{ "CASES.single.get-mandates" | translate }}
      </a>
      <!--   Get mandate button for joint case   -->
      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"
         [disabled]="!mandatePdfLocation" target="_blank" href="{{storageUrl + mandatePdfLocation}}"
         [title]="!mandatePdfLocation ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-client-1-mandates' | translate)">
        {{ "CASES.single.get-client-1-mandates" | translate }}
      </a>
      <a mat-raised-button color="primary" class="ml-3" *ngIf="case.joint_application"
         [disabled]="!mandatePdfLocationPartner" target="_blank" href="{{storageUrl + mandatePdfLocationPartner}}"
         [title]="!mandatePdfLocationPartner ?
          ('CASES.single.mandates-button-disabled' | translate) :
           ('CASES.single.get-client-2-mandates' | translate)">
        {{ "CASES.single.get-client-2-mandates" | translate }}
      </a>
      <div *ngIf="authUser.role_id === 5 || authUser.role_id === 1 || authUser.role_id === 2"
           class="d-flex justify-content-center flex-wrap" style="min-width: 245px; margin-left: auto">
        <div *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 3 || authUser.id === 6 || authUser.id === 967 || authUser.id === 235517
                  || authUser.id === 582 || authUser.id === 513023 || authUser.id === 34 || authUser.id === 26 || authUser.role_id === 2"
          class="dropleft d-flex">
          <button [disabled]="!contractPdfLocation" mat-raised-button color="primary" class="btn" [matMenuTriggerFor]="resignContractMenu"
                   type="button" [title]="!contractPdfLocation ? ('DOCUMENTS.resign-contract-disabled' | translate) :
                   ('DOCUMENTS.resign-contract' | translate)">
            <mat-icon style="">chevron_left</mat-icon>
            {{ "DOCUMENTS.resign-contract" | translate }}
          </button>
          <mat-menu #resignContractMenu="matMenu" class="resignContractMenu" xPosition="after">
            <a mat-menu-item (click)="resignContract($event, 'email', 'client')" href="#"> Client Email</a>
            <a mat-menu-item (click)="resignContract($event, 'sms', 'client')" href="#"> Client SMS</a>
            <a mat-menu-item (click)="resignContract($event, 'all', 'client')" href="#"> Client Email & SMS</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'email', 'partner')" href="#"> Client 2 Email</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'sms', 'partner')" href="#"> Client 2 SMS</a>
            <a *ngIf="case.joint_application"
               mat-menu-item (click)="resignContract($event, 'all', 'partner')" href="#"> Client 2 Email &
              SMS</a>
          </mat-menu>
        </div>
        <button
          *ngIf="authUser.id === 1 || authUser.id === 22 || authUser.id === 27 || authUser.id === 36 || authUser.id === 2497"
          [disabled]="!contractPdfLocation || regenerateSpinner" class="ml-3"
          mat-raised-button color="primary" (click)="regenerateContract()"
          [title]="!contractPdfLocation ? ('DOCUMENTS.regenerate-contract-disabled' | translate) : ('DOCUMENTS.regenerate-contract' | translate)">
          {{"CASES.single.regenerate-contract" | translate}}
        </button>
        <mat-spinner *ngIf="regenerateSpinner" diameter="30" class="m-0 m-auto"></mat-spinner>
        <button *ngIf="case.client" mat-raised-button color="primary" class="ml-3" (click)="verifySignature($event)">
          {{!case.client.signature_verified_at ?
          ("CASES.details.signature-verify" | translate) :
          ("CASES.details.view-signature" | translate)}}
        </button>
      </div>
    </div>
    <button mat-raised-button color="primary" class="mt-2" (click)="sendSignedContract()">
      {{'CASES.single.send-signed-contract' | translate}}
    </button>
    <button *ngIf="advicePackVisible" [matMenuTriggerFor]="advicePack" mat-raised-button color="primary" class="ml-2 mt-2">
      {{'CASES.single.resend_advice_pack' | translate}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #advicePack="matMenu">
      <button mat-menu-item (click)="resendAdvicePackEmail(true)">
        <span>{{'CASES.single.generate' | translate}}</span>
      </button>
      <button mat-menu-item (click)="resendAdvicePackEmail(false)">
        <span>{{'CASES.single.dont_generate' | translate}}</span>
      </button>
    </mat-menu>
    <button [matMenuTriggerFor]="debtCancellationRequestMenu" mat-raised-button color="primary" class="ml-2 mt-2">
      {{ 'CASES.single.documents.debt_cancellation_request.action' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #debtCancellationRequestMenu="matMenu">
      <button mat-menu-item [routerLink]="['/client/sign-debt-cancellation-request']"
              [queryParams]="{uuid: case.uuid, type: 'debt-cancellation-request', sign: 'client'}">
        {{ 'CASES.single.documents.debt_cancellation_request.preview' | translate }}
      </button>
      <button mat-menu-item (click)="requestDebtCancellationRequestSignature()">
        {{ 'CASES.single.documents.debt_cancellation_request.request_signature.action' | translate }}
      </button>
      <button mat-menu-item  (click)="sendEmailToExperian()" [disabled]="!areExperianAttachmentsReady">
        {{ 'CASES.single.documents.debt_cancellation_request.send_email_to_experian' | translate }}
      </button>
    </mat-menu>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitFiles()" class="form">
          <div>
            <div class="row pb-4">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="uploadFile1">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input multiple type="file" class="custom-file-input" [lang]="lang" id="uploadFile1"
                             formControlName="files" (change)="onFileChange($event)">
                      <label class="custom-file-label" for="uploadFile1">
                        {{ 'DOCUMENTS.choose-file' | translate}}
                      </label>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center" *ngFor="let file of files">
                    <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                    <button mat-icon-button color="warn" (click)="removeFile($event, file.index)">
                      <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                        remove_circle
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="case.joint_application"
                   class="col-md-2 col-lg-2 inline-form-button d-flex justify-content-between">
                <div class="form-group upload-for" style="margin-right: 0">
                  <label for="uploadFor">{{"CASES.single.upload-for" | translate}}</label>
                  <select id="uploadFor" class="form-control" formControlName="uploadFor"
                          (ngModelChange)="changeUploadFor($event)">
                    <option value="client">{{"CASES.single.client" | translate}}</option>
                    <option value="partner">{{"CASES.single.partner" | translate}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 d-flex">
                <div class="form-group">
                  <label for="status">{{ "DOCUMENTS.select-category" | translate }}</label>
                  <select class="form-control" id="status" formControlName="fileType">
<!--                    <option value="sepa">{{"CASES.single.sepa" | translate}}</option>-->
<!--                    <option value="contract">{{"CASES.single.document-types.contract" | translate}}</option>-->
<!--                    <option value="mandate">{{"CASES.single.document-types.mandates" | translate}}</option>-->
                    <option *ngFor="let type of documentType"
                            [value]="type.id">
                      {{ type.custom ? type.name : ("CASES.single.document-types." + type.name | translate)}}
                    </option>
                  </select>
                </div>
              </div>
              <div style="padding-top: 25px">
                <app-spinner-btn [loading]="uploadSpinner" [name]="'SHARED.submit' | translate"></app-spinner-btn>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="fileType === 'object'; else photo" class="col-6" style="height: auto">
        <div *ngIf="fileUrl" style="height: 100%">
          <div [ngClass]="{'height-50' : true, 'sticky' : isSticky}">
            <object [data]="fileUrl" type="application/pdf" width="100%" height="100%">
              <iframe [src]="fileUrl" width="100%" height="100%" style="border: none;">
                <p>
                  Your browser does not support PDFs.
                  <a [href]="fileUrl">Download the PDF</a>
                </p>
              </iframe>
            </object>
          </div>
        </div>
      </div>
      <ng-template #photo>
        <div class="col-md-6">
          <div [ngClass]="{'height-50' : true, 'sticky' : isSticky}">
            <img [src]="fileUrl" alt="" class="img-fluid" style="max-height: 83vh">
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
