<div class="col-12">
  <div *ngIf="isLoading" class="pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>

  <div *ngIf="!isLoading" class="row">
    <div class="col-12">
      <h2 *ngIf="editorType == 'create'" class="pl-4">{{ 'CONFIG.notifications.new-trigger' | translate}}</h2>
      <h2 *ngIf="editorType != 'create'" class="pl-4">{{ 'CONFIG.notifications.edit-trigger' | translate}}</h2>
    </div>

    <div class="col-12">
      <form *ngIf="formActive" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card shadow mb-5">
          <div class="card-body">

            <div class="row">
              <div class="col-md-2"><h3>{{'CONFIG.notifications.when' | translate}} </h3></div>
              <div class="col-md-4">
                <app-input type="select" formControlName="on" appearance="outline" [label]="'Statuses'" multiple
                           [fullWidth]="true" [selectOptions]="statusSelectOptions" [selectLabel]="'label'"
                           [selectValue]="'value'">
                </app-input>
              </div>
              <div class="col-md-4">
                <app-input type="select" formControlName="status" appearance="outline" [label]="'Statuses'" multiple
                           [fullWidth]="true" [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </div>

              <div class="col-md-2" *ngIf="form.get('on').value === 'stays'">
                <input type="text" class="form-control" id="days" [placeholder]="'CONFIG.notifications.days' | translate" formControlName="days">
              </div>

            </div>

            <div class="row pt-4">
              <div class="col-md-2"><h3>{{'CONFIG.notifications.then' | translate}} {{'CONFIG.notifications.send' | translate}} </h3></div>
              <div class="col-md-4">
                <app-input type="select" formControlName="type" appearance="outline" [label]="'Select'" multiple
                           [fullWidth]="true" [selectOptions]="emailSelectOptions" [selectLabel]="'label'" [selectValue]="'value'">
                </app-input>
              </div>

              <div class="col-md-4">
                <app-input type="select" formControlName="notification" appearance="outline" [label]="'Select'" multiple
                           [fullWidth]="true" [selectOptions]="notifications" [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </div>
            </div>

            <ng-container *ngFor="let formGroup of getFormArray().controls let i = index" formArrayName="receivers">
              <div [formGroupName]="i" class="row pt-4">
                <div class="col-md-2"><h3>{{'CONFIG.notifications.notification-to-stuff' | translate}}</h3></div>

                <div class="col-md-4">
                  <app-input type="select" formControlName="notification_to" appearance="outline" [label]="'Select'" multiple
                             [fullWidth]="true" [selectOptions]="notificationToSelectOptions" [selectLabel]="'label'" [selectValue]="'value'">
                  </app-input>
                </div>

                <div class="col-md-4" *ngIf="formGroup.value.notification_to === 'staff_in'">
                  <!--<angular2-multiselect [data]="stages" [settings]="dropdownSettings" formControlName="role"></angular2-multiselect>-->
                </div>

                <div class="col-md-4" *ngIf="formGroup.value.notification_to === 'staff_which'">
                  <app-input type="select" formControlName="role" appearance="outline" [label]="'Select'" multiple
                             [fullWidth]="true" [selectOptions]="roleSelectOptions" [selectLabel]="'label'" [selectValue]="'value'">
                  </app-input>
                </div>

                <div *ngIf="i !== getFormArray().controls.length-1" class="col-md-2">
                  <button mat-icon-button color="warn" (click)="removeReceiver(i)">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>

                <div *ngIf="i === getFormArray().controls.length-1" class="col-md-2">
                  <button mat-icon-button color="primary" (click)="addNewReceiver()">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>

            <div class="row pt-5">
              <button mat-raised-button color="primary" style="min-width: 120px" class="ml-3" type="submit">{{"SHARED.save" | translate}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>

