<!--<pre>{{ documentTypeCategories | json }}</pre>-->
<mat-tab-group>
  <mat-tab label="Agent">
    <h2>Agent View</h2>
    <app-document-request-list *ngIf="case" [case]="case" (documentsRequested)="reloadRequestedList()">
    </app-document-request-list>
  </mat-tab>
  <mat-tab label="Client">
    <h2>Client View</h2>
    <app-requested-document-uploader *ngIf="case" [case]="case" (documentsUploaded)="rebuildRequestForm()">
    </app-requested-document-uploader>
  </mat-tab>
</mat-tab-group>
