import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CaseVariable } from '../../../../../../../_base-shared/models/Case/Case';
import { SmsTemplate } from '../../../../../../../_base-shared/models/Notification/SmsTemplate';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { CaseService } from '../../../case/case.service';
import { UserService } from '../../../user/user.service';
import { SmsService } from '../sms.service';

@Component({
  selector:    'app-sms-editor',
  templateUrl: './sms-editor.component.html',
  styleUrls:   ['./sms-editor.component.scss'],
})
export class SmsEditorComponent implements OnInit, OnDestroy {
  public smsForm: UntypedFormGroup;
  public isLoading                              = 0;
  public sms: SmsTemplate;
  public editorType: string;
  public users: Array<User>                     = [];
  public templateVariables: Array<CaseVariable> = [];
  private subscriptions: Array<Subscription>    = [];

  constructor(
      private fb: UntypedFormBuilder,
      private router: Router,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private translate: TranslateService,
      private smsService: SmsService,
      private caseService: CaseService,
      private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.fetchVariables();
    this.fetchFromables();
    this.editorType = this.route.snapshot.data.editor;
    if (this.editorType === 'edit') {
      this.fetchSms(this.route.snapshot.paramMap.get('id'));
    } else {
      this.sms = new SmsTemplate();
      this.buildForm();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  fetchSms(smsId) {
    this.isLoading++;
    this.smsService.get(smsId)
        .pipe(
            finalize(() => this.isLoading--),
        )
        .subscribe(
            res => {
              this.sms = res.data;
              this.buildForm();
            },
            err => {
            },
        );
  }

  private fetchVariables() {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.indexCaseVariables().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.templateVariables = result.data),
    );
  }

  buildForm() {
    this.smsForm = this.fb.group({
      label:       [this.sms.label, Validators.required],
      description: [this.sms.description],
      subject:     [this.sms.subject],
      content:     [this.sms.content, Validators.required],
      active:      [this.sms.active, Validators.required],
    });
  }

  submit() {
    if (this.smsForm.invalid) {
      this.toastr.error(this.translate.instant('CONFIG.sms-template.sms-validation'));
      return;
    }
    if (this.editorType === 'edit') {
      const data = {
        smsId: this.route.snapshot.paramMap.get('id'),
        sms:   this.smsForm.value,
      };
      this.smsService.update(data)
          .subscribe(
              res => {
                this.toastr.success(
                    this.translate.instant('CONFIG.sms-template.edit-template-success'),
                    this.translate.instant('SHARED.success'),
                );
                this.router.navigateByUrl('/sms');
              },
              err => {
                this.toastr.error(
                    this.translate.instant('CONFIG.sms-template.edit-template-error'),
                    this.translate.instant('SHARED.error'),
                );
              },
          );
    } else {
      this.smsService.store(this.smsForm.value)
          .subscribe(
              res => {
                this.toastr.success(
                    this.translate.instant('CONFIG.sms-template.add-template-success'),
                    this.translate.instant('SHARED.success'),
                );
                this.router.navigateByUrl('/sms');
              },
              err => {
                this.toastr.error(
                    this.translate.instant('CONFIG.sms-template.add-template-error'),
                    this.translate.instant('SHARED.error'),
                );
              },
          );
    }
  }

  public addVariable($event) {
    const content = this.smsForm.get('content').value || '';
    this.smsForm.get('content').setValue(content + $event.target.innerText + ' ');
  }

  private fetchFromables() {
    this.isLoading++;
    this.subscriptions.push(
        this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.users = result.data),
    );
  }
}
