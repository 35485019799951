import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as cardValidation from 'creditcards';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PaymentCard } from '../../../../../../_base-shared/models/Payment/PaymentCard';
import { PaymentRequest } from '../../../../../../_base-shared/models/Payment/PaymentRequest';
import { User } from '../../../../../../_base-shared/models/User/User';
import { CustomValidators, expiryDateInPast } from '../../../../../../_base-shared/validators/custom.validators';
import { environment } from '../../../../environments/environment';
import {
  PaymentErrorModalComponent,
} from '../../../_shared/components/payment-error-modal/payment-error-modal.component';
import { ClientService } from '../../client.service';

@Component({
  selector:    'app-client-payment',
  templateUrl: './client-payment.component.html',
  styleUrls:   ['./client-payment.component.scss'],
  animations:  [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.3s ease-out',
              style({height: '*', opacity: 1})),
          ],
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in',
              style({height: 0, opacity: 0})),
          ],
        ),
      ],
    ),
    trigger('fadeAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.5s', style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.5s', style({opacity: 0})),
      ]),
    ]),
  ],
})
export class ClientPaymentComponent implements OnInit {
  public generalSiteUrl    = environment.GENERAL_SITE_URL;
  public companyPhoneLegal = environment.COMPANY_PHONE_LEGAL;
  public companyAddress    = environment.COMPANY_ADDRESS;
  public companyNameLegal  = environment.COMPANY_NAME_LEGAL;

  public isLoading = 0;
  public paymentRequest: PaymentRequest;
  public case: Case;
  public targetClient: User;
  public chargeAmount: number;
  public paymentCards: Array<PaymentCard>;

  public idCardForm: UntypedFormGroup;
  public submittingIdCard: boolean;
  public cardFormActive   = false;
  public paymentCardsForm: UntypedFormGroup;
  public newCardForm: UntypedFormGroup;
  public executingPayment = false;

  public isSuccessful: boolean;
  public paymentResponse: string;

  public debtPlanType: 'debt_plan' | 'additional_plans';
  private requestSignature: string;

  public desktopVersion: boolean;
  public isError = false;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private clientService: ClientService,
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    const paymentRequestUuid = this.route.snapshot.queryParamMap?.get('payment_request');
    this.requestSignature    = decodeURI(this.route.snapshot.queryParamMap?.get('signature'));

    const caseUuid             = this.route.snapshot.queryParamMap?.get('uuid');
    const requestChargeAmounts = this.route.snapshot.queryParamMap?.get('amount');

    this.buildIdCardForm();

    if (paymentRequestUuid && caseUuid && requestChargeAmounts) {
      this.isLoading++;
      this.clientService.getPaymentRequest(paymentRequestUuid, this.requestSignature, ['case.client'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.paymentRequest = result.data;
          this.case           = this.paymentRequest.case;
          this.targetClient   = this.case.client;
          this.chargeAmount   = +this.paymentRequest.amount;
          this.debtPlanType   = this.paymentRequest.charge_additional_plan ? 'additional_plans' : 'debt_plan';
          this.buildNewCardForm(this.targetClient, this.chargeAmount);
        });
    } else if (!paymentRequestUuid && caseUuid && requestChargeAmounts) {
      this.isLoading++;
      this.clientService.showCase(caseUuid).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.case         = result.data;
        this.targetClient = this.case.client;
        this.chargeAmount = +requestChargeAmounts;
        this.debtPlanType = this.route.snapshot.queryParamMap?.get('plan_type') === 'additional_plans' ?
          'additional_plans' :
          'debt_plan';
        this.buildNewCardForm(this.targetClient, this.chargeAmount);
      });
    }
  }

  private buildIdCardForm() {
    this.idCardForm = this.fb.group({
      id_card: [null, [Validators.required, Validators.maxLength(13)]],
    });
  }

  public submitIdCardAuthorization(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const requestData     = {signature: this.requestSignature, ...form.value};
    this.submittingIdCard = true;
    this.clientService.authorizePaymentRequest(this.paymentRequest.uuid, requestData)
      .pipe(finalize(() => this.submittingIdCard = false))
      .subscribe(result => {
        this.paymentCards   = result.data.map(card => {
          const dateOfExpiry       = moment(`${ card.card_exp_month }${ card.card_exp_year }`, 'MMYYYY')
            .startOf('month')
            .format();
          card.expires_at          = moment(dateOfExpiry).endOf('month').format();
          const expirationDateDiff = moment(card.expires_at).diff(moment(), 'months', true);

          if (expirationDateDiff <= 4) {
            card.expire = 'soon';
          }

          if (expirationDateDiff < 0) {
            card.expire = 'expired';
          }

          card.card_brand = card.card_brand ? card.card_brand : cardValidation.card.type(card.card_bin, true);

          return card;
        });
        this.idCardForm     = null;
        this.cardFormActive = true;
        this.buildPaymentCardsForm();
        this.buildNewCardForm(this.targetClient, this.chargeAmount);
      });
  }

  private buildPaymentCardsForm() {
    this.paymentCardsForm = this.fb.group({
      payment_request_uuid: [this.paymentRequest.uuid],
      card_id:              [null, [Validators.required]],
      amount:               [this.chargeAmount, [Validators.required, Validators.min(1), Validators.max(5000)]],
    });
  }

  private buildNewCardForm(client: User, chargeAmount: number) {
    this.newCardForm = this.fb.group({
      payment_request_uuid: [this.paymentRequest?.uuid],
      payment_plan:         [this.debtPlanType],
      amount:               [chargeAmount, [Validators.required, Validators.min(0.1), Validators.max(5000)]],
      card_number:          [null, [Validators.required, Validators.maxLength(19), CustomValidators.cardValidator]],
      cvv:                  [null, [Validators.required, Validators.maxLength(3), CustomValidators.cvcValidator]],
      holder:               [client ? (client.first_name + ' ' + client.last_name) : null],
      expiry_month:         [
        null,
        [Validators.required, Validators.maxLength(2), CustomValidators.expiryMonthValidator]],
      expiry_year:          [
        null,
        [Validators.required, Validators.maxLength(2), CustomValidators.expiryYearValidator]],
      brand:                [null, [Validators.required]],
      save_card:            [true, [Validators.required]],
    }, {validators: expiryDateInPast});

    //  Card type auto detection (visa or master)
    this.newCardForm.get('card_number').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(value => {
      let cardType = cardValidation.card.type(value, true);
      if (!cardType) {
        this.formatCardNumber(value);
        return;
      }
      cardType = cardType.toUpperCase();  //  Format string to uppercase - needed for backend
      if (cardType === 'MASTERCARD') {
        cardType = 'MASTER'; //  Trim mastercard string - needed for backend
      }
      this.newCardForm.patchValue({brand: cardType});
      this.formatCardNumber(value);
    });
  }

  public chargeExistingCard(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.executingPayment = true;
    this.clientService.chargeExistingCard(this.targetClient.uuid, this.case.uuid, form.value)
      .pipe(finalize(() => this.executingPayment = false))
      .subscribe(
        result => {
          this.isSuccessful    = true;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-success');

          Swal.fire({
            text: this.translate.instant('CARD-INFO.payment-success') + '!',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            cancelButtonText: this.translate.instant('SHARED.cancel'),
            confirmButtonColor: '#2764AE',
          });
          // window.location.href = 'https://www.deudafix.es/pago-recibido';
        },
        err => {
          Swal.fire({
            text:               this.translate.instant('CARD-INFO.payment-failed') + '!',
            icon:               'error',
            showCancelButton:   false,
            showConfirmButton:  true,
            cancelButtonText:   this.translate.instant('SHARED.cancel'),
            confirmButtonColor: '#2764AE',
          });
          this.isSuccessful    = false;
        },
      );
  }

  public chargeNewPaymentCard(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const requestData        = {...form.value};
    requestData.card_number  = cardValidation.card.parse(requestData.card_number);
    requestData.expiry_year  = cardValidation.expiration.year.parse(requestData.expiry_year, true);
    requestData.expiry_month = requestData.expiry_month.length === 1 ?
      `0${ requestData.expiry_month }` :
      requestData.expiry_month;

    this.executingPayment = true;
    this.clientService.chargeNewCard(this.targetClient.uuid, this.case.uuid, requestData)
      .pipe(finalize(() => this.executingPayment = false))
      .subscribe(
        result => {
          this.isSuccessful    = true;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-success');

          Swal.fire({
            text: this.translate.instant('CARD-INFO.payment-success') + '!',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            cancelButtonText: this.translate.instant('SHARED.cancel'),
            confirmButtonColor: '#2764AE',
          });
          // window.location.href = 'https://www.deudafix.es/pago-recibido';
        },
        err => {
          if (err.error?.errors?.card_charge === '100.100.700') {
            const dialogRef = this.dialog.open(PaymentErrorModalComponent);

            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                // Do something after close
              }
            });
          }
          Swal.fire({
            text:               this.translate.instant('CARD-INFO.payment-failed') + '!',
            icon:               'error',
            showCancelButton:   false,
            showConfirmButton:  true,
            cancelButtonText:   this.translate.instant('SHARED.cancel'),
            confirmButtonColor: '#2764AE',
          });
          this.isSuccessful = false;
          if (err.error?.errors?.brand) {
            Swal.fire({
              text:               this.translate.instant('CARD-INFO.payment-failed') + '. ' +
                                    'This card brand is invalid',
              icon:               'error',
              showCancelButton:   false,
              showConfirmButton:  true,
              cancelButtonText:   this.translate.instant('SHARED.cancel'),
              confirmButtonColor: '#2764AE',
            });
          }
        },
      );
  }

  private formatCardNumber(cardNumber: string) {
    const parseValue  = cardValidation.card.parse(cardNumber);
    const formatValue = cardValidation.card.format(parseValue);
    this.newCardForm.get('card_number').setValue(formatValue);
  }

  private findDefaultCardId(): number | null {
    const defaultCard = this.paymentCards.find(card => card.default);
    return defaultCard ? defaultCard.id : null;
  }

  public isImpago() {
    return this.case?.payment_status_id === 2 || this.case?.payment_status_id === 3 ||
      this.case?.payment_status_id === 6;
  }

  public dismissPopUp() {
    this.isError      = false;
    this.isSuccessful = false;
  }
}
