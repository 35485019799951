import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Proposal } from '../../../../../../../../_base-shared/models/CaseDocument/Proposal';
import { FinancialOverview } from '../../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { environment } from '../../../../../../environments/environment';
import { FinancialOverviewService } from '../../../../payment/financial-overview.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-proposal-modal',
  templateUrl: './proposal-modal.component.html',
  styleUrls:   ['./proposal-modal.component.scss'],
  animations:  [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.3s ease-out',
              style({height: '*', opacity: 1})),
          ],
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in',
              style({height: 0, opacity: 0})),
          ],
        ),
      ],
    ),
  ],
})
export class ProposalModalComponent implements OnInit {
  public case: Case;
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading  = 0;
  public storageUrl = environment.STORAGE_URL + '/';

  public financialOverview: FinancialOverview;
  public disposableIncome = 0;
  public unsecuredDebt    = 0;
  public showStepPayment  = false;

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private dialogRef: MatDialogRef<ProposalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translateService: TranslateService,
              private toast: ToastrService,
              private financialOverviewService: FinancialOverviewService) {
  }

  ngOnInit(): void {
    this.case               = this.data.case;
    const requiredRelations = [
      'product',
      'terms',
      'proposal',
    ];
    const loadRelations     = [];
    requiredRelations.forEach(relation => {
      if (!this.case[relation]) {
        loadRelations.push(relation);
      }
    });

    this.isLoading++;
    this.financialOverviewService.fetchLatest(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.financialOverview = result.data;
      this.isLoading++;
      this.caseService.get(this.case.id, loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(res => {
        loadRelations.forEach(relation => {
          this.case[relation] = res.data[relation];
        });
        this.disposableIncome = 0; // todo: remove
        this.unsecuredDebt    = 0; // todo: remove
        this.buildForm();
        this.calculateDividend();

        this.form.get('offer_to_creditors').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('term').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('debt_level').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('step_offer').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('step_period').valueChanges.subscribe(() => this.calculateDividend());
        if (this.case.product.slug === 'cajaplus') {
          this.form.get('legal_fees')
            .valueChanges
            .subscribe((value: number) => this.calculateOfferToCreditorsV2(value));
        } else {
          this.form.get('dividend').valueChanges.subscribe(value => this.calculateOfferToCreditors(value));
        }
      });
    });
  }

  public closeModal(proposal: Proposal | null, dismissed: boolean): void {
    this.dialogRef.close({dismissed, data: proposal});
  }

  private buildForm(): void {
    const proposal = this.case.proposal;

    let legalFees: number;
    let offerToCreditors: number;
    if (this.case.product.slug === 'cajaplus') {
      legalFees        = proposal?.legal_fees ?
        proposal?.legal_fees :
        Math.max(this.disposableIncome * 0.2 * 1.21, 50);
      offerToCreditors = +((Math.max(this.disposableIncome - legalFees, 0)).toFixed(2));
    } else {
      legalFees        = this.case.expense?.legal_fees || 0;
      offerToCreditors = proposal?.offer_to_creditors ? proposal.offer_to_creditors : null;
    }

    const totalInstallments = this.case.product.slug === 'cajaplus' ?
      Math.ceil(this.unsecuredDebt / (this.disposableIncome - legalFees)) :
      (proposal?.term ? proposal.term : 60);

    const disposableIncomeDisabled  = this.case.product.slug === 'cajaplus';
    const offerToCreditorsDisabled  = this.case.product.slug === 'cajaplus';
    const totalInstallmentsDisabled = this.case.product.slug === 'cajaplus';

    this.form = this.fb.group({
      total_income:             [0, [Validators.required]],
      debt_level:               [this.unsecuredDebt.toFixed(2), [Validators.required]],
      total_expenses:           [0, [Validators.required]],
      total_assets:             [0, [Validators.required]],
      disposable_income:        [
        {value: this.disposableIncome.toFixed(2), disabled: disposableIncomeDisabled},
        [Validators.required],
      ],
      legal_fees:               [
        {value: (legalFees).toFixed(2), disabled: false},
        [Validators.required],
      ],
      step_period:              [proposal?.step_period],
      step_offer:               [proposal?.step_offer],
      offer_to_creditors:       [
        {value: offerToCreditors, disabled: offerToCreditorsDisabled},
        [Validators.required],
      ],
      term:                     [
        {value: totalInstallments, disabled: totalInstallmentsDisabled},
        [Validators.required]],
      dividend:                 [
        proposal?.dividend ? proposal.dividend : null,
        [Validators.required],
      ],
      payment_date:             [
        proposal?.payment_date ? new Date(proposal.payment_date) : null,
        [Validators.required],
      ],
      creditor_payment_account: [proposal?.creditor_payment_account],
      payment_reference:        [proposal?.payment_reference],
    });

    this.formActive      = true;
    this.showStepPayment = !!proposal?.step_period;
  }

  private calculateOfferToCreditors(dividend: number): void {
    let offerToCreditors: number;
    const debtDevel  = this.form.get('debt_level').value;
    const term       = this.form.get('term').value;
    const stepOffer  = this.form.get('step_offer').value;
    const stepPeriod = this.form.get('step_period').value;

    if (this.showStepPayment) {
      offerToCreditors = +((
        ((+dividend / 100) * +debtDevel - (+stepOffer * +stepPeriod)) / (term - stepPeriod)
      ).toFixed(2));
    } else {
      offerToCreditors = +((((+dividend / 100) * +debtDevel) / +term).toFixed(2));
    }

    // Prevent infinite loop by disabling value change event
    this.form.get('offer_to_creditors').patchValue(offerToCreditors, {emitEvent: false, onlySelf: true});
  }

  private calculateOfferToCreditorsV2(legalFees: number): void {
    this.form.get('offer_to_creditors').patchValue(+((Math.max(this.disposableIncome - legalFees, 0)).toFixed(2)));
  }

  private calculateDividend(): void {
    let dividend: number;
    const offerToCreditors = this.form.get('offer_to_creditors').value;
    const term             = this.form.get('term').value;
    const debtLevel        = this.form.get('debt_level').value;
    const stepOffer        = this.form.get('step_offer').value;
    const stepPeriod       = this.form.get('step_period').value;

    if (this.showStepPayment) {
      const value = ((+offerToCreditors * (+term - stepPeriod) + (stepOffer * stepPeriod)) / +debtLevel).toFixed(4);
      dividend    = +(+value * 100).toFixed(2);
    } else {
      const value = ((+offerToCreditors * +term) / +debtLevel).toFixed(4);
      dividend    = +(+value * 100).toFixed(2);
    }

    // Prevent infinite loop by disabling value change event
    this.form.get('dividend').patchValue(dividend, {emitEvent: false, onlySelf: true});
  }

  public submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const requestData = {...this.form.getRawValue(), payment_date: this.form.get('payment_date').value.toISOString()};

    this.isLoading++;
    this.caseService.generateProposal(this.case.id, requestData).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.case.proposal = result.data;
        this.toast.success(this.translateService.instant('CASES.details.generate-proposal-success'));
        this.closeModal(this.case.proposal, false);
      },
      error => {
        console.error(error);
        this.toast.error(this.translateService.instant('CASES.details.generate-proposal-error'));
      });
  }

  public stepPayment(): void {
    this.showStepPayment = !this.showStepPayment;
    if (!this.showStepPayment) { // remove value in form
      this.form.get('step_period').patchValue(null);
      this.form.get('step_offer').patchValue(null);
    }
    //  Update form values to recalculate `dividend` field
    this.form.updateValueAndValidity();
  }
}
