<app-payment-list-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleFilters($event)"
                          (distributionVisibility)="handleDistributionVisibilityState($event)"
                          [onlyRelated]="onlyRelated" (wireTypeOfPayment)="handleWireTypeOfPayment($event)">
</app-payment-list-filters>

<p *ngIf="paymentListFilter?.payment_method && ['charge', 'pending'].includes(paymentListFilter.payment_method) &&
 !selection.isEmpty()" class="pl-5 mb-0 d-inline-block font-weight-bold">
  {{ "PAYMENTS.list.total-amount" | translate }}:
  <span *ngIf="paymentListFilter.select_all">{{ totalActionableAmount | number: '1.2-2' }}</span>
  <span *ngIf="!paymentListFilter.select_all">{{ totalAmount | number: '1.2-2' }}</span>
  €
</p>

<div class="basic-filters row">
  <div class="col-12">
    <div *ngIf="validationError" class="d-flex justify-content-center align-items-center">
      <p class="pl-5 mb-0" style="color:#e7026e;">{{ "PAYMENTS.validation-error" | translate }}</p>
    </div>
    <div *ngIf="successMessage" class="d-flex justify-content-center align-items-center">
      <p class="pl-5 mb-0" style="color:#155724;">{{ "PAYMENTS.toast-success" | translate }}</p>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between">
  <!-- Bulk Notifications Form -->
  <form *ngIf="bulkNotificationForm && (authUser.role_id === 1 || authUser.role_id === 5 || authUser.role_id === 7)"
        [formGroup]="bulkNotificationForm" #ngBulkForm class="d-flex flex-row pb-4 justify-content-between"
        (ngSubmit)="sendNotifications(bulkNotificationForm)">
    <div class="d-flex align-items-center">
      <mat-form-field appearance="standard">
        <mat-label>{{ "CASES.list.notifications.label" | translate }}</mat-label>
        <mat-select id="action" formControlName="action">
          <mat-option value="">{{ "CASES.list.notifications.options.none" | translate }}</mat-option>
          <mat-option value="sms">{{ "CASES.list.notifications.options.sms" | translate }}</mat-option>
          <mat-option value="email">{{ "CASES.list.notifications.options.email" | translate }}</mat-option>
          <mat-option value="all">{{ "CASES.list.notifications.options.all" | translate }}</mat-option>
        </mat-select>
        <div *ngIf="bulkNotificationForm.get('action').errors && ngBulkForm.submitted">
          <mat-error [hidden]="!bulkNotificationForm.get('action').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
      <button mat-raised-button class="ml-3" type="submit" color="primary"
              [disabled]="bulkNotificationForm.invalid || selection.selected.length === 0">
        {{ 'SHARED.send' | translate }}
      </button>
    </div>
    <div class="ml-auto mr-auto d-flex flex-column">
      <div class="d-flex">
        <div class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{ "PAYMENTS.list.boxes.billable-amount-selected" | translate }}</p>
          <p class="m-0">{{ this.billableAmount | number: '1.2-2' }}€</p>
        </div>
        <div class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{ "PAYMENTS.list.boxes.amount-billed" | translate }}</p>
          <p class="m-0">{{ this.amountBilled | number: '1.2-2' }}€</p>
        </div>
        <div class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{ "PAYMENTS.list.boxes.total" | translate }}</p>
          <p class="m-0">{{ (this.amountBilled + this.billableAmount) | number: '1.2-2' }}€</p>
        </div>
        <div class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{"PAYMENTS.list.boxes.active-plans" | translate }}</p>
          <p class="m-0">{{ this.activeClients }}</p>
        </div>
        <div *ngIf="showDistribution" class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{ "PAYMENTS.list.boxes.distribution-billable-amount" | translate }}</p>
          <p class="m-0">{{ this.distributionBillableAmount | number: '1.2-2' }}€</p>
        </div>
        <div *ngIf="showDistribution" class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{ "PAYMENTS.list.boxes.distribution-amount-billed" | translate }}</p>
          <p class="m-0">{{ this.distributionAmountBilled | number: '1.2-2' }}€</p>
        </div>
        <div *ngIf="showDistribution" class="ml-2 mb-2 d-flex flex-column align-items-center amount-box-item">
          <p class="text-center">{{ "PAYMENTS.list.boxes.distribution-total" | translate }}</p>
          <p class="m-0">{{ this.distributionAmountBilled + this.distributionBillableAmount | number: '1.2-2' }}€</p>
        </div>
      </div>
    </div>
  </form>
  <div class="text-right">
    <button mat-raised-button color="primary"  (click)="applyFilters()">
      {{ 'SHARED.apply_filters' | translate }}
    </button>
  </div>
</div>

<!--If all are selected and there is more to select on another page -->
<div *ngIf="filtersReady && paymentListFilter && pageSelected && (totalPages > 1)" class="row mb-3">
  <div class="col-12">
    <div class="global-select-all">
      <p class="m-0" *ngIf="!paymentListFilter.select_all">
        <span [innerHTML]="translate.instant('PAYMENTS.list.selected-local')
        .replace('{numTerms}', '<b>' + selection.selected.length + '</b>')">
        </span>
        <button mat-button color="primary" (click)="globalSelectAll($event)">
          {{ translate.instant("PAYMENTS.list.select-all").replace('{numTerms}', totalResults) }}
        </button>
      </p>
      <p class="m-0" *ngIf="paymentListFilter.select_all">
          <span [innerHTML]="translate.instant('PAYMENTS.list.selected-all')
          .replace('{numTerms}', '<b>' + totalResults + '</b>')">
          </span>
        <button mat-button color="primary" (click)="globalClearSelectAll($event)">
          {{ "PAYMENTS.list.clear-selection" | translate }}
        </button>
      </p>
    </div>
  </div>
</div>

<app-server-response [response]="serverResponse"></app-server-response>
<div *ngIf="filtersReady" class="mat-elevation-z8">
  <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- Selection -->
    <ng-container matColumnDef="select">
      <!-- Select All On Page -->
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                      [checked]="selection.hasValue() && allRowsSelected()"
                      [hidden]="!paymentListFilter?.payment_method"
                      [indeterminate]="selection.hasValue() && !allRowsSelected()">
        </mat-checkbox>
      </th>
      <!-- Select Row -->
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary" [checked]="selection.isSelected(row)"
                      [hidden]="!paymentListFilter.payment_method || !isCheckboxVisible(row)"
                      (change)="toggledRow($event, row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Ref Number -->
    <ng-container matColumnDef="ref_number">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.cases-reference" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/cases/{{element.case.id}}">{{element.case.ref_number}} </a>
      </td>
    </ng-container>
    <!-- Label -->
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.label" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.case_payment_plan.type.name }}<br>
        {{ element.name }}
      </td>
    </ng-container>
    <!-- First  Name  -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>{{ "USERS.model.first_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.case.client.first_name }}</td>
    </ng-container>
    <!-- Last  Name  -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>{{ 'USERS.model.last_name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.case.client.last_name }}</td>
    </ng-container>
    <!-- Payment Method -->
    <ng-container matColumnDef="payment_method">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.payment-method" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{getPaymentMethodName(
        element.case.debt_payment_plan?.payment_method_id)}} </td>
    </ng-container>
    <!-- Payment Status -->
    <ng-container matColumnDef="payment_status">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.payment-status" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.case.payment_status?.name}} </td>
    </ng-container>
    <!-- Next Payment -->
    <ng-container matColumnDef="term_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "PAYMENTS.payment-schedule" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.term_date | toDateObject | date }} </td>
    </ng-container>
    <!-- Date Paid -->
    <ng-container matColumnDef="date_paid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "PAYMENTS.date-paid" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.date_paid | toDateObject | date }}
        <ng-container *ngIf="element.date_paid">
          <p class="m-0" style="color: green" *ngIf="element.amount_paid === element.amount">
            <small>{{"PAYMENTS.paid" | translate }}</small>
          </p>
          <p class="m-0" style="color: orange"
             *ngIf="element.amount_paid < element.amount && element.amount_paid > 0">
            <small>{{"PAYMENTS.partial-paid" | translate }}</small>
          </p>
        </ng-container>
      </td>
    </ng-container>
    <!-- Amount -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <small class="font-weight-lighter">{{element.amount_paid | number: '1.2-2'}}€
          / {{ element.amount | number: '1.2-2' }}€ </small>
        <span class="font-weight-bold">- {{ element.amount - element.amount_paid | number: '1.2-2' }}€</span>
        <div class="progress" style="max-width: 120px">
          <div class="progress-bar progress-bar-striped" role="progressbar"
               [style]="{width: getPercent(element.amount_paid, element.amount)  + '%'}"
               [attr.aria-valuenow]="getPercent(element.amount_paid, element.amount)"
               [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
            {{ getPercent(element.amount_paid, element.amount) }}%
          </div>
        </div>
      </td>
    </ng-container>
    <!-- Has Card -->
    <ng-container matColumnDef="has_card">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.list.datatable.has-card.header" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="hasValidCard(element)" class="badge badge-success mr-1">Y</span>
        <span *ngIf="!hasValidCard(element)" class="badge badge-danger mr-1">N</span>
        <span *ngIf="hasMultipleCards(element)" class="badge badge-success mr-1"> M</span>
      </td>
    </ng-container>
    <!-- Term Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.list.status.header" | translate }}</th>
      <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status === 'pending'" class="badge badge-info mr-1">
              {{ "PAYMENTS.list.status.options.pending" | translate }}
            </span>
        <span *ngIf="element.status === 'paid'" class="badge badge-success mr-1">
              {{ "PAYMENTS.list.status.options.paid" | translate }}
            </span>
        <span *ngIf="element.status === 'failed'" class="badge badge-danger mr-1">
              {{ "PAYMENTS.list.status.options.failed" | translate }}
            </span>
        <span
          *ngIf="getPaymentMethodSlug(element.case.debt_payment_plan?.payment_method_id) === 'card' && !cardHasToken(element)"
          class="badge badge-info mr-1">
              {{ "PAYMENTS.list.status.options.no-payment-config" | translate }}
            </span>
        <span
          *ngIf="getPaymentMethodSlug(element.case.debt_payment_plan?.payment_method_id) === 'card' && cardHasExpired(element)"
          class="badge badge-info mr-1">
              {{ "PAYMENTS.list.status.options.card-expired" | translate }}
            </span>
        <span *ngIf="element.is_processing" class="badge badge-info mr-1">
              {{ "PAYMENTS.list.status.options.processing" | translate }}
            </span>
      </td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button (click)="$event.stopPropagation(); $event.preventDefault()" mat-stroked-button
                [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item class="dropdown-item" href="#"
             (click)="openChangeDateModal(element.id, $event)">{{ "PAYMENTS.change-date" | translate }}</a>
          <a mat-menu-item class="dropdown-item" href="#"
             (click)="openChangeAmountModal(element.id, $event)">{{ "PAYMENTS.change-value" | translate }}</a>
          <a mat-menu-item class="dropdown-item" href="#"
             (click)="openCardModal($event, element)">Take payment
          </a>
        </mat-menu>
      </td>
    </ng-container>
  </table>
  <!-- Loader -->
  <div *ngIf="isLoadingPayments > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- No Data -->
  <div *ngIf="isLoadingPayments < 1 && dataSource && dataSource.data?.length < 1" style="padding: 50px">
    <h3 class="text-center">{{ "PAYMENTS.no-payments" | translate }}</h3>
  </div>
  <!-- Pagination -->
  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                 [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
  </mat-paginator>
</div>
<!-- Footer Actions -->
<div class="bulk-actions d-flex pb-5 mt-2 mb-5 align-items-baseline">
  <!-- Payment Actions Form -->
  <form *ngIf="bulkPaymentActionForm" [formGroup]="bulkPaymentActionForm" #paymentForm
        (ngSubmit)="submitPayment(bulkPaymentActionForm)">
    <mat-form-field style="width: 100%" color="primary" appearance="standard">
      <!--<mat-label>Payment Action</mat-label>-->
      <mat-select formControlName="payment_action" (ngModelChange)="paymentActionChanged($event)">
        <mat-option *ngFor="let paymentOption of bulkPaymentOptions" [value]="paymentOption.value">
          {{ paymentOption.label }}
        </mat-option>
      </mat-select>
      <div *ngIf="bulkPaymentActionForm.get('payment_action').errors &&
     (bulkPaymentActionForm.get('payment_action').touched || paymentForm.submitted)">
        <mat-error [hidden]="!bulkPaymentActionForm.get('payment_action').hasError('required')">
          {{ "SHARED.field-required" | translate }}
        </mat-error>
      </div>
    </mat-form-field>
    <!-- Submit -->
    <button *ngIf="!processingPayment" [disabled]="disableApplyBtn" mat-raised-button color="primary" type="submit">
      {{ 'SHARED.apply' | translate }}
    </button>
    <!-- Performing Action Spinner -->
    <div class="d-flex justify-content-center align-items-center" *ngIf="processingPayment" style="min-width: 67px">
      <mat-spinner diameter="25" color="primary"></mat-spinner>
    </div>
  </form>
  <!-- Export -->
  <button *ngIf="authUser.role_id === 5 || authUser.role_id === 7"
          mat-raised-button color="primary" class="ml-2" (click)="exportRecords(paymentListFilter)" type="button">
    {{ 'PAYMENTS.list.export.button-name' | translate }}
  </button>
  <!-- Bill Cards -->
  <button mat-raised-button color="primary" class="ml-2 mr-2" (click)="billCards($event)"
          type="button">
    {{ 'PAYMENTS.list.bill-cards-button' | translate }}
  </button>
  <button *ngIf="authUser.role_id === 5" mat-raised-button color="primary" (click)="dialerListIdSelector()">
    {{ "CASES.list.push_to_dialer.button" | translate }}
  </button>
  <!-- Bulk action -->

  <button mat-raised-button color="primary" class="ml-3" [matMenuTriggerFor]="bulkActionsMenu">
    {{ "CASES.list.bulk_actions.button" | translate }}
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #bulkActionsMenu="matMenu">
    <a mat-menu-item class="dropdown-item" (click)="openChangeModal('status', paymentListFilter)">
      {{ "CASES.list.bulk_actions.change_case_status" | translate }}
    </a>
    <a mat-menu-item class="dropdown-item" (click)="openChangeModal('payment_status', paymentListFilter)">
      {{ "CASES.list.bulk_actions.change_payment_status" | translate }}
    </a>
    <a mat-menu-item class="dropdown-item" (click)="openChangeModal('department_assignments', paymentListFilter)">
      {{ "CASES.list.bulk_actions.change_department_status" | translate }}
    </a>
    <a mat-menu-item class="dropdown-item" (click)="openChangeModal('product_type', paymentListFilter)">
      {{'CASES.list.bulk_actions.change_product_type' | translate}}
    </a>
  </mat-menu>
</div>

<!-- Change Date Modal -->
<form [formGroup]="changeDateForm" (ngSubmit)="submitChangeDate(changeDateForm)">
  <div class="modal fade" #changeDateModal id="send-sms" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ "PAYMENTS.change-payment-date" | translate }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="pl-2">{{ "PAYMENTS.select-new-date" | translate }}</label>
            <div class="pb-3">
              <mat-form-field color="primary" appearance="fill">
                <mat-label>{{ "PAYMENTS.new-date" | translate }}</mat-label>
                <input matInput [matDatepicker]="newDate" formControlName="newDate">
                <mat-datepicker-toggle matSuffix [for]="newDate"></mat-datepicker-toggle>
                <mat-datepicker #newDate color="primary"></mat-datepicker>
              </mat-form-field>
            </div>
            <div>
              <label class="pl-2">{{ "PAYMENTS.change-date-for" | translate }}</label>
            </div>
            <mat-radio-group formControlName="newDateRadio" aria-label="Select an option">
              <mat-radio-button value="this_payment">{{ "PAYMENTS.this-payment" | translate }}</mat-radio-button>
              <mat-radio-button value="all_payments">{{ "PAYMENTS.all-payments" | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="modal-footer">
          <button class="mr-3" type="button" mat-stroked-button color="primary"
                  data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
          <button type="submit" mat-raised-button color="primary" [disabled]="!changeDateForm.value.newDate"
                  data-dismiss="modal">
            {{ "PAYMENTS.change-date" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
