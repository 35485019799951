import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { CaseCreditorStatusEditorComponent } from './case-creditor-status-editor/case-creditor-status-editor.component';
import { CaseCreditorStatusListComponent } from './case-creditor-status-list/case-creditor-status-list.component';

const routes: Routes = [
  {
    path:     'case-creditor-statuses',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: CaseCreditorStatusListComponent},
      {
        path:        'create',
        canActivate: [MainPermissionGuard],
        component:   CaseCreditorStatusEditorComponent,
        data:        {editor: 'create'},
      },
      {
        path:      ':id/edit',
        component: CaseCreditorStatusEditorComponent,
        data:      {editor: 'edit'},
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseCreditorStatusRoutingModule { }
