import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { CreditorBookValueComponent } from './creditor-book-value/creditor-book-value.component';
import { FinancialReportListComponent } from './financial-report-list/financial-report-list.component';
import { HeadlineReportsComponent } from './headline-reports/headline-reports.component';
import { MissingDocumentsComponent } from './missing-documents/missing-documents.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { PaymentReceivedModalComponent } from './financial-report-list/payment-received-modal/payment-received-modal.component';
import { SalesReportModalComponent } from './financial-report-list/sales-report-modal/sales-report-modal.component';
import { RunOffExportComponent } from './run-off-export/run-off-export.component';

@NgModule({
  imports:      [
    SharedModule,
    ReportsRoutingModule,
  ],
  declarations: [
    CreditorBookValueComponent,
    HeadlineReportsComponent,
    MissingDocumentsComponent,
    FinancialReportListComponent,
    PaymentReceivedModalComponent,
    SalesReportModalComponent,
    RunOffExportComponent,
  ],
})
export class ReportsModule {
}
