<div *ngIf="isLoading" class="row">
  <div class="col-12 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<form *ngIf="!isLoading && case?.id && form" [formGroup]="form" (ngSubmit)="submitForm(form)">
  <!-- ID Card -->
  <app-input formControlName="id_card" [label]="inputLabel" [extraLabel]="true" [fullWidth]="true"></app-input>
  <div *ngFor="let existingUser of takenByUsers" class="text-right">
    <button type="submit" mat-raised-button color="accent" style="min-width: 120px" (click)="mergeUsers(existingUser)">
      Merge #{{ existingUser.id }} {{ existingUser.first_name + ' ' + existingUser.last_name }}
    </button>
  </div>
  <div class="text-right mt-2 mb-2">
    <button type="submit" mat-raised-button color="primary" style="min-width: 120px">
      {{ 'SHARED.save' | translate }}
    </button>
  </div>
</form>
