import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { Product } from '../../../../../_base-shared/models/Product';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends MainBaseApiService {

  public index(data, relations: Array<string> = []) {
    data.with    = relations;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<Product>>>(this.apiUrl + '/products', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  public getDefaultProduct() {
    return this.http.get<LaravelResourceResponse<Product>>(this.apiUrl + '/products/default')
        .pipe(catchError(response => this.handleError(response)));
  }

  public updateCaseProduct(caseId: number, productData) {
    return this.http.put<LaravelResourceResponse<Case>>(
        this.apiUrl + '/cases/' + caseId + '/products', productData,
    ).pipe(catchError(response => this.handleError(response)));
  }

}
