import { CasePaymentPlan } from '../Payment/CasePaymentPlan';
import { PaymentPlan } from './PaymentPlan';

export type PaymentPlanTypeSlug = 'debt_plan' | 'attorney_fee' | 'claim_fee';

export class PaymentPlanType {
  id?: number;

  name_en?: string;
  name_es?: string;
  slug?: PaymentPlanTypeSlug;
  default?: 0 | 1 | boolean;
  custom?: 0 | 1 | boolean;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Accessors
  name?: string;

  // Relations
  payment_plans?: Array<PaymentPlan>;
  case_payment_plans?: Array<CasePaymentPlan>;
}
