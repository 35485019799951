<h1 mat-dialog-title>{{'CARD-INFO.header' | translate}}</h1>
<div mat-dialog-content class="custom-card-wrap" style="overflow: auto">
  <div *ngIf="fetchingCards && data.type === 'Manual charge'" style="min-height: 200px">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>

  <form *ngIf="formActive && !executingPayment && !paymentResponse" [formGroup]="form">
    <div *ngIf="data.type === 'Manual charge'" class="row pb-4">
      <div class="col-12">
        <mat-radio-group class="card-radio-group" aria-label="Select an option" formControlName="chosen_card">
          <ng-container *ngIf="userCards.length > 0">
            <div class="row">
              <div class="col-lg-6" *ngFor="let card of userCards">
                <mat-radio-button class="card-radio-button" [value]="card.id" [disabled]="card.expire === 'expired'"
                                  (change)="onCardSelectChange($event)">
                  <div class="d-flex card-list-item justify-content-between align-items-center">
                    <div>
                      <img *ngIf="card.card_brand === 'Visa'" class="img-fluid" src="assets/img/visaIcon.png" alt="">
                      <img *ngIf="card.card_brand === 'Mastercard'" class="img-fluid" src="assets/img/masterCardIcon.png"
                           alt="">
                    </div>
                    <div class="text-right">
                      <mat-hint class="d-block">**** **** **** {{ card.card_last_four }}
                        ({{ card.payment_processor?.type }})
                      </mat-hint>
                      <div>
                        <mat-hint class="pr-3" *ngIf="card.expire === 'soon'" style="color: #ff8100">Soon expiring
                        </mat-hint>
                        <mat-hint class="pr-3" *ngIf="card.expire === 'expired'" style="color: red">Card expired
                        </mat-hint>
                        <mat-hint>{{card.card_exp_month}} / {{card.card_exp_year}}</mat-hint>
                      </div>
                      <div style="min-height: 20px">
                        <a *ngIf="isAdmin()" href="#" class="remove-card" style="color: red"
                           (click)="removeCard($event, card.id)">
                          Remove card
                        </a>
                      </div>
                    </div>
                  </div>
                </mat-radio-button>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col-lg-6">
              <mat-radio-button class="card-radio-button" (change)="onCardSelectChange($event)" [value]="null">
                {{"CARD-INFO.new-card" | translate}}
              </mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
        <div class="row">
          <div class="col-lg-6">
            <app-input type="number" formControlName="amount" [suffix]="'€'" [fullWidth]="true"
                       [label]="'CASES.single.amount' | translate">
            </app-input>
          </div>
          <div class="col-lg-6 d-flex align-items-end">
            <mat-checkbox (change)="setDefaultCard($event)" color="primary" class="example-margin"
                          formControlName="default">
              Make this card default
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div [@inOutAnimation] *ngIf="form.value.chosen_card === null || data.type !== 'Manual charge'">
      <mat-tab-group dynamicHeight [selectedIndex]="tabIndex" (selectedIndexChange)="tabChange($event)">
        <mat-tab label="Cashflows">
          <div class="row">
            <div class="col-md-6 pb-3" *ngIf="data.amountInput && data.type !== 'Manual charge'">
              <label for="amount">{{'CASES.single.amount' | translate}}</label>
              <input id="amount" [placeholder]="translate.instant('CASES.single.amount')"
                     type="number" class="form-control" formControlName="amount">
              <mat-error
                *ngIf="(form.get('amount').touched && !form.get('amount').errors?.required) &&
               (form.get('amount').invalid) "
                class="pl-2">{{"CASES.single.charge-amount-invalid" | translate}}
              </mat-error>
              <mat-error *ngIf="form.get('amount').touched && form.get('amount').errors?.required">
                {{"SHARED.field-required" | translate}}
              </mat-error>
            </div>
            <div class="col-md-6 pb-3">
              <label for="brand">{{'CARD-INFO.card-type' | translate}}</label>
              <select class="form-control" formControlName="brand" name="brand" id="brand">
                <option value="MASTER">Master Card</option>
                <option value="VISA">Visa</option>
              </select>
            </div>
            <div class="col-md-6 pb-3">
              <label for="expiry_date">{{'CARD-INFO.ext-date' | translate}}</label>
              <div class="row">
                <div class="col">
                  <input id="expiry_date" maxlength="2" placeholder="MM" type="text" class="form-control"
                         formControlName="expiry_month">
                </div>
                <div class="col-1 text-center">
                  <h1>/</h1>
                </div>
                <div class="col">
                  <input id="expiry_date+year" maxlength="2" placeholder="YY" type="text" class="form-control"
                         formControlName="expiry_year">
                </div>
                <mat-error
                  *ngIf="(form.get('expiry_month').touched && form.get('expiry_month').errors?.expiryMonthInvalid) ||
                    (form.get('expiry_year').touched && form.get('expiry_year').errors?.expiryYearInvalid) ||
                    (form.get('expiry_year').touched && form.get('expiry_month').touched && form.errors?.expiryDateInPast)"
                  class="pl-2">{{"CARD-INFO.invalid-exp-date" | translate}}
                </mat-error>
              </div>
            </div>
            <div class="col-md-4 pb-3">
              <label for="card_holder">{{'CARD-INFO.card-holder' | translate}}</label>
              <input id="card_holder" [placeholder]="translate.instant('CARD-INFO.card-holder')" type="text"
                     class="form-control" formControlName="holder">
              <mat-error
                *ngIf="form.get('holder').touched && form.get('holder').errors?.required"
                class="pl-2">{{"CARD-INFO.name-required" | translate}}
              </mat-error>
            </div>
            <div class="col-md-4 pb-3">
              <label for="card_number">{{'CARD-INFO.card-number' | translate}}</label>
              <input id="card_number" (keyup)="formatCardNumber($event)"
                     [placeholder]="translate.instant('CARD-INFO.card-number')" maxlength="19"
                     placeholder="Enter payment amount" type="text" class="form-control" formControlName="card_number">
              <mat-error
                *ngIf="form.get('card_number').touched && form.get('card_number').errors?.cardInvalid"
                class="pl-2">{{"CARD-INFO.invalid-card-number" | translate}}
              </mat-error>
            </div>
            <div class="col-md-4 pb-3">
              <label for="cvv">CVV</label>
              <input id="cvv" maxlength="3" placeholder="CVV" type="text" class="form-control" formControlName="cvv">
              <mat-error
                *ngIf="form.get('cvv').touched && form.get('cvv').errors?.cvcInvalid"
                class="pl-2">{{"CARD-INFO.cvv-required" | translate}}
              </mat-error>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="MyMoid" *ngIf="appEnv === 'local' || appEnv === 'staging' || this.authUser.id === 2403">
          <div style="min-height: 100px">
            <div *ngIf="iFrameSrcSecured">
              <iframe #iframe id="payment-iframe" class="mymoid-iframe" [src]="iFrameSrcSecured" frameborder="0"
                      width="100%" height="800"></iframe>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

    </div>
  </form>
  <div *ngIf="executingPayment" style="min-height: 200px">
    <div class="text-center" *ngIf="!paymentResponse">
      <h2>{{'CARD-INFO.processing' | translate}}</h2>
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
  </div>
  <div *ngIf="paymentResponse" style="min-height: 200px">
    <div *ngIf="paymentResponse && isSuccessful">
      <h1 class="text-center">{{paymentResponse}}</h1>
      <p class="text-center">
        <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary">check_circle</mat-icon>
      </p>
    </div>
    <div *ngIf="paymentResponse && !isSuccessful" style="min-height: 200px">
      <h1 class="text-center">{{paymentResponse}}</h1>
      <p class="text-center">
        <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="warn">report</mat-icon>
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="tabIndex === 0">
  <div mat-dialog-actions>
    <div *ngIf="formActive" class="mb-3">
      <button mat-button color="primary" class="mr-3" *ngIf="!executingPayment && !paymentResponse"
              (click)="onNoClick({reFetch: false})">{{'SHARED.cancel' | translate}}</button>
      <button mat-raised-button class="pay-button" color="primary" *ngIf="!executingPayment && !paymentResponse"
              (click)="onSubmit()">{{'SHARED.pay' | translate}}
        <ng-container class="pay-button-amount-text" *ngIf="form.value.amount">
          ({{ form.value.amount | number: '1.2-2' }} €)
        </ng-container>
      </button>
      <button mat-raised-button color="primary" *ngIf="paymentResponse"
              (click)="onNoClick({reFetch: true})">{{'SHARED.finish' | translate}}</button>
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="tabIndex === 1">
  <div mat-dialog-actions>
    <div *ngIf="formActive" class="mb-3">
      <button mat-button color="primary" class="mr-3"
              (click)="onNoClick({reFetch: false})">{{'SHARED.cancel' | translate}}</button>
      <button *ngIf="!executingPayment && !paymentResponse && !iFrameSrcSecured" mat-raised-button class="pay-button"
              color="primary" (click)="requestPayment('mymoid_moto')">
        Request payment for
        <ng-container class="pay-button-amount-text" *ngIf="form.value.amount">
          ({{ form.value.amount | number: '1.2-2' }} €)
        </ng-container>
      </button>
      <!--              <button mat-raised-button color="primary" *ngIf="paymentResponse" (click)="onNoClick({reFetch: true})" >{{'SHARED.finish' | translate}}</button>-->
    </div>
  </div>
</div>
