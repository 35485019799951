import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { SystemEvent, SystemEventConfiguration } from '../../../../../../_base-shared/models/SystemEventConfiguration';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SystemEventService extends MainBaseApiService {
  public index(): Observable<LaravelResourceResponse<SystemEventConfiguration>> {
    return this.http.get<LaravelResourceResponse<SystemEventConfiguration>>(this.apiUrl + '/system-events')
      .pipe(catchError(response => this.handleError(response)));
  }

  public show(systemEventId: number): Observable<LaravelResourceResponse<SystemEvent>> {
    return this.http.get<LaravelResourceResponse<SystemEvent>>(this.apiUrl + '/system-events/' + systemEventId)
      .pipe(catchError(response => this.handleError(response)));
  }

  public store(data): Observable<LaravelResourceResponse<SystemEventConfiguration>> {
    return this.http.post<LaravelResourceResponse<SystemEventConfiguration>>(this.apiUrl + '/system-events', data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
