<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    <h2>{{"CASES.details.select-notary" | translate}}</h2>
  </div>

<!--  <div *ngIf="this.data.case.case_entities?.notary">-->
<!--    <h2>Selected notary: {{this.data.case.case_entities?.notary?.name}}</h2>-->
<!--  </div>-->



  <div class="row pb-5" [formGroup]="form">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-right-0">
            <i class="fal fa-search"></i>
          </span>
        </div>
        <input
          formControlName="search"
          type="text"
          class="form-control border-left-0 bg-transparent pl-0"
          (focus)="onFocus()"
          (blur)="onBlur()"
          [placeholder]="'SHARED.search' | translate ">
      </div>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" style="width: 100%">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="selectNotary(row)"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'active': selection.isSelected(row)}"
          (click)="selectNotary(row)">
      </tr>
    </table>
    <mat-error *ngIf="form.get('notary').touched && form.get('notary').errors?.required" class="pl-2">Please select notary</mat-error>

    <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
      <h3 class="text-center">There are no notaries</h3>
    </div>


    <mat-paginator
      [length]="paginatorConfig.length"
      [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50]"
      (page)="paginatorChange($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>

  <div class="text-right mt-5 pb-5 d-flex justify-content-end align-items-center">

    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal('')" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <button *ngIf="!isSending" mat-raised-button color="primary" (click)="submit()">
      {{"SHARED.select" | translate}}
    </button>
    <mat-spinner *ngIf="isSending" diameter="20" color="accent"></mat-spinner>
  </div>
</div>
