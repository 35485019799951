import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {PaymentService} from '../../../../payment/payment.service';
import {PaymentTerm} from '../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import {debounce, finalize} from 'rxjs/operators';
import {interval} from 'rxjs';

@Component({
  selector: 'app-split-term',
  templateUrl: './split-term.component.html',
  styleUrls: ['./split-term.component.scss']
})
export class SplitTermComponent implements OnInit {
  public term: PaymentTerm;
  public form: UntypedFormGroup;
  public isSubmitting = 0;
  private callCalculateMethod = true;
  constructor(private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<SplitTermComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toastr: ToastrService,
              private translate: TranslateService,
              private paymentService: PaymentService) {
  }
  ngOnInit(): void {
    this.term = this.data.term;
    this.buildForm(this.term);
  }

  private buildForm(term: PaymentTerm): void {
    this.form = this.fb.group({
      term_duration: ['', Validators.required],
      monthly_amount: ['', Validators.required],
      start_date: ['', Validators.required]
    });

    this.subscribeToFormChanges();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.isSubmitting++;
    this.paymentService.splitTerm(this.term.id, form.value)
      .pipe(finalize(() => this.isSubmitting--))
      .subscribe(
        response => {
          this.toastr.success('Success');
          this.dialogRef.close(response);
        },
        () => this.toastr.error('Error')
      );
  }

  private subscribeToFormChanges(): void {
    this.form.get('monthly_amount').valueChanges
      .subscribe(value => this.calculateNewTerms('monthly_amount'));

    this.form.get('term_duration').valueChanges
      .subscribe(value => this.calculateNewTerms('term_duration'));
  }

  private calculateNewTerms(key: string): void {
    if (!this.callCalculateMethod) {
      return;
    }

    if (key === 'monthly_amount') {
      const termDuration = Math.ceil(this.term.amount / this.form.get('monthly_amount').value);
      this.callCalculateMethod = false;

      this.form.get('term_duration').patchValue(termDuration);
    }

    if (key === 'term_duration') {
      const monthlyAmount = (this.term.amount / this.form.get('term_duration').value).toFixed(2);
      this.callCalculateMethod = false;

      this.form.get('monthly_amount').patchValue(monthlyAmount);
    }

    this.callCalculateMethod = true;
  }

}
