import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { AuthService } from '../../../auth/auth.service';
import { ClientService } from '../../../client/client.service';

@Component({
  selector:    'app-non-staff-navigation',
  templateUrl: './non-staff-navigation.component.html',
  styleUrls:   ['./non-staff-navigation.component.scss'],
})
export class NonStaffNavigationComponent implements OnInit {
  @Input() authUser: User;
  @Input() desktopNav: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
