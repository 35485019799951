import {Product} from './Product';

export class AffiliateProductPivot {
    affiliate_id?: number;
    product_id?: number;
    tracking_type?: string;
    cost?: number;
    status_id?: number;
}

export class AffiliateProduct extends Product {
    pivot?: AffiliateProductPivot;
}
