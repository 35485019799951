import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { AffiliateUserService } from '../affiliate-user.service';

@Component({
  selector:    'app-affiliate-create-lead',
  templateUrl: './affiliate-create-lead.component.html',
  styleUrls:   ['./affiliate-create-lead.component.scss'],
})
export class AffiliateCreateLeadComponent implements OnInit {
  public isLoading = 0;
  public submitting: boolean;
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public serverResponse: string;
  public authUser: User;
  public affiliateIds: Array<number>;

  constructor(
      private fb: UntypedFormBuilder,
      public affiliateUserService: AffiliateUserService,
      private router: Router,
      public globalEventsService: MainGlobalEventService,
      private toastr: ToastrService,
      private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.affiliateIds = this.authUser.affiliates ? this.authUser.affiliates.map(a => a.id) : [];
    });
    this.buildForm();
  }

  public submit(form: UntypedFormGroup) {
    if (form.invalid) {
      return;
    }

    this.formSubmitted = true;
    this.submitting    = true;

    const data = form.value;
    this.affiliateUserService.storeLead(data).pipe(finalize(() => this.submitting = false))
        .subscribe(res => {
              this.router.navigate(['/']);
            }, (err) => {
              this.serverResponse = err.error;
              if (err.status === 422) {
                const errorBag  = err.error.errors;
                const errorKeys = Object.keys(errorBag);
                errorKeys.forEach(key => {
                  this.toastr.error(errorBag[key], err.error.message,
                      {closeButton: true, disableTimeOut: true},
                  );
                });
              } else {
                this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
              }
            },
        );
  }

  private buildForm() {
    this.form = this.fb.group({
      first_name:   [null, [Validators.required]],
      last_name:    [null, [Validators.required]],
      email:        [null, [Validators.required, Validators.email]],
      mobile:       [null, [Validators.required]],
      affiliate_id: [this.authUser.affiliates[0].track_id],
      note:         [null],
      product_type: ['cajaplus'],
    });
  }
}
