import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  BasePaymentHandlerComponent
} from '../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PaymentCard } from '../../../../../../_base-shared/models/Payment/PaymentCard';
import { User } from '../../../../../../_base-shared/models/User/User';
import { ScriptLoaderService } from '../../../../../../_base-shared/services/script-loader.service';
import { environment } from '../../../../environments/environment';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../../case/case.service';
import { PaymentService } from '../payment.service';

@Component({
  selector:    'app-admin-payment-handler',
  templateUrl: '../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component.html',
  styleUrls:   [
    '../../../../../../_base-shared/components/payment/base-payment-handler/base-payment-handler.component.scss',
    './admin-payment-handler.component.scss'
  ]
})
export class AdminPaymentHandlerComponent extends BasePaymentHandlerComponent implements OnInit {
  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected fb: UntypedFormBuilder,
              protected dialog: MatDialog,
              protected breakpointObserver: BreakpointObserver,
              protected translate: TranslateService,
              protected toastr: ToastrService,
              protected scriptLoader: ScriptLoaderService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              @Optional() public dialogRef: MatDialogRef<AdminPaymentHandlerComponent>,
              protected caseService: CaseService,
              protected paymentService: PaymentService,
              protected globalEventsService: MainGlobalEventService,
  ) {
    super(route, router, fb, dialog, breakpointObserver, translate, toastr, scriptLoader, data);
    console.log(this.data?.paymentPlanType)

  }

  ngOnInit(): void {
    super.ngOnInit();
    

    if (this.data?.type === 'moto') {
      this.amountToCharge  = this.data.amount;
      this.paymentPlanType = this.data.paymentPlanType;
      this.installment     = this.data.installment;
      this.dueDate         = this.installment ? new Date(this.installment.term_date) : null;
      this.editableAmount  = this.data.editableAmount;
      this.redirectNewTab  = false;
      this.autoRedirect    = false;
      this.clientRole      = this.data.clientRole;
      this.fetchCase(this.data.caseId);
    }
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
    });
  }

  public makeDefault(paymentCard: PaymentCard) {
    super.baseMakeDefault(paymentCard, this.paymentService.setDefaultPaymentCard(this.case.uuid, paymentCard.uuid));
  }

  public deleteCard(paymentCard: PaymentCard) {
    super.baseDeleteCard(paymentCard, this.paymentService.deletePaymentCardPublic(this.case.uuid, paymentCard.uuid));
  }

  protected submitFormToApi(userUuid: string, caseUuid: string, formValue): void {
    super.makePayment(this.paymentService.makePayment(userUuid, caseUuid, formValue));
  }

  protected submitNewCardFormToApi(userUuid: string, caseUuid: string, formValue): void {
    super.makeNewCardPayment(this.paymentService.submitNewCardPayment(userUuid, caseUuid, formValue));
  }

  protected fetchCase(caseId: number): void {
    this.isLoading++;
    this.caseService.get(this.data.caseId, ['client', 'partner'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.case              = result.data;
        this.targetClient      = this.clientRole === 'partner' && this.case.partner ? this.case.partner : this.case.client;
        const maxPayableAmount = this.data.maxPayableAmount ? Math.min(this.data.maxPayableAmount, 5000) : 5000;
        this.fetchPaymentCards(this.case.client, this.case, this.data.amount, maxPayableAmount, true);
      });
  }

  protected fetchPaymentCards(client: User, clientCase: Case, amount: number, maxPayableAmount: number, isMotoPayment: boolean): void {
    const requestData  = {force_default_processor: this.paymentPlanType === 'debt_plan'};
    this.fetchingCards = true;
    this.paymentService.getCreditCards(this.case.id, requestData).pipe(finalize(() => this.fetchingCards = false))
      .subscribe(result => {
        this.paymentCards           = this.mapPaymentCards(result.data);
        this.redirectSuccess        = '/cases/' + this.case.id + '/payments?payment_outcome=success';
        this.redirectError          = '/cases/' + this.case.id + '/payments?payment_outcome=error';
        this.redirectCancelled      = '/cases/' + this.case.id + '/payments?payment_outcome=cancelled';
        const redirectSuccessFull   = environment.APP_URL + this.redirectSuccess;
        const redirectErrorFull     = environment.APP_URL + this.redirectError;
        const redirectCancelledFull = environment.APP_URL + this.redirectCancelled;

        this.buildForm(client, amount, maxPayableAmount, isMotoPayment, redirectSuccessFull, redirectErrorFull, redirectCancelledFull);
      });
  }

  protected handleComponentDestruction(redirectUrl: string = null): void {
    this.dialogRef.close();
    super.handleComponentDestruction(redirectUrl);
  }
}
