import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../../../admin/case/case.service';
import { ClientService } from '../../client.service';

@Component({
  selector:    'app-sepa-payment',
  templateUrl: './sepa-payment.component.html',
  styleUrls:   ['./sepa-payment.component.scss'],
})
export class SepaPaymentComponent implements OnInit {
  public formActive        = false;
  public sepaForm: UntypedFormGroup;
  private uuid;
  private caseId;
  public isSubmitting      = false;
  public isSuccessful      = false;
  public serverError       = false;
  public companyName       = environment.COMPANY_NAME;
  public companyNameLegal  = environment.COMPANY_NAME_LEGAL;
  public companyAddress    = environment.COMPANY_ADDRESS;
  public generalSiteUrl    = environment.GENERAL_SITE_URL;
  public companyPhoneLegal = environment.COMPANY_PHONE_LEGAL;

  constructor(
      private fb: UntypedFormBuilder,
      private globalEventsService: MainGlobalEventService,
      private route: ActivatedRoute,
      private clientService: ClientService,
      private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.queryParamMap?.get('uuid');
    if (!this.uuid) {
      this.globalEventsService.authUser$
          .subscribe(user => {
            this.getUserCase(user.id);
          });
    } else {
      this.getCase();
    }
  }

  getUserCase(userId) {
    this.caseService.index({user_id: userId, 'with[]': ['debt_payment_plan']})
        .subscribe(
            res => {
              this.uuid = res.data[0]?.uuid;
              this.buildForm(res.data[0]);
            },
            err => {
              console.log(err);
            },
        );
  }

  getCase() {
    this.caseService.caseInfo(this.uuid)
        .subscribe(res => {
          this.caseId = res.data.id;
          this.buildForm(res.data);
        });
  }

  buildForm(userCase) {
    this.sepaForm   = this.fb.group({
      iban_first_name: [userCase.client.first_name, Validators.required],
      iban_last_name:  [userCase.client.last_name, Validators.required],
      iban:            ['', [Validators.required, Validators.pattern('(ES|es)[0-9]{22}')]],
      terms:           [true, Validators.required],
    });
    this.formActive = true;
  }

  onSubmit() {
    if (this.sepaForm.invalid) {
      this.sepaForm.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.clientService.saveSepaInfo(this.uuid, this.sepaForm.value)
        .pipe(finalize(() => this.isSubmitting = false))
        .subscribe(
            res => this.isSuccessful = true,
            err => this.serverError = true,
        );
  }
}
