import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { OppwaOrder } from '../../../../../../../../_base-shared/models/Payment/Transaction/OppwaOrder';
import { DistributionService } from '../../../../distribution/distribution.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-transaction-list',
  templateUrl: './case-transaction-list.component.html',
  styles:      []
})
export class CaseTransactionListComponent implements OnInit {
  @Input() case: Case;
  @Input() reFetchTransactions: EventEmitter<OppwaOrder>;
  @Input() componentType: 'distribution' | 'admin';
  public transactions: Array<OppwaOrder> = [];

  constructor(private caseService: CaseService,
              private distributionService: DistributionService) {
  }

  ngOnInit(): void {
    this.reFetchTransactions.subscribe(() => this.fetchTransactions(this.componentType));
    this.fetchTransactions(this.componentType);
  }

  public updateComponentType(type: 'distribution' | 'admin') {
    this.componentType = type;
    this.fetchTransactions(type);
  }

  private fetchTransactions(componentType: 'admin' | 'distribution') {
    const data: { case_id: number, all_results: number, success_status: null | number } = {
      case_id:        this.case.id,
      all_results:    1,
      success_status: null
    };

    if (componentType === 'distribution') {
      data.success_status = 1;
    }

    const observable = componentType === 'admin' ?
                       this.caseService.getTransactions(data, ['payment_processor', 'latest_transaction']) :
                       this.distributionService.indexTransactions(this.case.uuid, data, ['payment_processor', 'latest_transaction']);

    observable.subscribe(result => {
      this.transactions = result.data;
    });
  }
}
