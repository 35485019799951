import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-upload-document-type',
  templateUrl: './upload-document-type.component.html',
  styleUrls: ['./upload-document-type.component.scss']
})
export class UploadDocumentTypeComponent implements OnInit {
  @Input() type;
  @Input() uploader;
  @Input() uuId;
  @Input() file;
  @Input() creditors;
  @Input() banks;
  @Input() files: BehaviorSubject<any>;

  public noOfFiles = ['file'];

  constructor() { }

  ngOnInit(): void {
  }

  addFile() {
    this.noOfFiles.push('file');
  }

}
