import { Case } from './Case/Case';
import { Payment } from './Payment/Payment';
import { User } from './User/User';

export type NoteType = 'note' | 'payment' | 'draft' | 'distribution' | 'case_creditor';
export type NoteContactType = 'no_contact' | 'email_contact' | 'sms_contact' | 'call_contact' | 'whatsapp_contact';

export class Note {
  id?: number;
  user_id?: number;
  case_id?: number;
  noteable_type?: string;
  noteable_id?: number;
  type?: 'note' | 'payment' | 'draft' | 'distribution' | 'case_creditor';
  customer_contact_type?: NoteContactType;
  note?: string;
  contacted_at?: string | Date;
  created_at?: string | Date;
  updated_at?: string | Date;
  sticky?: boolean;
  case_creditor_id: number;
  // Relations
  user?: User;
  case?: Case;
  noteable?: Payment;
}
