import { NgModule } from '@angular/core';
import { SharedClientModule } from '../_shared/shared-client.module';
import { ClientPaymentComponent } from './client-payment/client-payment.component';
import { PaymentOutcomeComponent } from './client-payment/payment-outcome/payment-outcome.component';
import { TrustboxComponent } from './client-payment/trustbox/trustbox.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { SepaPaymentComponent } from './sepa-payment/sepa-payment.component';
import { AuthPaymentComponent } from './auth-payment/auth-payment.component';
import { ClientPublicFooterComponent } from './client-payment/client-public-footer/client-public-footer.component';
import { ClientPaymentRedirectComponent } from './client-payment-redirect/client-payment-redirect.component';

@NgModule({
  imports:      [
    SharedClientModule,
    PaymentRoutingModule,
  ],
  declarations: [
    PaymentConfirmationComponent,
    ClientPaymentComponent,
    PaymentOutcomeComponent,
    SepaPaymentComponent,
    TrustboxComponent,
    AuthPaymentComponent,
    ClientPaymentRedirectComponent,
    ClientPublicFooterComponent,
  ],
})
export class PaymentModule {
}
