<h1 class="pb-5">{{ 'DISTRIBUTION.report.heading' | translate }}</h1>
<div class="row">
  <div class="col-md-12 mb-4">
  <h2 class="text-center">{{ "DISTRIBUTION.report.subheadings.performance_reports" | translate }}</h2>
  </div>
  <div *ngFor="let report of performanceReports" class="col-4">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ report.title | translate }}</h2>
      </div>
      <div class="card-body">
        <p *ngIf="report.description">
          {{ report.description | translate }}
        </p>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary"
                  [disabled]="!report.enabled" (click)="openDistributionModal($event, report.reportType, report.reportGroup)">
            {{ 'DISTRIBUTION.report.submit_label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 mt-5 mb-4">
    <h2 class="text-center mb-0">{{ "DISTRIBUTION.report.subheadings.funding_reports" | translate }}</h2>
  </div>
  <div *ngFor="let report of fundingReports" class="col-4">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ report.title | translate }}</h2>
      </div>
      <div class="card-body">
        <p *ngIf="report.description">
          {{ report.description | translate }}
        </p>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary"
                  [disabled]="!report.enabled" (click)="openDistributionModal($event, report.reportType, report.reportGroup)">
            {{ 'DISTRIBUTION.report.submit_label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
