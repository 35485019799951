import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { PaymentTerm } from '../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import {
  CaseCreditorPaymentStatus,
} from '../../../../../../../../_base-shared/models/Status/CaseCreditorPaymentStatus';
import { PaymentService } from '../../../../payment/payment.service';
import { CaseCreditorService } from '../../../case-creditor.service';

@Component({
  selector:    'app-payment-status-modal',
  templateUrl: './payment-status-modal.component.html',
  styles:   [],
})
export class PaymentStatusModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public installment: PaymentTerm;
  public creditorPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];
  public isLoading                                                 = 0;
  public isSubmitting                                              = false;

  constructor(private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<PaymentStatusModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toastr: ToastrService,
              private translate: TranslateService,
              private paymentService: PaymentService,
              private caseCreditorService: CaseCreditorService,
  ) {
  }

  ngOnInit(): void {
    this.installment = this.data.term;
    this.fetchCreditorPaymentStatuses();
    this.buildForm(this.installment);
  }

  public buildForm(installment: PaymentTerm): void {
    this.form = this.fb.group({
      case_creditor_payment_status_id: [this.installment.case_payment_plan.case_creditor.payment_status_id, Validators.required],
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.paymentService.changePaymentStatus(this.installment.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        response => {
          this.toastr.success(this.translate.instant('CASES.single.payments.change_payment_status.change_payment_status-success'));
          this.dialogRef.close(response.data);
        },
        () => this.toastr.error(
          this.translate.instant('SHARED.error')),
      );
  }

  private fetchCreditorPaymentStatuses(): void {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorPaymentStatuses({select_all: 1}, [], ['name'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.creditorPaymentStatuses = result.data,
        error => this.toastr.error(this.translate.instant('SHARED.error')),
      );
  }

}
