import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { PaymentInfoResource } from '../../../../../../../_base-shared/models/Payment/PaymentInfoResource';
import { ClientService } from '../../../client.service';

@Component({
  selector:    'app-mymoid-response',
  templateUrl: './mymoid-response.component.html',
  styleUrls:   ['./mymoid-response.component.scss'],
})
export class MymoidResponseComponent implements OnInit {
  public responseSuccess: boolean;
  public paymentInfo: PaymentInfoResource;
  public transactionableUuid: string;

  constructor(private route: ActivatedRoute,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.responseSuccess     = !!this.route.snapshot.data.success;
    this.transactionableUuid = this.route.snapshot.queryParamMap.get('transactionableUuid');
    this.clientService.getPaymentInfo(this.transactionableUuid).pipe().subscribe(
        result => {
          const paymentInfo           = result.data;
          paymentInfo.retry_signature = encodeURI(paymentInfo.retry_signature);
          this.paymentInfo            = paymentInfo;
        },
        error => {
          console.error('Failed to get payment info');
        },
    );
  }

}
