<button mat-stroked-button type="button" color="primary" class="btn mt-2 ml-2"
        (click)="clearFilters()">{{ "SHARED.reset-filters" | translate }}</button>
<div class="filters pb-5 pt-3" *ngIf="form" [formGroup]="form">
  <div class="advanced-filters">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "PAYMENTS.advanced-filters" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-4">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</mat-label>
              <mat-select formControlName="ownership" multiple>
                <mat-option
                  value="applicant">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</mat-option>
                <mat-option
                  value="partner">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</mat-option>
                <mat-option value="joint">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</mat-option>
              </mat-select>
              <button *ngIf="form.value.ownership.length" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearMultiSelect($event, 'ownership')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-lg col-xl">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ 'CASE_CREDITOR.model.verified.label' | translate }}</mat-label>
              <mat-select formControlName="verified" multiple>
                <mat-option [value]="1">{{"CASE_CREDITOR.model.verified.options.true" | translate}}</mat-option>
                <mat-option [value]="0">{{"CASE_CREDITOR.model.verified.options.false" | translate}}</mat-option>
              </mat-select>
              <button *ngIf="form.value.verified.length" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearMultiSelect($event, 'verified')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-lg col-xl">
            <!-- Creditor Status -->
            <app-input type="select" formControlName="status" appearance="standard" fullWidth="true" multiple
                       [label]="'CASE_CREDITOR.model.response_received.label' | translate" [showClear]="true"
                       [selectOptions]="creditorStatuses" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!-- Claim Creditors -->
<h3>{{ "CASE_CREDITOR.model.claim" | translate }}</h3>
<button mat-raised-button color="primary" [matMenuTriggerFor]="menuClaim">
  {{ "SHARED.bulk-actions" | translate }}
</button>
<mat-menu #menuClaim="matMenu">
  <!--Send mandate-->
  <div [title]="claimVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
    <button type="button" mat-menu-item [disabled]="claimVerifyDisabled"
            (click)="verifyCreditor(selectionClaim, 'claim_creditors')">
      {{ "CASES.details.verify-send-mandates" | translate }}
    </button>
  </div>
  <!-- Send extrajudicial claim -->
  <div
    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
    <button mat-menu-item [disabled]="!selectionClaim.selected.length"
            (click)="sendExtrajudicialClaim(selectionClaim)">
      {{ 'SHARED.send' | translate }} {{'CASES.single.creditors.actions.extrajudicial_claim' | translate}}
    </button>
  </div>
  <!-- Send right of access -->
  <div
    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
    <button mat-menu-item [disabled]="!selectionClaim.selected.length"
            (click)="sendRightOfAccessNotice(selectionClaim)">
      {{ 'SHARED.send' | translate }} {{'CASES.single.creditors.actions.right_of_access' | translate}}
    </button>
  </div>
  <!-- Mark as won -->
  <div
    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
    <button mat-menu-item [disabled]="!selectionClaim.selected.length"
            (click)="openPaymentPlanGenerationModal(selectionClaim)">
      {{'CASES.single.creditors.actions.mark_as_won' | translate}}
    </button>
  </div>

  <!-- Court Fees -->
  <div
    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
    <button mat-menu-item [disabled]="!selectionClaim.selected.length"
            (click)="openCourtFeesModal(selectionClaim)">
      {{'CASES.single.creditors.actions.set_court_fee' | translate}}
    </button>
  </div>

  <!-- DDA -->
  <div
    [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
    <button mat-menu-item [disabled]="!selectionClaim.selected.length"
            (click)="selectAsDDA(selectionClaim)">
     {{'CASES.single.creditors.actions.dda_received' | translate}}
    </button>
  </div>
</mat-menu>
<div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
  <table style="width: 100%" mat-table matSort [dataSource]="claimCreditors">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle(selectionClaim, claimCreditors) : null" color="primary"
                      [checked]="selectionClaim.hasValue() && isAllSelected(selectionClaim, claimCreditors)"
                      [indeterminate]="selectionClaim.hasValue() && !isAllSelected(selectionClaim, claimCreditors)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                      (change)="$event ? selectionClaim.toggle(row) : null"
                      [checked]="selectionClaim.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Reference number -->
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.reference_number' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}" [routerLink]="[element.id]">
        {{element.reference_number}}
      </td>
    </ng-container>

    <!--  Contract Number  -->
    <ng-container matColumnDef="contract_number">
      <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.contract_number' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}" [routerLink]="[element.id]">
        {{element.contract_number}}
      </td>
    </ng-container>

    <!-- Creditor Name -->
    <ng-container matColumnDef="creditor_name">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{ element.creditor?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="case_creditor_product">
      <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.products' | translate}}</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <ng-container> {{ element.case_creditor_product?.name ?
          element.case_creditor_product?.name :
          element.case_creditor_product?.name_es }}
        </ng-container>
      </td>
    </ng-container>

<!--    <ng-container matColumnDef="debt_type">
      <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <ng-container>
          {{ 'CASE_CREDITOR.model.debt_type.options.' + element.debt_type | translate }}
        </ng-container>
      </td>
    </ng-container>-->

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <ng-container>{{ element.status?.name ? element.status?.name : element.status?.name_es }}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="claim_payment_status">
      <th mat-header-cell *matHeaderCellDef>{{'CASES.claim-payment-status' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <ng-container>{{ element.latest_case_creditor_payment_status_change?.to_status?.name_es}}</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="time_in_status">
      <th mat-header-cell *matHeaderCellDef>{{'CASES.time-in-status' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <ng-container>{{ returnDifferenceInDays(element.latest_case_creditor_status_change?.created_at)}}</ng-container>
      </td>
    </ng-container>

    <!--TODO: ADD EXTRAJUDICAL DATE-->
    <ng-container matColumnDef="extrajudicial_claim_sent_at">
      <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.date_of_extrajudical_claim_sent' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">{{element.extrajudicial_claim_sent_at | date: 'd/M/y'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="court_fees">
      <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.court_fees' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{element.court_fees | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="court_fees_status">
      <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.court_fees_status' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{element.court_fees_status}}
      </td>
    </ng-container>

    <ng-container matColumnDef="win_type">
      <th mat-header-cell *matHeaderCellDef>{{'CASES.single.payments.case_payment_plan_generator.form.win_type.label' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{element.win_type}}
      </td>
    </ng-container>

    <ng-container matColumnDef="win_amount">
      <th mat-header-cell *matHeaderCellDef>{{'CASES.single.payments.case_payment_plan_generator.form.win_amount' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{element.win_amount | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="fee_amount">
      <th mat-header-cell *matHeaderCellDef>{{'CASES.single.payments.case_payment_plan_generator.form.fee_amount' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{element.fee_amount | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="case_creditor_invoice_status">
      <th mat-header-cell *matHeaderCellDef>{{'CASES.single.payments.case_payment_plan_generator.form.invoice_status' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        {{element?.case_creditor_invoice_status?.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="right-of-access-answered">
      <th mat-header-cell *matHeaderCellDef>DDA</th>
      <td mat-cell *matCellDef="let element" class="text-center"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <mat-icon *ngIf="element.right_of_access_answered" class="text-success mat-icon">check</mat-icon>
        <mat-icon *ngIf="!element.right_of_access_answered" class="text-danger mat-icon">clear</mat-icon>
      </td>
    </ng-container>


    <ng-container matColumnDef="loader">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
        <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.id) !== -1"
                     diameter="20" color="accent"></mat-spinner>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="isFetching" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isFetching && claimCreditors && !claimCreditors.data.length" style="padding: 50px">
    <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
  </div>
</div>

