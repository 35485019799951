<form *ngIf="formReady" class="row" [formGroup]="form" (ngSubmit)="submit()">
  <div class="col-12">
    <h1>{{"CONFIG.misc.header" | translate}}</h1>
  </div>
  <div class="col-12">
    <div class="card shadow">
      <div class="card-body pt-5">
        <div class="container">
          <div class="row pb-5">
            <!-- LSO -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target" [fullWidth]="true"
                         [label]="'CONFIG.misc.full-target' | translate">
              </app-input>
            </div>
            <!-- LSO2 -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_lso2" [fullWidth]="true"
                         [label]="'CONFIG.misc.monthly_lso2' | translate">
              </app-input>
            </div>
            <!-- DM -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_dm" [fullWidth]="true"
                         [label]="'CONFIG.misc.full-target-dm' | translate">
              </app-input>
            </div>
            <!-- BDM -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_bdm" [fullWidth]="true"
                         [label]="'CONFIG.misc.full-target-bdm' | translate">
              </app-input>
            </div>
            <!-- Frozen Cases -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_caso_congelado" [fullWidth]="true"
                         [label]="'CONFIG.misc.full-target-caso-congelado' | translate">
              </app-input>
            </div>
            <!-- Documentation Complete -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_documentacion_completa" [fullWidth]="true"
                         [label]="'CONFIG.misc.full-target-documentacion-completa' | translate">
              </app-input>
            </div>
            <!-- DM -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_dgs" [fullWidth]="true"
                         [label]="'CONFIG.misc.full-target-dgs' | translate">
              </app-input>
            </div>
            <!-- Full Target Cajaplus -->
            <div class="col-6">
              <app-input type="number" formControlName="full_target_cajaplus" [fullWidth]="true"
                         [label]="'CONFIG.misc.full_target_cajaplus' | translate">
              </app-input>
              <!-- Min Disposal Income -->
              <div class="col-6">
                <app-input type="number" formControlName="min_disposable_income" [fullWidth]="true"
                           [label]="'CONFIG.misc.min_disposable_income' | translate">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-8">
                <div class="d-flex">
                  <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.save' | translate"></app-spinner-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
