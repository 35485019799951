<div class="card shadow">
  <!--  <div class="card-header">-->
  <!--    <div class="d-flex">-->
  <!--      <h1>{{ "CASES.single.documents.heading" | translate }}</h1>-->
  <!--    </div>-->
  <!-- Actions -->
  <!--  </div>-->
  <div class="card-body">
    <app-case-document-upload-list *ngIf="case && caseCreditor" [case]="case" [caseCreditor]="caseCreditor">
    </app-case-document-upload-list>
  </div>
</div>
