import { PaymentMethodType } from './PaymentMethod';

export class PaymentListFilter {
  search?: string;
  start_date?: string | Date;
  end_date?: string | Date;
  products?: Array<number>;
  statuses?: Array<number>;
  call_statuses?: Array<number>;
  payment_statuses?: Array<number>;
  is_processing?: boolean | 0 | 1;
  payment_method?: PaymentMethodType;
  income_account_id?: number;
  pay_day?: number;
  card_status?: 'has_cards' | 'doesnt_have_cards' | 'has_multiple_cards' | 'has_expired_cards';
  activity_status?: string;
  paid_status?: string;
  billable_status?: 'only_billable' | 'only_non_billable' | 'all' | null;
  active?: 0 | 1 | null;
  select_all?: boolean | 0 | 1;
  only_count?: boolean | 0 | 1;
  only_related?: boolean | 0 | 1;
  user_department_assignments?: Array<number>;
  has_distribution?: boolean | 0 | 1;
  case_distribution_status?: 'in_distribution' | 'not_in_distribution_and_nonviable' | 'nonviable';
  distribution_batch_ids?: Array<number> | null;
  plan_type_ids?: Array<number> | null;
  plan_type_slugs?: Array<number> | null; // slug wrapper for plan_type_ids
  activity_status_filter_type?: string;
  'collections-agent'?: Array<number>;
  // Sort
  order?: 'asc' | 'desc';
  sort_by?: 'date_paid' | 'term_date';
  // sort_by?: 'id' | 'ref_number' | 'first_name' | 'last_name' | 'joint_application' | 'created_at' | 'status' |
  //     'payment_status' | 'affiliate' | 'product' | 'debt_amount' | 'pending_files_count';

  // Paginate
  per_page?: number;
  page?: number;

  // Extra
  cases?: Array<number>;

  terms?: Array<number>;
  expected_count?: number;
  has_batch_in_statuses?: Array<string>;
  doesnt_have_batch_in_statuses?: Array<string>;
}
