import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { TaskTemplate } from '../../../../../_base-shared/models/Task/TaskTemplate';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TaskTemplateService extends MainBaseApiService {

  index(params = {}): Observable<LaravelResourceResponse<Array<TaskTemplate>>> {
    return this.http.get<LaravelResourceResponse<Array<TaskTemplate>>>(this.apiUrl + '/task-templates', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  store(taskTemplate: TaskTemplate, params = {}): Observable<LaravelResourceResponse<TaskTemplate>> {
    return this.http.post<LaravelResourceResponse<TaskTemplate>>(this.apiUrl + '/task-templates', taskTemplate, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number, params = {}): Observable<LaravelResourceResponse<TaskTemplate>> {
    return this.http.get<LaravelResourceResponse<TaskTemplate>>(this.apiUrl + '/task-templates/' + id, {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, taskTemplate: TaskTemplate, params = {}): Observable<LaravelResourceResponse<TaskTemplate>> {
    return this.http.patch<LaravelResourceResponse<TaskTemplate>>(
        this.apiUrl + '/task-templates/' + id, taskTemplate, {params},
    )
        .pipe(catchError(response => this.handleError(response)));
  }

  destroy(id: number, params = {}): Observable<LaravelResourceResponse<any>> {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/task-templates/' + id, {params})
        .pipe(catchError(response => this.handleError(response)));
  }
}
