import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { Task } from '../../../../../../../../../_base-shared/models/Task/Task';
import { TaskDetailComponent } from '../../../../../task/task-detail/task-detail.component';
import { TaskEditorComponent } from '../../../../../task/task-editor/task-editor.component';
import { TaskService } from '../../../../../task/task.service';

@Component({
  selector:    'app-case-task-list',
  templateUrl: './case-task-list.component.html',
  styles:      ['.case-tasks-list { }'],
})
export class CaseTaskListComponent implements OnInit {
  @Input() case: Case;
  public caseTasks: Array<Task> = [];
  public isLoading              = 0;

  constructor(private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private taskService: TaskService) {
  }

  ngOnInit(): void {
    this.loadCaseTasks(this.case);
  }

  public addTask(taskCase: Case) {
    const dialogRef = this.dialog.open(TaskEditorComponent, {
      width:     '40%',
      autoFocus: false,
      position:  {
        top: '10px',
      },
      data:      {
        case:       taskCase,
        editorType: 'create',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'reFetch') {
        this.taskService.indexCaseTasks(this.case.id)
            .subscribe(result => this.caseTasks = result.data);
      }
    });
  }

  public editTask(taskCase: Case, task: Task) {
    const dialogRef = this.dialog.open(TaskEditorComponent, {
      width:     '40%',
      autoFocus: false,
      position:  {
        top: '10px',
      },
      data:      {
        case:       taskCase,
        task,
        editorType: 'edit',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'reFetch') {
        this.taskService.indexCaseTasks(taskCase.id).subscribe(
            r => this.caseTasks = r.data);
      }
    });
  }

  public openTask(task: Task) {
    const dialogRef = this.dialog.open(TaskDetailComponent, {
      width:     '40%',
      autoFocus: false,
      // minHeight: '500px',
      data: {
        task,
      },
    });
  }

  private loadCaseTasks(clientCase: Case) {
    this.isLoading++;
    this.taskService.indexCaseTasks(clientCase.id).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.caseTasks = result.data,
    );
  }

  public confirmCompleteTask(task: Task) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('TASK.detail.actions.confirm-complete.text'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonText:  this.translate.instant('TASK.detail.actions.confirm-complete.confirm-button'),
      confirmButtonColor: '#886ab5',
    }).then(result => {
      if (result.isConfirmed) {
        this.completeTask(task);
      }
    });
  }

  public completeTask(task: Task) {
    const requestData = {is_completed: 1};
    this.taskService.completeCaseTask(task.case_id, task.id, requestData).subscribe(
      data => {
        this.toastr.success(this.translate.instant('TASK.detail.complete.response-success'));
      },
      error => this.toastr.error('Could not complete task'),
    );
  }
}
