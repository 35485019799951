import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CallStatus } from '../../../../../../_base-shared/models/Status/CallStatus';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { CallStatusService } from '../call-status.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import Swal from 'sweetalert2';

@Component({
  selector:    'app-call-status-list',
  templateUrl: './call-status-list.component.html',
  styleUrls:   ['./call-status-list.component.scss']
})
export class CallStatusListComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public callStatuses: CallStatus[];
  public displayedColumns: string[] = ['id', 'name', 'created_at', 'actions'];
  public actions: string[]          = ['Edit', 'Delete'];
  public isLoading                  = 0;
  public dataSource: MatTableDataSource<CallStatus>;
  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1
  };

  public search                     = new UntypedFormControl('');

  constructor(
    private toastr: ToastrService,
    public translateService: TranslateService,
    private callStatusService: CallStatusService,
  ) {
  }

  ngOnInit(): void {
    this.fetchCallStatuses();
    this.search.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.fetchCallStatuses());
  }

  private fetchCallStatuses() {
    this.dataSource = new MatTableDataSource<CallStatus>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
      'append[]':   ['name']
    };

    this.callStatusService.index(data).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.callStatuses           = res.data;
        this.dataSource             = new MatTableDataSource<CallStatus>(res.data);
        this.dataSource.sort        = this.sort;
        this.paginatorConfig.length = res.meta.total;
      });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
  }

  openDeleteDialog(statusId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('SHARED.delete-item'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('SHARED.delete'),
      confirmButtonColor: '#886AB5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteCallStatus(statusId);
      }
    });
  }

  deleteCallStatus(statusId: number) {
    this.callStatusService.delete(statusId)
      .subscribe(res => {
          this.fetchCallStatuses();
          this.toastr.success(this.translateService.instant('CONFIG.call-status.call-status-deleted'));
        },
        error => {
          this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
        });
  }
}
