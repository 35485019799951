import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DialerList } from '../../../../../../_base-shared/models/Dialer/DialerList';
import { DialerTrigger } from '../../../../../../_base-shared/models/Dialer/DialerTrigger';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../_base-shared/models/Product';
import { CaseCreditorProduct } from '../../../../../../_base-shared/models/Product/CaseCreditorProduct';
import { CallStatus } from '../../../../../../_base-shared/models/Status/CallStatus';
import { CaseCreditorPaymentStatus } from '../../../../../../_base-shared/models/Status/CaseCreditorPaymentStatus';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { AdminPackagerService } from '../../admin-packager/admin-packager.service';
import { CallStatusService } from '../../call-status/call-status.service';
import { CaseCreditorService } from '../../case/case-creditor.service';
import { ProductService } from '../../case/product.service';
import { PaymentStatusService } from '../../payment-status/payment-status.service';
import { StatusService } from '../../status/status.service';
import { DialerService } from '../dialer.service';

@Component({
  selector:    'app-dialer-triggers',
  templateUrl: './dialer-triggers.component.html',
  styles:      ['.table th, .table td{padding: 0}'],
})
export class DialerTriggersComponent implements OnInit {
  @Input() statusCategories: Array<Status>                               = [];
  @Input() paymentStatuses: Array<PaymentStatus>                         = [];
  @Input() callStatuses: Array<CallStatus>                               = [];
  @Input() caseCreditorPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];

  public isLoading                                        = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public delayOptions: Array<AppSelectOption>;
  public dialerTriggers: Array<DialerTrigger>;
  public dialerLists: Array<DialerList>;
  public products: Array<Product>                         = [];
  public packagers: Array<Packager>                       = [];
  public caseCreditorProducts: Array<CaseCreditorProduct> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialerService: DialerService,
    private statusService: StatusService,
    private paymentStatusService: PaymentStatusService,
    private productService: ProductService,
    private callStatusService: CallStatusService,
    private packagerService: AdminPackagerService,
    private caseCreditorService: CaseCreditorService
  ) {
  }

  ngOnInit(): void {
    this.buildDelayOptions();
    this.isLoading++;
    this.dialerService.indexTriggers(['products', 'case_creditor_products']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.dialerTriggers = result.data;
      this.buildForm(this.dialerTriggers);
    });
    this.fetchDialerLists();
    this.fetchProducts();
    this.fetchPackagers();
    this.fetchStatuses();
    this.fetchPaymentStatuses();
    this.fetchCallStatuses();
    this.fetchCaseCreditorPaymentStatuses();
    this.fetchCaseCreditorProducts();
  }

  private buildForm(dialerTriggers: Array<DialerTrigger>) {
    this.form       = this.fb.group({
      dialer_triggers: this.fb.array([]),
    });
    const formArray = this.getFormArray();

    dialerTriggers.forEach(dialerTrigger => {
      formArray.push(this.dialerTriggerFormGroup(dialerTrigger));
    });
    console.log(this.form.value);
  }

  public dialerTriggerFormGroup(dialerTrigger: DialerTrigger): UntypedFormGroup {
    const productIds         = [];
    const creditorProductIds = [];
    dialerTrigger.products?.forEach(product => {
      productIds.push(product.id);
    });
    dialerTrigger.case_creditor_products?.forEach(cProduct => {
      creditorProductIds.push(cProduct.id);
    });

    return this.fb.group({
      id:                        [dialerTrigger.id],
      packager_id:               [dialerTrigger.packager_id, [Validators.required]],
      statusable_type:           [dialerTrigger.statusable_type, [Validators.required]],
      statusable_id:             [dialerTrigger.statusable_id, [Validators.required]],
      product_ids:               [productIds, [Validators.required]],
      case_creditor_product_ids: [creditorProductIds],
      dialer_list_id:            [dialerTrigger.dialer_list_id, [Validators.required]],
      priority:                  [dialerTrigger.priority, [Validators.required, Validators.min(1), Validators.max(99)]],
      force_dial:                [dialerTrigger.force_dial],
      delay:                     [dialerTrigger.delay, [Validators.required, Validators.min(0)]],
      delay_unit:                [dialerTrigger.delay_unit, [Validators.required]],
      auto_queue:                [dialerTrigger.auto_queue, [Validators.required]],
      active:                    [dialerTrigger.active, [Validators.required]],
    });
  }

  public getFormArray() {
    return this.form.get('dialer_triggers') as UntypedFormArray;
  }

  public removeStatus(index) {
    this.getFormArray().removeAt(index);
  }

  public addNewStatusTrigger(statusableType: 'status' | 'payment_status' | 'call_status' | 'case_creditor_payment_status') {
    const dialerTrigger           = new DialerTrigger();
    dialerTrigger.statusable_type = statusableType;
    dialerTrigger.product_ids     = null;
    dialerTrigger.delay           = 0;
    dialerTrigger.delay_unit      = 'minute';
    dialerTrigger.auto_queue      = true;
    dialerTrigger.active          = true;
    dialerTrigger.force_dial      = true;

    const formArray = this.getFormArray();
    formArray.push(this.dialerTriggerFormGroup(dialerTrigger));
  }

  public submitForm(form: UntypedFormGroup) {
    this.getFormValidationErrors();
    if (form.invalid) {
      this.getFormValidationErrors();
      form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.dialerService.upsertTriggers(form.getRawValue())
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        res => {
          this.toastr.success('Dialer config saved');
        },
        err => {
          this.serverResponse = err.error;
          this.toastr.error('Dialer config did not save');
        },
      );
  }

  private buildDelayOptions() {
    this.delayOptions = [
      {
        value: 'minute',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.minute'),
      },
      {
        value: 'hour',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.hour'),
      },
      {
        value: 'day',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ]
    ;
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.products = result.data;
      });
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.statusCategories = result.data);
  }

  private fetchPaymentStatuses() {
    this.isLoading++;
    this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.paymentStatuses = result.data);
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.callStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.callStatuses = result.data);
  }

  private fetchCaseCreditorPaymentStatuses() {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorPaymentStatuses({select_all: 1}, [], ['name'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.caseCreditorPaymentStatuses = result.data);
  }

  private fetchCaseCreditorProducts() {
    this.isLoading++;
    this.caseCreditorService.indexProducts({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.caseCreditorProducts = result.data);
  }

  private fetchDialerLists(): void {
    this.isLoading++;
    this.dialerService.indexLists({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.dialerLists = result.data);
  }

  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.packagers = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }
}
