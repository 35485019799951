import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends MainBaseApiService {

  indexQueuedExports(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  headlineReports() {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/headline')
        .pipe(catchError(response => this.handleError(response)));
  }

  getMissingDocs(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/missing-docs-report', {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  generateBreakageReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/breakage', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  generatePaymentReceivedReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/payment_received_report',
        {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }

  getMissingDocsSimpleExport(): Observable<HttpResponse<Blob>> {
    // @ts-ignore
    return this.http.get(this.apiUrl + '/reports/missing-docs-report-export', {observe: 'response', responseType: 'blob'})
      .pipe(catchError(response => this.handleError(response)));
  }

  getMissingDocsExport(): Observable<HttpResponse<Blob>> {
    return this.http.get(this.apiUrl + '/reports/missing-docs-report-export-advanced', {observe: 'response', responseType: 'blob'})
      .pipe(catchError(response => this.handleError(response)));
  }

  generateSalesReport(requestData) {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/reports/sales_report', {params: requestData})
        .pipe(catchError(response => this.handleError(response)));
  }
}
