import { Component, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorStatusLog } from '../../../../../../../../../_base-shared/models/Case/CaseCreditorStatusLog';

@Component({
  selector: 'app-case-creditor-status-log',
  templateUrl: './case-creditor-status-log.component.html',
  styles: []
})
export class CaseCreditorStatusLogComponent implements OnInit{
  @Input() case: Case;
  @Input() caseCreditor: CaseCreditor;
  public isLoading                           = 0;
  public caseCreditorStatusLogs: Array<CaseCreditorStatusLog>;

  constructor() {}

  ngOnInit(): void {
    this.caseCreditorStatusLogs = this.caseCreditor ? this.caseCreditor.case_creditor_status_changes : [];
  }
}
