import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { OneTimeFeeEditorComponent } from './one-time-fee-editor/one-time-fee-editor.component';
import { OneTimeFeeListComponent } from './one-time-fee-list/one-time-fee-list.component';

const routes: Routes = [
  {
    path:     'one-time-fee',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: OneTimeFeeListComponent},
      {path: 'create', canActivate: [MainPermissionGuard], component: OneTimeFeeEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [MainPermissionGuard], component: OneTimeFeeEditorComponent, data: {editor: 'edit'}},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OneTimeFeeRoutingModule { }
