<div class="pt-5">
  <div class="trustpilot-widget"
       id="trustbox"
       data-locale="es-ES"
       data-template-id="53aa8807dec7e10d38f59f32"
       data-businessunit-id="5ebe9fd725d4cc000186fe6e"
       data-style-height="150px"
       data-theme="light">
    <a href="https://es.trustpilot.com/review/reclamamidinero.es" target="_blank" rel="noopener">Trustpilot</a>
  </div>
</div>
