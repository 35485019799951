<div class="card shadow mb-4 card-height">
  <div class="card-body">
    <div class="row">
      <!-- Tab Navigation -->
      <div class="col-2">
        <mat-list>
          <mat-list-item *ngFor="let tab of tabs">
            <button class="btn-block mb-2" color="primary" mat-raised-button *ngIf="selectedTab.value === tab.value">
              {{ tab.label }}
            </button>
            <button class="btn-block mb-2" color="primary" mat-stroked-button *ngIf="selectedTab.value !== tab.value"
                    (click)="selectTab(tab.value)">
              {{ tab.label }}
            </button>
          </mat-list-item>
        </mat-list>
      </div>
      <!-- Tab Contents -->
      <div class="col-10" [ngSwitch]="selectedTab.value" style="max-height: 600px; overflow: auto">
        <app-case-notes *ngSwitchCase="'case-creditor-notes'" noteType="case_creditor" [case]="case" [caseCreditor]="caseCreditor"></app-case-notes>
        <app-case-notes *ngSwitchCase="'notes'" noteType="note" [case]="case"></app-case-notes>
        <app-case-notes *ngSwitchCase="'payment_notes'" noteType="payment" [case]="case"></app-case-notes>
        <app-case-notes *ngSwitchCase="'draft_notes'" noteType="draft" [case]="case"></app-case-notes>
        <app-case-creditor-note-list *ngSwitchCase="'creditor_notes'" [case]="case"
                                     (caseCreditorsLoaded)="updateCaseCreditors($event)">
        </app-case-creditor-note-list>
        <app-case-creditor-status-log *ngSwitchCase="'case_creditor_status_log'" [case]="case" [caseCreditor]="caseCreditor"></app-case-creditor-status-log>
      </div>
    </div>
  </div>
</div>
