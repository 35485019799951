<form [formGroup]="form" #ngForm>
  <div class="row">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date"
               (dateChange)="dateChanged($event, 'end_date', false)">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <!-- Date Range Type -->
  <div class="row">
    <mat-label style="padding-right: 10px; display: block">
      {{ 'CASES.list.filters.status_date_type.label' | translate }}
    </mat-label>
    <mat-radio-group formControlName="status_date_type" (ngModelChange)="datePickTypeUpdated($event)">
      <mat-radio-button value="sign_up">
        {{ "CASES.list.filters.status_date_type.options.sign_up" | translate }}
      </mat-radio-button>
      <mat-radio-button value="conversion_date" class="ml-2">
        {{ "CASES.list.filters.status_date_type.options.conversion_date" | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="row">
    <!-- Conversion Status -->
    <div class="col-4">
      <app-input type="select" formControlName="conversion_status" appearance="standard" fullWidth="true"
                 [label]="'AFFILIATES.conversion-status' | translate"
                 [selectOptions]="conversionStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">
      </app-input>
    </div>
    <!-- Affiliates -->
    <div class="col-4">
      <app-input type="select" formControlName="affiliate_ids" appearance="standard" fullWidth="true"
                 [label]="'CASES.affiliates' | translate" [searchable]="true" multiple
                 [selectOptions]="affiliates" [selectLabel]="'name'" [selectValue]="'id'">
      </app-input>
    </div>
    <!-- Affiliate Users -->
    <div class="col-4">
      <app-input type="select" formControlName="affiliate_user_ids" appearance="standard" fullWidth="true"
                 [label]="'AFFILIATES.affiliate_users' | translate" [searchable]="true" multiple
                 [selectOptions]="affiliateUsers" [selectLabel]="'name'" [selectValue]="'id'">
      </app-input>
    </div>
    <!-- Product Groups -->
    <div class="col-4">
      <app-input type="select" appearance="standard" [label]="'CONFIG.dialer.product_type' | translate"
                 formControlName="product_group_slugs" (ngModelChange)="productGroupUpdated($event)"
                 [showClear]="false" [showLabel]="true" [fullWidth]="true"
                 [selectOptions]="productTypeGroups" [multiple]="true"
                 [selectLabel]="'label'" [selectValue]="'value'">
      </app-input>
    </div>
  </div>
</form>
