import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as cardValidation from 'creditcards';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import {
  PaymentErrorModalComponent,
} from '../../../_shared/components/payment-error-modal/payment-error-modal.component';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PaymentCard } from '../../../../../../_base-shared/models/Payment/PaymentCard';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CustomValidators, expiryDateInPast } from '../../../../../../_base-shared/validators/custom.validators';
import { ClientService } from '../../client.service';

@Component({
  selector:    'app-auth-payment',
  templateUrl: './auth-payment.component.html',
  styleUrls:   ['./auth-payment.component.scss'],
  animations:  [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.5s', style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.5s', style({opacity: 0})),
      ]),
    ])
  ],
})
export class AuthPaymentComponent implements OnInit {
  public authUser: User;
  public case: Case;
  public isLoading                                = 0;
  public paymentCards: Array<PaymentCard>;
  public paymentCardsFiltered: Array<PaymentCard>;
  public form: UntypedFormGroup;
  public submitType: 'existing_card' | 'new_card' = 'new_card';
  public executingPayment: boolean;
  public isSuccessful                             = false;
  public isError                                  = false;
  public paymentResponse: string;
  public isExistingCard                           = false;
  public desktopVersion: boolean;
  public amountToCharge: number;
  public dueDate: number;
  public isSubmiting = false;

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private translate: TranslateService,
              private globalEventsService: MainGlobalEventService,
              private clientService: ClientService,
              private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser  = user;
      const caseUuid = this.route.snapshot.paramMap.get('caseUuid');
      this.fetchCase(this.authUser, caseUuid);
    });
  }

  public dismissPopUp() {
    this.isError      = false;
    this.isSuccessful = false;
  }

  private fetchCase(client: User, caseUuid: string) {
    const loadRelations = [
      'relation_validations',
      'client.address',
      'partner.address',
      'debt_payment_plan.payment_method'];
    this.isLoading++;
    this.clientService.showUserCase(client.uuid, caseUuid, loadRelations)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.case = result.data;
        this.globalEventsService.setClientSelectedCase(this.case);
        this.fetchPaymentCards(this.authUser, this.case);
      });
  }

  private fetchPaymentCards(authUser: User, clientCase: Case) {
    this.isLoading++;
    this.clientService.indexPaymentCards(authUser.uuid, clientCase.uuid).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentCards = result.data.map(card => {
          const dateOfExpiry       = moment(`${ card.card_exp_month }${ card.card_exp_year }`, 'MMYYYY')
            .startOf('month')
            .format();
          card.expires_at          = moment(dateOfExpiry).endOf('month').format();
          const expirationDateDiff = moment(card.expires_at).diff(moment(), 'months', true);

          if (expirationDateDiff <= 4) {
            card.expire = 'soon';
          }

          if (expirationDateDiff < 0) {
            card.expire = 'expired';
          }

          card.card_brand = card.card_brand ? card.card_brand : cardValidation.card.type(card.card_bin, true);

          return card;
        });
        this.clientService.paymentAmount(this.authUser.uuid, this.case.uuid).subscribe(res => {
          let chargeAmount = 0;
          if (res.data.hasOwnProperty('amount') && res.data.hasOwnProperty('amount_paid')) {
            chargeAmount = res.data.amount - res.data.amount_paid;
          }
          this.amountToCharge = chargeAmount;
          this.dueDate        = res.data.term_date;
          this.buildNewCardForm(this.authUser, chargeAmount);
        });
      });
  }

  private buildNewCardForm(client: User, chargeAmount: number) {
    this.form = this.fb.group({
      payment_plan:  ['debt_plan', [Validators.required]], // TODO: deprecate
      payment_plans: [['debt_plan'], [Validators.required]],
      amount:        [chargeAmount, [Validators.required, Validators.min(0.1), Validators.max(5000)]],
      card_id:       [null],
      holder:        [client ? (client.first_name + ' ' + client.last_name) : null],
      card_number:   [null, [Validators.required, Validators.minLength(16), Validators.maxLength(19)]],
      expiry_month:  [
        null,
        [Validators.required, Validators.min(1), Validators.max(12), CustomValidators.expiryMonthValidator]],
      expiry_year:   [
        null,
        [
          Validators.required,
          Validators.min(new Date().getFullYear() % 100),
          Validators.max(60),
          Validators.minLength(2),
          Validators.maxLength(2),
          CustomValidators.expiryYearValidator],
      ],
      cvv:           [
        {value: null, disabled: false},
        [Validators.required, Validators.minLength(3), Validators.maxLength(3), CustomValidators.cvcValidator]],
      brand:         [null],
      save_card:     [true], // TODO: Checkmark for saving card
    }, {validators: expiryDateInPast});

    //  Card type auto detection (visa or master)
    this.form.get('card_number').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(value => {
      let cardType = cardValidation.card.type(value, true);
      if ( ! cardType) {
        this.formatCardNumber(value);
        return;
      }
      cardType = cardType.toUpperCase();  //  Format string to uppercase - needed for backend
      if (cardType === 'MASTERCARD') {
        cardType = 'Master'; //  Trim mastercard string - needed for backend
      }
      this.form.patchValue({brand: cardType});
      this.formatCardNumber(value);
    });
  }

  // TODO: on card_id form control model change, update other fields and change submision target
  private paymentCardSelected(cardId: number | null) {
    this.form.get('cvv').patchValue(null);
    if (cardId) {
      this.submitType = 'existing_card';
      const card      = this.paymentCards.find(paymentCard => paymentCard.id === cardId);
      if (card) {
        this.form.get('brand').patchValue(card.card_brand);
        this.form.get('card_id').patchValue(card.id);
        this.form.get('card_number').patchValue('XXXX-XXXX-XXXX-' + card.card_last_four);
        this.form.get('expiry_month').clearValidators();
        this.form.get('expiry_year').clearValidators();
        this.form.get('cvv').clearValidators();
      }
      this.paymentCardsFiltered = [];
    } else {
      // TODO: add brand and rest of properties on form
      this.submitType = 'new_card';
      this.form.get('card_number').patchValue(null);
      this.form.get('expiry_month')
        .setValidators(
          [Validators.required, Validators.min(1), Validators.max(12), CustomValidators.expiryMonthValidator]);
      this.form.get('expiry_year').setValidators([
        Validators.required,
        Validators.min(new Date().getFullYear() % 100),
        Validators.max(60),
        Validators.minLength(2),
        Validators.maxLength(2),
        CustomValidators.expiryYearValidator,
      ]);
      this.form.get('cvv').setValidators([
        Validators.required, Validators.minLength(3), Validators.maxLength(3), CustomValidators.cvcValidator,
      ]);
    }
    this.form.get('expiry_month').updateValueAndValidity();
    this.form.get('expiry_year').updateValueAndValidity();
    this.form.get('cvv').updateValueAndValidity();
  }

  public submitNewCardPayment(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.isSubmiting = true;
    const requestData        = {...form.value};
    requestData.card_number  = cardValidation.card.parse(requestData.card_number);
    requestData.expiry_year  = cardValidation.expiration.year.parse(requestData.expiry_year, true);
    requestData.expiry_month = requestData.expiry_month.length === 1 ?
      `0${ requestData.expiry_month }` :
      requestData.expiry_month;
    requestData.brand        = cardValidation.card.type(requestData.card_number, true).toUpperCase();

    this.executingPayment = true;
    this.clientService.chargeNewCard(this.authUser.uuid, this.case.uuid, requestData)
      .pipe(finalize(() => this.executingPayment = false))
      .subscribe(
        result => {
          this.isError         = false;
          this.isSuccessful    = true;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-success');
        },
        err => {
          if (err.error?.errors?.card_charge === '100.100.700') {
            this.dialog.open(PaymentErrorModalComponent);
          }
          this.isError         = true;
          this.isSuccessful    = false;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-failed');
          if (err.error?.errors?.brand) {
            this.paymentResponse = this.paymentResponse + '. ' + 'This card brand is invalid';
          }
          this.isSubmiting = false;
        },
      );
  }

  public submitExistingCardPayment(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.isSubmiting = true;
    this.executingPayment = true;
    this.clientService.chargeExistingCard(this.authUser.uuid, this.case.uuid, form.value)
      .pipe(finalize(() => this.executingPayment = false))
      .subscribe(
        result => {
          this.isError         = false;
          this.isSuccessful    = true;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-success');
        },
        err => {
          this.isError         = true;
          this.isSuccessful    = false;
          this.paymentResponse = this.translate.instant('CARD-INFO.payment-failed');
          this.isSubmiting = false;
        },
      );
  }

  public findCard($event) {
    if ( ! $event) {
      this.isExistingCard = false;
      this.submitType     = 'new_card';
    }

    if ( ! this.isExistingCard) {
      this.paymentCardsFiltered = this.paymentCards.filter(it => {
        return it.card_bin.includes($event);
      });
    }
  }

  public chooseExistingCard(card: any) {
    this.paymentCardSelected(card.id);
    this.isExistingCard = true;
  }

  private formatCardNumber(cardNumber: string) {
    if (this.isExistingCard) {
      return;
    }

    const parseValue  = cardValidation.card.parse(cardNumber);
    const formatValue = cardValidation.card.format(parseValue);
    this.form.get('card_number').setValue(formatValue);
  }
}
