<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoading" class="row" [formGroup]="form">
  <div class="col-12">
    <h1>{{"CONFIG.system-event.header" | translate}}</h1>
  </div>
  <div class="col-12">
    <div class="card shadow">
      <div class="card-body pt-5">
        <div class="row pb-5">
          <div class="col-md-8 offset-md-2">
            <label>{{"CONFIG.system-event.default-status" | translate}}:</label>
            <app-input type="select" formControlName="default_status" appearance="outline" [label]="'CONFIG.system-event.default-status'| translate "
                       [fullWidth]="true" [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
            </app-input>
          </div>
        </div>
        <div class="row pb-5">
          <div class="col-md-8 offset-md-2">
            <label>{{"CONFIG.system-event.new-creditor" | translate}}:</label>
            <app-input type="select" formControlName="new_creditor" appearance="outline" [label]="'CONFIG.system-event.new-creditor'| translate "
                       [fullWidth]="true" [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
            </app-input>
          </div>
        </div>
        <div class="row pb-5">
          <div class="col-md-8 offset-md-2">
            <label>{{"CONFIG.system-event.sign-contract" | translate}}: </label>
            <app-input type="select" formControlName="sign_contract" appearance="outline" [label]="'CONFIG.system-event.sign-contract'| translate "
                       [fullWidth]="true" [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
            </app-input>
          </div>
        </div>

        <div class="row pb-5">
          <div class="col-md-8 offset-md-2">
            <label>{{"CONFIG.system-event.sent-contract" | translate}}: </label>
            <app-input type="select" formControlName="send_contract" appearance="outline" [label]="'CONFIG.system-event.sign-contract'| translate "
                       [fullWidth]="true" [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
            </app-input>
          </div>
        </div>

        <div class="row pb-5">
          <div class="col-md-8 offset-md-2">
            <button mat-raised-button color="primary" style="min-width: 120px" *ngIf="!isSubmitting" (click)="submit()">
              {{ "SHARED.save" | translate }}
            </button>
            <mat-spinner *ngIf="isSubmitting"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
