import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Affiliate } from '../../../../../../../../_base-shared/models/Affiliate';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { AffiliateService } from '../../../../affiliate/affiliate.service';
import { UserService } from '../../../../user/user.service';
import { CaseService } from '../../../case.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CaseUserPasswordModalComponent} from '../case-user-password-modal/case-user-password-modal.component';

@Component({
  selector:    'app-case-quick-client-editor',
  templateUrl: './case-quick-client-editor.component.html',
  styles:      [],
})
export class CaseQuickClientEditorComponent implements OnInit {
  @Input() case: Case;
  @Output() caseClientsUpdated: EventEmitter<Case> = new EventEmitter<Case>();
  public loadRelations                             = [
    'lead', 'client.address', 'partner.address', 'linked_by_cases.client.address', 'linked_cases.client.address',
  ];
  public isLoading                                 = 0;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public affiliates: Array<Affiliate>              = [];
  public isEditing                                 = false;
  public isSubmitting                              = false;

  private validateClientEmailTimeout: any;
  private validatePartnerEmailTimeout: any;

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private affiliateService: AffiliateService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.fetchAffiliates();
    this.loadCaseClients(this.case);
  }

  public toggleEditMode() {
    this.isEditing = !this.isEditing;
    this.toggleFormControls(this.isEditing);
  }

  public submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const requestData = this.form.value;
    if (!requestData.client.address?.address_1) {
      requestData.client.address.address_1 = null;
    }
    if (this.case.joint_application && !requestData.partner?.address?.address_1) {
      requestData.partner.address.address_1 = null;
    }

    this.isSubmitting = true;
    this.caseService.update(this.case.id, requestData, this.loadRelations)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        result => {
          this.toastr.success(
            this.translate.instant('CASES.editor.general.result.update.success'),
            this.translate.instant('SHARED.success'),
          );
          const updatedCase = result.data;
          this.case.lead    = updatedCase.lead;
          this.case.client  = updatedCase.client;
          this.case.partner = updatedCase.partner;
          this.toggleEditMode();
        },
        err => this.serverResponse = err.error,
      );
  }

  private toggleFormControls(isEnabled: boolean) {
    if (isEnabled) {
      //  Client fields
      this.form.get('case.lead.affiliate_id').enable();
      this.form.get('client.first_name').enable();
      this.form.get('client.last_name').enable();
      this.form.get('client.email').enable();
      this.form.get('client.phone').enable();
      this.form.get('client.id_card').enable();
      this.form.get('client.address.address_1').enable();
      this.form.get('client.address.address_2').enable();

      if (this.case.joint_application) {
        this.form.get('partner.first_name').enable();
        this.form.get('partner.last_name').enable();
        this.form.get('partner.email').enable();
        this.form.get('partner.phone').enable();
        this.form.get('partner.id_card').enable();
        this.form.get('partner.debt_aware').enable();
        this.form.get('partner.address.address_1').enable();
        this.form.get('partner.address.address_2').enable();
      }
    } else {
      this.form.get('case.lead.affiliate_id').disable();
      this.form.get('client.first_name').disable();
      this.form.get('client.last_name').disable();
      this.form.get('client.email').disable();
      this.form.get('client.phone').disable();
      this.form.get('client.id_card').disable();
      this.form.get('client.address.address_1').disable();
      this.form.get('client.address.address_2').disable();

      if (this.case.joint_application) {
        this.form.get('partner.first_name').disable();
        this.form.get('partner.last_name').disable();
        this.form.get('partner.email').disable();
        this.form.get('partner.phone').disable();
        this.form.get('partner.id_card').disable();
        this.form.get('partner.debt_aware').disable();
        this.form.get('partner.address.address_1').disable();
        this.form.get('partner.address.address_2').disable();
      }
    }
  }

  private buildForm(clientCase: Case) {
    this.form = this.fb.group({
      case:    this.fb.group({
        ref_number:        [{value: clientCase.ref_number, disabled: true}],
        joint_application: [clientCase.joint_application, Validators.required],
        lead:              this.fb.group({
          affiliate_id: [{value: clientCase?.lead?.affiliate_id, disabled: true}],
        }),
      }),
      client:  this.fb.group({
        first_name:         [{value: clientCase.client.first_name, disabled: true}, Validators.required],
        last_name:          [{value: clientCase.client.last_name, disabled: true}, Validators.required],
        id_card:            [{value: clientCase.client.id_card, disabled: true}],
        email:              [
          {
            value:    clientCase.client.email,
            disabled: true,
          },
          [Validators.required, Validators.email],
          this.validateClientEmail.bind(this),
        ],
        phone_country_code: ['34', [Validators.required]],
        phone:              [
          {value: clientCase.client.phone, disabled: true},
          [
            Validators.required,
            Validators.maxLength(9),
            Validators.minLength(9),
            Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        address:            this.fb.group({
          address_1: [{value: clientCase.client.address?.address_1, disabled: true}],
          address_2: [{value: clientCase.client.address?.address_2, disabled: true}],
        }),
      }),
      partner: this.fb.group({
        first_name:            [
          {value: clientCase.partner?.first_name, disabled: true},
          clientCase.joint_application ? [Validators.required] : [],
        ],
        last_name:             [
          {value: clientCase.partner?.last_name, disabled: true},
          clientCase.joint_application ? [Validators.required] : [],
        ],
        id_card:               [{value: clientCase.partner?.id_card, disabled: true}],
        email:                 [
          {
            value:    clientCase.partner?.email,
            disabled: true,
          },
          clientCase.joint_application ? [Validators.required, Validators.email] : [],
          clientCase.joint_application ? [this.validatePartnerEmail.bind(this)] : [],
        ],
        phone_country_code:    ['34', [Validators.required]],
        phone:                 [
          {value: clientCase.partner?.phone, disabled: true},
          [
            Validators.maxLength(9),
            Validators.minLength(9),
            Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        debt_aware:            [
          {value: clientCase.partner?.debt_aware, disabled: true},
          clientCase.joint_application ? [Validators.required] : [],
        ],
        relation_to_applicant: [
          clientCase.partner?.relation_to_applicant,
          clientCase.joint_application ? [Validators.required] : [],
        ],
        address:               this.fb.group({
          address_1: [{value: clientCase.partner?.address?.address_1 || null, disabled: true}],
        }),
      }),
    });
  }

  private loadCaseClients(clientCase: Case) {
    this.isLoading++;
    this.caseService.get(clientCase.id, this.loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.case.lead            = result.data.lead;
        this.case.client          = result.data.client;
        this.case.partner         = result.data.partner;
        this.case.linked_by_cases = result.data.linked_by_cases;
        this.case.linked_cases    = result.data.linked_cases;
        this.buildForm(this.case);
      },
    );
  }

  private fetchAffiliates() {
    this.isLoading++;
    this.affiliateService.index({all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.affiliates = result.data);
  }

  private validateClientEmail(control: UntypedFormControl): Promise<any> | Observable<any> {
    if (this.validateClientEmailTimeout) {
      clearTimeout(this.validateClientEmailTimeout);
    }
    return new Promise<any>((resolve, reject) => {
      this.validateClientEmailTimeout = setTimeout(() => {
        this.userService.validateField('email', control.value, this.case?.user_id).subscribe(
          result => resolve(null),
          err => resolve({unique: err.error.data?.existing_model}),
        );
      }, 500);
    });
  }

  private validatePartnerEmail(control: UntypedFormControl): Promise<any> | Observable<any> {
    if (this.validatePartnerEmailTimeout) {
      clearTimeout(this.validatePartnerEmailTimeout);
    }
    return new Promise<any>((resolve, reject) => {
      this.validatePartnerEmailTimeout = setTimeout(() => {
        if (!this.case.joint_application) {
          resolve(null);
        }
        this.userService.validateField('email', control.value, this.case?.partner_user_id).subscribe(
          result => resolve(null),
          err => resolve({unique: err.error.data?.existing_model}),
        );
      }, 500);
    });
  }

  public clickToCall(typeOfPhone: string, user: 'client' | 'partner') {
    const phone_number = this.form.get(user).get(typeOfPhone).value;
    this.caseService.call({phone_number}).subscribe((response) => {
    });
  }

  public openUserPasswordModal(userId: number) {
    const dialogRef  = this.dialog.open(CaseUserPasswordModalComponent, {
      width: '20%',
      data:  {
        userId,
        caseId: this.case.id
      },
    });
    //  On close fire handle response
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  }
}
