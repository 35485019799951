import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSelectOption } from '../../../../../../../../../_base-shared/contracts/common.interface';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';

@Component({
  selector: 'app-case-creditor-relation-list',
  templateUrl: './case-creditor-relation-list.component.html',
  styleUrls: ['./case-creditor-relation-list.component.scss']
})
export class CaseCreditorRelationListComponent implements OnInit{
  @Input() case: Case;
  @Input() caseCreditor: CaseCreditor;
  @Output() caseCreditorsUpdated: EventEmitter<Case> = new EventEmitter<Case>();
  public selectedTab                                 = new UntypedFormControl(null);
  public tabs: Array<AppSelectOption>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.case?.packager?.master ? this.getRelationTabs() : this.getRelationTabsPackagers();
    this.route.queryParams.subscribe(result => {
      const queryParams   = new URLSearchParams(result);
      const activeTabName = queryParams.get('activeTab');
      const activeTab     = this.tabs.find(fTab => fTab.value === activeTabName);
      if (activeTab) {
        this.selectedTab.patchValue(activeTab.value);
      }
    });

    this.translate.onLangChange.subscribe(next => {
      this.case?.packager?.master ? this.getRelationTabs() : this.getRelationTabsPackagers();
    });
  }

  public selectTab(tabName: string): void {
    if (this.selectedTab.value === tabName) {
      return;
    }

    const queryParams: Params = {activeTab: tabName};

    this.router.navigate(
      [],
      {
        relativeTo:          this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private getRelationTabs(): void {
    this.tabs = [
      {value:  'case-creditor-notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_case_creditor'),
      },
      {value: 'notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {
        value: 'payment_notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment'),
      },
      {value: 'draft_notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading_draft')},
      {
        value: 'creditor_notes',
        label: this.translate.instant('CASES.single.general.relation_list.creditor_notes.heading'),
      },
      {value: 'case_creditor_status_log', label: this.translate.instant('CASES.single.general.relation_list.case_creditor_status_logs.heading')},
    ];
  }

  private getRelationTabsPackagers(): void {
    this.tabs = [
      {value:  'case-creditor-notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_case_creditor'),
      },
      {value: 'notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {
        value: 'payment_notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment'),
      },
      {value: 'draft_notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading_draft')},
      {
        value: 'creditor_notes',
        label: this.translate.instant('CASES.single.general.relation_list.creditor_notes.heading'),
      },
      {value: 'case_creditor_status_log', label: this.translate.instant('CASES.single.general.relation_list.case_creditor_status_logs.heading')},
    ];
  }

  public updateCaseCreditors(updatedCase: Case): void {
    this.case.secured_creditors   = updatedCase.secured_creditors;
    this.case.unsecured_creditors = updatedCase.unsecured_creditors;
    this.case.public_debts        = updatedCase.public_debts;
    this.caseCreditorsUpdated.emit(this.case);
  }
}
