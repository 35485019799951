import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-request',
  templateUrl: './request.component.html',
  styleUrls:   ['./request.component.scss'],
})
export class RequestComponent implements OnInit {
  @Input() case: Case;
  public form      = new UntypedFormGroup({
    dni_nie:                                      new UntypedFormControl(false),
    dni_nie_message:                              new UntypedFormControl(''),
    regulatory_agreement_of_the_judgment:         new UntypedFormControl(false),
    regulatory_agreement_of_the_judgment_message: new UntypedFormControl(''),
    family_book:                                  new UntypedFormControl(false),
    family_book_message:                          new UntypedFormControl(''),
    child_birth_certificate:                      new UntypedFormControl(false),
    child_birth_certificate_message:              new UntypedFormControl(''),
    martial_capitulations:                        new UntypedFormControl(false),
    martial_capitulations_message:                new UntypedFormControl(''),
    pensioner_certificate:                        new UntypedFormControl(false),
    pensioner_certificate_message:                new UntypedFormControl(''),
    work_contract:                                new UntypedFormControl(false),
    work_contract_message:                        new UntypedFormControl(''),
    subsidy:                                      new UntypedFormControl(false),
    subsidy_message:                              new UntypedFormControl(''),
    deed_of_sale:                                 new UntypedFormControl(false),
    deed_of_sale_message:                         new UntypedFormControl(''),
    deed_of_mortgage_guarantee:                   new UntypedFormControl(false),
    deed_of_mortgage_guarantee_message:           new UntypedFormControl(''),
  });
  public isFocused = {
    dni_nie_message:                              false,
    regulatory_agreement_of_the_judgment_message: false,
    family_book_message:                          false,
    child_birth_certificate_message:              false,
    martial_capitulations_message:                false,
    pensioner_certificate_message:                false,
    work_contract_message:                        false,
    subsidy_message:                              false,
    deed_of_sale_message:                         false,
    deed_of_mortgage_guarantee_message:           false,
  };
  public loading   = false;

  constructor(private caseService: CaseService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  public onFocus($event) {
    const inputName           = $event.target.name;
    this.isFocused[inputName] = true;
  }

  public onBlur($event) {
    const inputName           = $event.target.name;
    this.isFocused[inputName] = false;
  }

  onSubmit() {
    this.loading = true;
    this.caseService.documentRequest(this.case.id, this.form.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
        });
  }

}
