<div class="card mb-3">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="card-title">
        <h2>{{ 'CASES.editor.creditors.heading' | translate }}</h2>
      </div>
      <!-- Page Actions -->
      <div *ngIf="form">
        <app-spinner-btn type="button" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                         (click)="submitForm(form)">
        </app-spinner-btn>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="isLoading || serverResponse">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>
<form *ngIf="form" [formGroup]="form">
  <div style="min-height: 1000px">
    <!-- Claims Creditors -->
    <div *ngIf="authUser?.packager.master" class="row mt-3">
      <div class="col-12">
        <div class="card shadow">
          <div class="card-header">
            <div class="card-title">
              <h3>{{ "CASE_CREDITOR.model.type.options.claim" | translate }}</h3>
            </div>
          </div>
          <div class="card-body">
            <table formArrayName="claim_creditors"
                   class="table">
              <thead>
              <tr>
                <th>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
                <th>{{ "CASE_CREDITOR.model.relations.creditor" | translate }}</th>
                <th>{{ "CASE_CREDITOR.model.case_creditor_product" | translate}}</th>
                <th>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
                <th>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</th>
                <th>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
                <th>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let formGroup of getFormArray('claim_creditors').controls let i = index">
                <tr [formGroupName]="i" style="border-collapse: separate" class="first-row border-top"
                    [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                    [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                    [class.unapproved]="!formGroup.get('approved')?.value">
                  <!-- Ownership -->
                  <td>
                    <app-input *ngIf="!case.joint_application" type="select" formControlName="ownership"
                               selectLabel="label" selectValue="value" width="160px"
                               [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                               [selectOptions]="singleOwnershipOptions" [showClear]="false">
                    </app-input>
                    <app-input *ngIf="case.joint_application" type="select" formControlName="ownership"
                               selectLabel="label" selectValue="value" width="160px"
                               [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                               [selectOptions]="jointOwnershipOptions" [showClear]="false">
                    </app-input>
                  </td>
                  <!-- Creditor -->
                  <td>
                    <app-input type="select" formControlName="creditor_id" width="160px"
                               selectLabel="name" selectValue="id"
                               [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                               [selectOptions]="creditors" [showClear]="false" [searchable]="true"
                               (ngModelChange)="checkCreditors($event, 'claim_creditors', i)">
                    </app-input>
                  </td>
                  <!-- Case Creditor Product -->
                  <td>
                    <app-input type="select" formControlName="case_creditor_product_id" width="160px"
                               selectLabel="name_es" selectValue="id" [selectOptions]="caseCreditorProducts"
                               [label]="'CASE_CREDITOR.model.case_creditor_product' | translate" [showClear]="true">
                    </app-input>
                  </td>
                  <!-- Debt Type -->
                  <td>
                    <app-input type="select" formControlName="debt_type" width="160px"
                               (ngModelChange)="suggestProduct($event, i, 'debt_type')"
                               selectLabel="label" selectValue="value" [selectOptions]="debtTypes"
                               [label]="'CASE_CREDITOR.model.debt_type.label' | translate" [showClear]="true">
                    </app-input>
                  </td>
                  <!-- Initial Balance -->
                  <td>
                    <app-input type="number" formControlName="initial_balance" width="160px"
                               [label]="'CASE_CREDITOR.model.initial_balance' | translate">
                    </app-input>
                  </td>
                  <!-- Current Balance -->
                  <td>
                    <app-input type="number" formControlName="current_balance" width="160px"
                               [label]="'CASE_CREDITOR.model.current_balance' | translate">
                    </app-input>
                  </td>
                  <!-- Monthly Payment -->
                  <td>
                    <app-input type="number" formControlName="monthly_payments" width="160px"
                               [label]="'CASE_CREDITOR.model.monthly_payments' | translate">
                    </app-input>
                  </td>
                  <!-- Remove -->
                  <td class="d-flex flex-row align-items-center">
                    <!-- Remove -->
                    <button mat-icon-button color="warn" class="ml-2"
                            (click)="removeCreditor('claim_creditors',i)">
                      <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr class="second-row" [formGroupName]="i" style="border-collapse: separate"
                    [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                    [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                    [class.unapproved]="!formGroup.get('approved')?.value">
                  <!--Contract Number-->
                  <td>
                    <app-input type="text" formControlName="contract_number"
                               [label]="'CASE_CREDITOR.model.contract_number' | translate">
                    </app-input>
                  </td>
                  <!--Year of hire-->
                  <td>
                    <app-input type="text" formControlName="year_of_hire"
                               [label]="'CASE_CREDITOR.model.year_of_hire' | translate">
                    </app-input>
                  </td>
                  <td>
                    <!-- Total Repaid -->
                    <app-input type="number" formControlName="total_repaid" width="160px"
                               [label]="'CASE_CREDITOR.model.total_repaid' | translate">
                    </app-input>
                    <span *ngIf="formGroup.get('total_repaid')?.value > formGroup.get('initial_balance')?.value"
                          class="badge badge-success mr-1 mt-1">
                      {{ 'CASES.editor.creditors.table.potential_azcarate' | translate }}
                    </span>
                  </td>
                  <!--Interest-->
                  <td>
                    <app-input type="number" formControlName="interest" width="160px"
                               [label]="'CASE_CREDITOR.model.interest' | translate">
                    </app-input>
                  </td>
                  <!--Insurance-->
                  <td>
                    <app-input type="select" formControlName="insurance" width="160px"
                               [label]="'CASE_CREDITOR.model.insurance' | translate"
                               (ngModelChange)="suggestProduct($event, i, 'insurance')"
                               [selectOptions]="insurance" [selectLabel]="'label'" [selectValue]="'value'"
                               [showClear]="false" [searchable]="true">
                    </app-input>
                  </td>
                  <ng-container *ngIf="formGroup.get('insurance').value === true">
                    <!--Type-->
                    <td>
                      <app-input type="select" formControlName="insurance_type" width="160px"
                                 [label]="'CASE_CREDITOR.model.type.label' | translate"
                                 (ngModelChange)="suggestProduct($event, i, 'insurance_type')"
                                 [selectOptions]="insuranceTypes" [selectLabel]="'label'" [selectValue]="'value'"
                                 [showClear]="false" [searchable]="true">
                      </app-input>
                    </td>
                    <!--Amount Paid-->
                    <td>
                      <app-input type="number" formControlName="insurance_amount_paid" width="160px"
                                 [label]="'CASE_CREDITOR.model.amount_paid' | translate">
                      </app-input>
                    </td>
                  </ng-container>
                  <!-- Making Payments -->
                  <td>
                    <app-input type="checkbox" formControlName="making_payments"
                               [label]="'CASE_CREDITOR.model.making_payments' | translate">
                    </app-input>
                  </td>
                </tr>
                <tr class="third-row" [formGroupName]="i">
                  <!--Cancelar-->
                  <td>
                    <p>{{'CASE_CREDITOR.model.cancel' | translate}}</p>
                    <p *ngIf="formGroup.get('initial_balance').value <= formGroup.get('total_repaid').value">
                      {{formGroup.get('current_balance').value}}</p>
                    <p *ngIf="formGroup.get('initial_balance').value > formGroup.get('total_repaid').value">
                      {{formGroup.get('current_balance').value -
                    (formGroup.get('initial_balance').value - formGroup.get('total_repaid').value)}}</p>
                  </td>
                  <!--Amount-->
                  <td>
                    <p>{{'CASES.single.payments.case_payment_plan_generator.form.amount' | translate}}</p>
                    <p>{{formGroup.get('current_balance').value - formGroup.get('total_repaid').value }}</p>
                  </td>
                  <ng-container *ngIf="formGroup.get('debt_type').value === 'mortgage'">
                    <!--Type of Mortgage-->
                    <td>
                      <app-input type="select" formControlName="mortgage_type" width="160px"
                                 (ngModelChange)="suggestProduct($event, i, 'mortgage_type')"
                                 [label]="'CASE_CREDITOR.model.type_of_mortgage.label' | translate"
                                 [selectOptions]="mortgageTypes" [selectLabel]="'label'" [selectValue]="'value'"
                                 [showClear]="false" [searchable]="true">
                      </app-input>
                    </td>
                    <!--Currency-->
                    <td>
                      <app-input type="select" formControlName="mortgage_currency" width="160px"
                                 (ngModelChange)="suggestProduct($event, i, 'mortgage_currency')"
                                 [label]="'CASE_CREDITOR.model.currency.label' | translate"
                                 [selectOptions]="mortgageCurrencies" [selectLabel]="'label'" [selectValue]="'value'"
                                 [showClear]="false" [searchable]="true">
                      </app-input>
                    </td>
                    <!--Clausula suelo-->
                    <td>
                      <app-input type="select" formControlName="mortgage_clause" width="160px"
                                 (ngModelChange)="suggestProduct($event, i, 'mortgage_clause')"
                                 [label]="'CASE_CREDITOR.model.clause' | translate"
                                 [selectOptions]="mortgageClause" [selectLabel]="'label'" [selectValue]="'value'"
                                 [showClear]="false" [searchable]="true">
                      </app-input>
                    </td>
                    <!--Mortgage expenses paid-->
                    <td>
                      <app-input type="select" formControlName="mortgage_expenses_paid" width="160px"
                                 (ngModelChange)="suggestProduct($event, i, 'mortgage_expenses_paid')"
                                 [label]="'CASE_CREDITOR.model.mortgage_expenses_paid' | translate"
                                 [selectOptions]="mortgageExpensesPaid" [selectLabel]="'label'" [selectValue]="'value'"
                                 [showClear]="false" [searchable]="true">
                      </app-input>
                    </td>
                  </ng-container>
                </tr>
                <tr>
                  <td colspan="7"><p class="mt-2"><b>Suggested products:</b> &nbsp;<span
                    class="text-primary">{{suggestedProducts[i]?.product_suggestion}}</span></p></td>
                </tr>
              </ng-container>
              </tbody>
            </table>
            <!-- Add -->
            <button mat-raised-button color="primary" (click)="addCreditor()">
              + {{ 'CASES.editor.creditors.table.actions.add' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
