import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseDistribution } from '../../../../../../../_base-shared/models/Distribution/CaseDistribution';
import {
  DistributionBatch,
  DistributionBatchTotalAmounts,
} from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { User } from '../../../../../../../_base-shared/models/User/User';
import {
  DistributionAmountCalculatorService,
} from '../../distribution-amount-calculator.service';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import { DistributionBatchService } from '../../distribution-batch.service';

@Component({
  selector:    'app-distribution-batch-detail',
  templateUrl: './distribution-batch-detail.component.html',
  styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      mat-progress-bar {
        height: 16px;
      }
    `,
  ],
})
export class DistributionBatchDetailComponent extends DistributionAmountCalculatorService implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public authUser: User;
  public componentType: 'distribution' | 'admin';
  public filterList: any;
  public isLoading                                               = 0;
  public serverResponse: LaravelResourceResponse;
  public paymentStatuses: Array<PaymentStatus>                   = [];
  public distributionBatch: DistributionBatch;
  public batchTotalAmounts: DistributionBatchTotalAmounts;
  public caseDistributions: MatTableDataSource<CaseDistribution> = new MatTableDataSource<CaseDistribution>([]);
  public displayedColumns: Array<string>;
  public selection                                               = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: string };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;
  public storageUrl                                              = environment.STORAGE_URL + '/';

  // Filters
  public paymentStatusFilter: UntypedFormControl = new UntypedFormControl(null);

  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toastr: ToastrService,
    private globalEventService: MainGlobalEventService,
    private distributionService: DistributionService,
    private distributionBatchService: DistributionBatchService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'entered_batch_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.filterList             = {...this.paginatorConfig};
    this.resetSort();
    this.fetchPaymentStatuses();
    this.subscribeToFilterChanges();

    this.globalEventService.authUser$.subscribe(user => {
      this.authUser         = user;
      this.componentType    = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
      this.displayedColumns = this.getTableColumns(this.componentType);
      this.route.paramMap.subscribe(params => {
        const batchId = +params.get('id');
        this.fetchDistributionBatch(batchId);
      });
    });
  }

  ngAfterViewInit(): void {
    this.caseDistributions.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.filterList.page     = this.paginatorConfig.pageIndex + 1;
    this.filterList.per_page = this.paginatorConfig.pageSize;
    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  public sortData(sort) {
    this.filterList.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.filterList.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  private subscribeToFilterChanges() {
    this.subscriptions.push(
      this.paymentStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(paymentStatusIds => {
        this.paymentStatusChanged(paymentStatusIds);
      }),
    );
  }

  private resetPagination(): void {
    this.paginatorConfig     = this.defaultPaginatorConfig;
    this.filterList.per_page = this.paginatorConfig.pageSize;
    this.filterList.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.filterList.sort_by = this.defaultSort.active;
    this.filterList.order   = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    // this.patchSelectAllFilter(0);
    delete this.filterList.case_ids;
  }

  private fetchPaymentStatuses() {
    this.isLoading++;
    this.distributionService.indexPaymentStatuses().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.paymentStatuses = result.data;
    });
  }

  private fetchDistributionBatch(batchId: number) {
    this.isLoading++;
    this.distributionService.showBatch(batchId).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.distributionBatch = result.data;
        this.fetchBatchTotalAmounts(this.distributionBatch);
        this.fetchBatchCaseDistributions(this.distributionBatch);
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private fetchBatchTotalAmounts(distributionBatch: DistributionBatch) {
    this.isLoading++;
    this.distributionBatchService.getTotalAmounts(distributionBatch.id).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.batchTotalAmounts                          = result.data;
        this.batchTotalAmounts.total_distributed_amount = +(this.batchTotalAmounts.total_distributed_amount) +
          +(this.batchTotalAmounts.total_fees_retained);
      });
  }

  private fetchBatchCaseDistributions(distributionBatch: DistributionBatch) {
    this.clearSelection();

    const withRelations = [
      'batch.distribution_provider',
      'case.status',
      'case.latest_case_status_log',
      'case.payment_status',
      'case.latest_contract',
    ];

    this.caseDistributions = new MatTableDataSource<CaseDistribution>([]);

    this.isLoading++;
    this.distributionService.indexBatchDistributions(distributionBatch.id, this.filterList, withRelations)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          const resultData = result.data.map(cDistribution => {
            const totalDistributed = +(cDistribution.distributed_amount) + +(cDistribution.fees_retained);
            return {...cDistribution, distributed_amount: totalDistributed};
          });

          this.caseDistributions      = new MatTableDataSource<CaseDistribution>(resultData);
          this.caseDistributions.sort = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    const columns = [
      'batch_index',
      'case_ref_number',
      'case_status',
      'case_status_updated_at',
      'case_payment_status',
      'monthly_fee_amount',
      'batch_contract_amount',
      // 'funded_amount',
      // 'cash_hurdle_amount',
      // 'distributed_amount',
      // 'fees_retained',
    ];

    if (type === 'admin' || type === 'distribution') {
      columns.push('contract_download');
    }

    return columns;
  }

  public downloadContracts(distributionBatch: DistributionBatch) {
    console.log('TODO: download contracts');
  }

  public changeComponentType(type: 'distribution' | 'admin') {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
  }

  private paymentStatusChanged(paymentStatusIds: Array<number>) {
    this.filterList.payment_statuses = paymentStatusIds ? paymentStatusIds : [];
    this.applyFilters();
  }

  private applyFilters() {
    this.resetPagination();
    this.resetSort();
    this.fetchBatchCaseDistributions(this.distributionBatch);
  }
}
