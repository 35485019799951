import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { MiscConfigService } from './misc-config.service';

@Component({
  selector:    'app-misc-config',
  templateUrl: './misc-config.component.html',
  styleUrls:   ['./misc-config.component.scss'],
})
export class MiscConfigComponent implements OnInit {
  public form: UntypedFormGroup;
  public formReady    = false;
  public isSubmitting = 0;

  constructor(private fb: UntypedFormBuilder,
              private configService: MiscConfigService,
              private toast: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getConfigData();
  }

  private getConfigData() {
    this.configService.getConfigData().subscribe(next => {
      this.buildForm(next.data);
    });
  }

  public submit() {
    this.isSubmitting++;
    this.configService.index(this.form.value)
      .pipe(finalize(() => this.isSubmitting--))
      .subscribe(
        res => {
          this.toast.success(this.translate.instant('CONFIG.system-event.config-saved'));
        },
        error => {
          this.toast.error(this.translate.instant('CONFIG.system-event.config-saved-error'));
        });
  }

  private buildForm(data) {
    const fullTarget                      = data.find(obj => obj.key === 'full_target');
    const fullTargetLso2                  = data.find(obj => obj.key === 'full_target_lso2');
    // const salesTarget = data.find(obj => obj.key === 'sales_target');
    const fullTargetDM                    = data.find(obj => obj.key === 'full_target_dm');
    const fullTargetBDM                   = data.find(obj => obj.key === 'full_target_bdm');
    const fullTargetCasoCongelado         = data.find(obj => obj.key === 'full_target_caso_congelado');
    const fullTargetDocumentacionCompleta = data.find(obj => obj.key === 'full_target_documentacion_completa');
    const fullTargetDgs                   = data.find(obj => obj.key === 'full_target_dgs');
    const fullTargetCajaplus              = data.find(obj => obj.key === 'full_target_cajaplus');
    const minDisposableIncome             = data.find(obj => obj.key === 'min_disposable_income');
    this.form                             = this.fb.group({
      full_target:      fullTarget ? fullTarget.value : '',
      full_target_lso2: fullTargetLso2 ? fullTargetLso2.value : '',
      full_target_dm:  fullTargetDM ? fullTargetDM.value : '',
      full_target_bdm: fullTargetBDM ? fullTargetBDM.value : '',
      full_target_caso_congelado:         fullTargetCasoCongelado ? fullTargetCasoCongelado.value : '',
      full_target_documentacion_completa: fullTargetDocumentacionCompleta ? fullTargetDocumentacionCompleta.value : '',
      full_target_dgs:                    fullTargetDgs ? fullTargetDgs.value : '',
      full_target_cajaplus:               fullTargetCajaplus ? fullTargetCajaplus.value : '',
      min_disposable_income:              minDisposableIncome ? minDisposableIncome.value : '',
    });

    this.formReady = true;
  }
}
