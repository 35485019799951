import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../_shared/shared.module';

import { CaseCreditorStatusRoutingModule } from './case-creditor-status-routing.module';
import { CaseCreditorStatusEditorComponent } from './case-creditor-status-editor/case-creditor-status-editor.component';
import { CaseCreditorStatusListComponent } from './case-creditor-status-list/case-creditor-status-list.component';


@NgModule({
  declarations: [
    CaseCreditorStatusEditorComponent,
    CaseCreditorStatusListComponent
  ],
  imports: [
    CommonModule,
    CaseCreditorStatusRoutingModule,
    TranslateModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class CaseCreditorStatusModule { }
