export class CreditorScheduleConfig {
  id: number;
  creditor_id: number;
  type: string;
  day: number;
  hour: number;

  created_at?: Date | string;
  updated_at?: Date | string;
}

export class Creditor {
  id?: number;
  name: string;
  active?: number;
  approved?: number;
  email?: string;
  email_data_protection?: string;
  email_complaints?: string;
  email_payments?: string;
  email_mandates?: string;
  email_proposals?: string;
  phone_1?: string;
  phone_2?: string;
  address?: string;
  contact_person_1?: string;
  contact_person_2?: string;
  company_name?: string;
  lender_type?: string;
  voting_outcomes_lso: string;
  voting_outcomes_dm: string;
  minimum_dividend_required_lso: string;
  minimum_dividend_required_dm: string;
  can_be_recovery: any;
  payment_account: number;
  payment_reference: number;
  payment_date: Date;
  assigned_to_id: number;
  send_claims: boolean;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  schedule_config?: CreditorScheduleConfig;
}
