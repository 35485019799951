<div class="container-fluid">
  <mat-tab-group>
    <!-- Triggers -->
    <mat-tab [label]="'CONFIG.dialer.tabs.triggers' | translate">
      <app-dialer-triggers></app-dialer-triggers>
    </mat-tab>
    <!-- Lists -->
    <mat-tab [label]="'CONFIG.dialer.tabs.lists' | translate">
      <app-dialer-lists></app-dialer-lists>
    </mat-tab>
  </mat-tab-group>
</div>
