import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../../client.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector   : 'app-payment-outcome',
  templateUrl: './payment-outcome.component.html',
  styleUrls  : ['./payment-outcome.component.scss']
})
export class PaymentOutcomeComponent implements OnInit {

  public isLoading = 0;
  public isSuccessful;
  public message;
  public uuid;

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.isLoading++;
    this.route.queryParams
      .subscribe(res => {
        this.uuid = res.uuid;
        this.checkPaymentStatus(res);
      });
  }

  checkPaymentStatus(paymentData) {
    const data = {
      uuid: this.uuid,
      paymentData
    };
    this.clientService.checkPaymentSatus(data)
      .pipe(
        finalize(() => this.isLoading--)
      )
      .subscribe(res => {
        this.isSuccessful   = res.data.success;
        this.message = res.data.message;
      });
  }

}
