import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Status } from '../../../../../_base-shared/models/Status/Status';
import { CallStatus } from '../../../../../_base-shared/models/Status/CallStatus';

@Injectable({
  providedIn: 'root',
})

export class CallStatusService extends MainBaseApiService {
  index(data = {}) {
    return this.http.get<LaravelResourceResponse<Array<CallStatus>>>(`${ this.apiUrl }/call-statuses`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  get(callStatusId: number) {
    return this.http.get<LaravelResourceResponse<CallStatus>>(`${ this.apiUrl }/call-statuses/${ callStatusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(status: Status) {
    return this.http.post<LaravelResourceResponse<CallStatus>>(`${ this.apiUrl }/call-statuses`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(statusId: number, status: Status) {
    return this.http.put<LaravelResourceResponse<CallStatus>>(`${ this.apiUrl }/call-statuses/${ statusId }`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(statusId: number) {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/call-statuses/${ statusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }
}
