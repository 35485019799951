import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckIfComponentIsDirty } from '../../../../../_base-shared/guards/check-if-comopnent-dirty.guard';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { DocumentComponent } from '../document/document.component';
import { NotificationListComponent } from '../notification/notification-list/notification-list.component';
import { TaskListComponent } from '../task/task-list/task-list.component';
import { CaseDashboardComponent } from './case-dashboard/case-dashboard.component';
import { CaseCreditorDetailComponent } from './case-detail/case-creditor-detail/case-creditor-detail.component';
import {
  CaseCreditorDocumentListComponent
} from './case-detail/case-creditor-detail/case-creditor-document-list/case-creditor-document-list.component';
import {
  CaseCreditorDraftComponent
} from './case-detail/case-creditor-detail/case-creditor-draft/case-creditor-draft.component';
import {
  CaseCreditorGeneralDetailComponent
} from './case-detail/case-creditor-detail/case-creditor-general-detail/case-creditor-general-detail.component';
import {
  CaseCreditorInstallmentListComponent
} from './case-detail/case-creditor-detail/case-creditor-installment-list/case-creditor-installment-list.component';
import { CaseCreditorListComponent } from './case-detail/case-creditor-list/case-creditor-list.component';
import { CaseGeneralDetailComponent } from './case-detail/case-detail-general/case-general-detail.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import {
  CaseDocumentListLegacyComponent
} from './case-detail/case-document-list-legacy/case-document-list-legacy.component';
import { CaseDocumentListDeprecatedConceptComponent } from './case-detail/case-document-list-deprecated-concept/case-document-list-deprecated-concept.component';
import { CaseDocumentListComponent } from './case-detail/case-document-list/case-document-list.component';
import { CaseDraftComponent } from './case-detail/case-draft/case-draft.component';
import { CasePaymentComponent } from './case-detail/case-payment/case-payment.component';
import { CaseCreditorEditorComponent } from './case-editor/case-creditor-editor/case-creditor-editor.component';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseGeneralEditorComponent } from './case-editor/case-general-editor/case-general-editor.component';
import { CasePaymentEditorComponent } from './case-editor/case-payment-editor/case-payment-editor.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseSnapshotComponent } from './case-snapshot/case-snapshot.component';
import { CreditorProposalPreviewComponent } from './creditor-proposal-preview/creditor-proposal-preview.component';

const caseListChildren: Routes = [
  {
    path:        'create',
    component:   CaseEditorComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {editorType: 'create', allowAgent: false, allowManager: true},
    children:    [
      {
        path:      'general',
        component: CaseGeneralEditorComponent,
        data:      {editorType: 'create'},
      },
    ],
  },
  {
    path:        ':id',
    component:   CaseDetailComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    children:    [
      {path: '', redirectTo: 'general', pathMatch: 'full'},
      {path: 'general', component: CaseGeneralDetailComponent},
      {path: 'documents-legacy', component: CaseDocumentListLegacyComponent},
      {path: 'documents', component: CaseDocumentListComponent},
      {
        path: 'creditors', children: [
          {path: '', component: CaseCreditorListComponent},
          {
            path: ':id', component: CaseCreditorDetailComponent, children: [
              {path: '', redirectTo: 'general', pathMatch: 'full'},
              {path: 'general', component: CaseCreditorGeneralDetailComponent},
              {path: 'documents', component: CaseCreditorDocumentListComponent},
              {path: 'legal', component: CaseCreditorDraftComponent},
              {path: 'payments', component: CaseCreditorInstallmentListComponent},
            ]
          }
        ]
      },
      {path: 'legal', component: CaseDraftComponent},
      {path: 'payments', component: CasePaymentComponent},
      {path: 'documents-concept', component: CaseDocumentListDeprecatedConceptComponent},
      {path: 'snapshot', component: CaseSnapshotComponent},
    ],
    data:        {allowAgent: false, allowManager: true},
  },
  {
    path:        ':id/edit',
    component:   CaseEditorComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {editorType: 'edit', allowAgent: true, allowManager: true},
    children:    [
      {
        path:          'general',
        component:     CaseGeneralEditorComponent,
        canDeactivate: [CheckIfComponentIsDirty],
        data:          {editorType: 'edit'},
      },
      {path: 'creditors', component: CaseCreditorEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'payment', component: CasePaymentEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
    ],
  },
];

const routes: Routes = [
  {
    path:        'cases',
    children:    [
      {path: '', component: CaseListComponent, data: {onlyRelated: false}},
      ...caseListChildren,
    ],
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {filterType: 'case', allowAgent: true, allowManager: true},
  },
  {
    path:     'affiliate-cases',
    children: [
      {path: '', component: CaseListComponent, data: {filterType: 'affiliate_case'}},
    ],
  },
  {
    path:      'case-dashboard',
    component: CaseDashboardComponent,
    children:  [
      {path: '', redirectTo: 'cases', pathMatch: 'full'},
      {
        path:        'cases',
        canActivate: [MainAuthGuard, MainPermissionGuard],
        data:        {filterType: 'case', allowAgent: true, allowManager: false},
        children:    [
          {path: '', component: CaseListComponent},
          ...caseListChildren,
        ],
      },
      {
        path:     'my-cases',
        children: [
          {path: '', component: CaseListComponent, data: {filterType: 'case', onlyRelated: true}},
          ...caseListChildren,
        ],
      },
      {
        path:     'tasks',
        children: [
          {path: '', component: TaskListComponent},
        ],
      },
      {
        path:     'my-tasks',
        children: [
          {path: '', component: TaskListComponent, data: {onlyRelated: true}},
        ],
      },
      {
        path:     'notifications',
        children: [
          {path: '', component: NotificationListComponent},
        ],
      },
    ],
  },
  {path: 'app-documents/download', component: DocumentComponent},
  {path: 'creditor-proposal-preview', component: CreditorProposalPreviewComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class CaseRoutingModule {
}
