<div class="container-fluid payment-page-wrap" *ngIf="formActive"
     [formGroup]="sepaForm">
  <div class="sepa-page-header">
    <div class="row">
      <div class="col-12">
        <h2>Domiciliación de pagos</h2>
      </div>
      <div class="col-12">
        <p>Complete el siguiente formulario para crear su plan de pago</p>
      </div>
    </div>
  </div>

  <div class="sepa-content">
    <div class="row" *ngIf="!(isSuccessful || serverError || isSubmitting) " style="border-bottom: 1px solid #e7eaf3;">
      <div class="col-12">
        <h3 class="h5">Orden de domiciliación de adeudo directo SEPA</h3>
      </div>
      <div class="col-md-6 pt-4">
        <label for="first_name">Apellido</label>
        <input type="text" id="first_name" class="form-control p-2" formControlName="iban_first_name">
        <mat-error *ngIf="sepaForm.get('iban_first_name').touched && sepaForm.get('iban_first_name').invalid">Este campo
          es requerido
        </mat-error>
      </div>
      <div class="col-md-6 pt-4">
        <label for="last_name">Nombre</label>
        <input type="text" id="last_name" class="form-control p-2" formControlName="iban_last_name">
        <mat-error *ngIf="sepaForm.get('iban_last_name').touched && sepaForm.get('iban_last_name').invalid">Este campo
          es requerido
        </mat-error>
      </div>
      <div class="col-12 pt-4">
        <label for="iban">Número de cuenta - IBAN</label>
        <input type="text" id="iban" class="form-control p-2" formControlName="iban"
               placeholder="ESXX XXXX XXXX XXXX XXXX XXXX">
        <mat-error *ngIf="sepaForm.get('iban').touched && sepaForm.get('iban').hasError('required')">Este campo es
          requerido
        </mat-error>
        <mat-error *ngIf="sepaForm.get('iban').touched && sepaForm.get('iban').hasError('pattern')">Número IBAN no
          válido
        </mat-error>
      </div>
      <div class="col py-5">
        <button class="btn submit-button" [disabled]="!sepaForm.get('terms').value" (click)="onSubmit()">Enviar</button>
        <br>
        <mat-error *ngIf="!sepaForm.get('terms').value">Tienes que aceptar los términos y condiciones</mat-error>
      </div>
    </div>
  </div>

  <div class="sepa-content">
    <div class="row d-flex justify-content-center align-items-center"
         *ngIf="isSuccessful || serverError || isSubmitting" style="border-bottom: 1px solid #e7eaf3;">
      <ng-container *ngIf="isSubmitting">
        <mat-spinner color="primary" diameter="100" class="mb-3"></mat-spinner>
      </ng-container>
      <ng-container *ngIf="!isSubmitting">
        <div *ngIf="isSuccessful" class="text-center">
          <h1 class="text-center">Orden de domiciliación bancaria completada</h1>
          <p class="text-center">
            <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="primary">check_circle</mat-icon>
          </p>
        </div>
        <div *ngIf="serverError" class="text-center">
          <h1 class="text-center">Vuelve a intentarlo más tarde</h1>
          <p class="text-center">
            <mat-icon style="font-size: 100px; width: 100px; height: 100px" color="warn">report</mat-icon>
          </p>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="sepa-content">
    <div class="row pb-5">
      <div class="col-12">
        <p>{{ companyNameLegal }}, {{ companyAddress }}</p>
        <p>
          Mediante la firma de esta orden de domiciliación, el deudor autoriza a (A) {{ companyName }} a enviar
          instrucciones a
          la entidad del deudor para adeudar en cuenta y (B) a la entidad para efectuar los adeudos en su cuenta
          siguiendo las instrucciones de {{ companyName }}. Como parte de sus derechos, el deudor esta legitimado al
          reembolso
          por su entidad en los términos y condiciones del contrato suscrito con la misma. La
          solicitud de reembolso deberá efectuarse dentro de las ocho semanas que siguen a la fecha de adeudo en cuenta.
          Nota: Puede obtener información adicional sobre sus derechos en su entidad financiera.
        </p>
      </div>
      <br>
      <div class="d-flex col-12">
        <div class="pr-3">
          <input id="terms-check" type="checkbox" formControlName="terms">
        </div>
        <div class="">
          <label for="terms-check" class="terms-label">
            Confirmo, por la firma digital de este documento, que la información proporcionada es cierta y que estoy
            legitimado para aceptar cargos en la cuenta bancaria que indico. Autorizo a {{ companyName }} a realizar
            cargos en la
            cuenta bancaria anterior para los fines descritos en el contrato firmado con la compañía.
          </label>
          <mat-error *ngIf="!sepaForm.get('terms').value">Tienes que aceptar los términos y condiciones</mat-error>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="payment-page-footer">
  <div class="container footer-links" style="border-bottom: 1px solid #e7eaf3;">
    <div class="row">
      <div class="col-lg-3 mb-7 mb-lg-0 pb-5 pb-lg-0">
        <div class="d-inline-flex align-self-start flex-column h-100">
          <a class="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
             href="https://{{ generalSiteUrl }}/index.php">
            <img class="img-fluid" src="assets/img/logos/devuelve_logo_blue.png" alt="Logo" width="150">
          </a>
          <div class="mt-3">
            <a href="https://www.confianzaonline.es/empresas/deudafix.htm" target="_blank"
               style="padding-left: 10px">
              <img src="assets/img/3rdparty/ecommerceeu-confianza-fondo-claro-100.png" width="130px">
            </a>
          </div>
          <div class="mt-3">
            <a href="https://www.boe.es/buscar/mediadores.php?campo%5B0%5D=NOMBRE&amp;dato%5B0%5D=DEUDAFIX&amp;operador%5B0%5D=and&amp;campo%5B1%5D=ID_PROV_TRABAJO&amp;dato%5B1%5D=&amp;page_hits=50&amp;accion=Buscar"
               target="_blank" style="padding-left: 0px">
              <img src="assets/img/3rdparty/ministerio-justicia.jpg" width="155px">
            </a>
            <p style="font-size: 12px; width: 75%">Registro del Ministerio De Justicia</p>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4 mb-md-0">
        <h4 class="h6 font-weight-semi-bold">Recursos</h4>
        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/registrarse.php">
              Acceso cliente
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/quejas.php">
              Quejas
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/blog.php">
              Noticias
            </a>
          </li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4 mb-md-0">
        <h4 class="h6 font-weight-semi-bold">Empresa</h4>
        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/aviso_legal.php">
              Aviso legal
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/condiciones.php">
              Condiciones generales
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/privacidad.php">
              Privacidad
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/cookies.php">
              Cookies
            </a>
          </li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-sm-4 col-md-3 col-lg-2 mb-4 mb-md-0">
        <h4 class="h6 font-weight-semi-bold">Empleo</h4>

        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li><a class="list-group-item list-group-item-action"
                 href="https://{{ generalSiteUrl }}/careers.php">Legal</a>
          </li>
          <li><a class="list-group-item list-group-item-action"
                 href="https://{{ generalSiteUrl }}/careers.php">Tecnología</a>
          </li>
          <li><a class="list-group-item list-group-item-action"
                 href="https://{{ generalSiteUrl }}/careers.php">Finanzas</a>
          </li>
          <li><a class="list-group-item list-group-item-action"
                 href="https://{{ generalSiteUrl }}/careers.php">Operaciones</a>
          </li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-md-3 col-lg-2">
        <h4 class="h6 font-weight-semi-bold">Contáctenos</h4>

        <!-- Address -->
        <address>
          <ul class="list-group list-group-flush list-group-borderless mb-0">
            <li>
              <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/contactanos.php">
                Contacto
              </a>
            </li>
            <li><a class="list-group-item list-group-item-action" href="#">Tel. {{ companyPhoneLegal }}</a></li>
            <li>
              <a class="list-group-item list-group-item-action">
                {{ companyAddress }}
              </a>
            </li>
          </ul>
        </address>

        <!-- Social Networks -->
        <div class="row">
          <ul class="list-inline text-sm-left mb-0">
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://www.facebook.com/deudafix/">
                <span class="fab fa-facebook-f btn-icon__inner"></span>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://www.instagram.com/deudafix/">
                <span class="fab fa-instagram btn-icon__inner"></span>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://twitter.com/deudafix">
                <span class="fab fa-twitter btn-icon__inner"></span>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://www.linkedin.com/company/deudafix">
                <span class="fab fa-linkedin btn-icon__inner"></span>
              </a>
            </li>
          </ul>
        </div>
        <!-- End Social Networks -->
        <!-- End Address -->
      </div>
    </div>
  </div>
  <div class="container footer-text">
    <p class="mb-4">{{ companyNameLegal }}, está inscrito como Mediador Concursal en el Ministerio de
      Justicia, número de
      registro 1953. {{ companyNameLegal }}, CIF: B88315890, inscrita en el Registro Mercantil de Madrid,
      Hoja M-688444, Tomo 38720, Folio 164.</p>
    <p class="mb-4" style="color: #1E88E5">Más de 500,000 deudas reducidas en dos países, cambiamos vidas.</p>
    <p class="mb-4">
      Siguiendo nuestra política de buenas practicas y cumplimiento, para que usted pueda acogerse a la Ley de la
      Segunda Oportunidad y obtener la cancelación de sus deudas, debe: (i) tener deudas calificadas como no
      garantizadas, por importes de entre un mínimo de 15.000 € y máximo de 5.000.000 €, además (ii) dichas deudas deben
      estar reconocidas y distribuidas entre un mínimo de dos o más acreedores.
    </p>
    <p class="mb-0">
      Cumplidos los requisitos legales del procedimiento y demostrada la buena fé del postulante, la cancelación de
      hasta un 75% de sus deudas es realista, sin embargo, el monto final de la cancelación de la deuda para cada
      cliente difiere según sus circunstancias financieras individuales y está en todo caso sujeto a la aprobación de
      sus acreedores o los tribunales.
    </p>
  </div>
</div>
