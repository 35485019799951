import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';

@Component({
  selector:    'app-case-relation-list',
  templateUrl: './case-relation-list.component.html',
  styles:      [
    `
      .card-height {
        min-height: 550px;
      }
    `],
})
export class CaseRelationListComponent implements OnInit {
  @Input() case: Case;
  @Output() caseCreditorsUpdated: EventEmitter<Case> = new EventEmitter<Case>();
  public selectedTab                                 = new UntypedFormControl(null);
  public tabs: Array<AppSelectOption>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.case.packager.master ? this.getRelationTabs() : this.getRelationTabsPackagers();
    this.route.queryParams.subscribe(result => {
      const queryParams   = new URLSearchParams(result);
      const activeTabName = queryParams.get('activeTab');
      const activeTab     = this.tabs.find(fTab => fTab.value === activeTabName);
      if (activeTab) {
        this.selectedTab.patchValue(activeTab.value);
      }
    });

    this.translate.onLangChange.subscribe(next => {
      this.case.packager.master ? this.getRelationTabs() : this.getRelationTabsPackagers();
    });
  }

  public selectTab(tabName: string) {
    if (this.selectedTab.value === tabName) {
      return;
    }

    const queryParams: Params = {activeTab: tabName};

    this.router.navigate(
      [],
      {
        relativeTo:          this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private getRelationTabs() {
    this.tabs = [
      {value: 'notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {
        value: 'payment_notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment'),
      },
      {value: 'draft_notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading_draft')},
      {
        value: 'creditor_notes',
        label: this.translate.instant('CASES.single.general.relation_list.creditor_notes.heading'),
      },
      {
        value: 'email_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_email'),
      },
      {
        value: 'sms_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_sms'),
      },
      {
        value: 'call_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_call'),
      },
      {value: 'status_logs', label: this.translate.instant('CASES.single.general.relation_list.status_logs.heading')},
      {value: 'call_status_logs', label: this.translate.instant('CASES.single.general.relation_list.call_status_logs.heading')},
      {
        value: 'payment_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.payment_status_logs.heading'),
      },
      {value: 'contracts', label: this.translate.instant('CASES.single.general.relation_list.contracts.heading')},
      {value: 'proposals', label: this.translate.instant('CASES.single.general.relation_list.proposals.heading')},
      {value: 'aep_history', label: this.translate.instant('CASES.single.general.relation_list.aep_history.heading')},
      {value: 'tasks', label: this.translate.instant('CASES.single.general.relation_list.tasks.heading')},
      {value:  'distribution',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_distribution'),
      },
    ];
  }

  private getRelationTabsPackagers(): void {
    this.tabs = [
      {value: 'notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {
        value: 'payment_notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment'),
      },
      {
        value: 'email_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_email'),
      },
      {
        value: 'sms_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_sms'),
      },
      {
        value: 'call_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_call'),
      },
      {value: 'status_logs', label: this.translate.instant('CASES.single.general.relation_list.status_logs.heading')},
      {value: 'call_status_logs', label: this.translate.instant('CASES.single.general.relation_list.call_status_logs.heading')},
      {
        value: 'payment_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.payment_status_logs.heading'),
      },
      {value: 'contracts', label: this.translate.instant('CASES.single.general.relation_list.contracts.heading')},
      {value: 'tasks', label: this.translate.instant('CASES.single.general.relation_list.tasks.heading')}
    ];
  }

  public updateCaseCreditors(updatedCase: Case) {
    this.case.secured_creditors   = updatedCase.secured_creditors;
    this.case.unsecured_creditors = updatedCase.unsecured_creditors;
    this.case.public_debts        = updatedCase.public_debts;
    this.caseCreditorsUpdated.emit(this.case);
  }
}
