<div *ngIf="isLoading" class="pt-4 pl-4">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<div *ngIf="serverResponse" class="row">
  <div class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<div class="row">
  <div *ngIf="case" class="col-4">
    <app-case-quick-client-editor [case]="case" (caseClientsUpdated)="updateCaseClients($event)">
    </app-case-quick-client-editor>
  </div>
  <div *ngIf="case" class="col-4">
    <app-case-status-editor [case]="case" (caseUpdate)="updateCaseStatusAndProduct($event)"></app-case-status-editor>
  </div>
  <div *ngIf="case" class="col-4">
    <app-case-department-user-editor [case]="case" (caseDepartmentsUpdated)="updateCaseDepartmentAssignments($event)"></app-case-department-user-editor>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <app-case-relation-list *ngIf="case" [case]="case" (caseCreditorsUpdated)="updateCaseCreditors($event)">
    </app-case-relation-list>
  </div>
</div>
