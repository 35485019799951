<div class="card-body">
  <div class="row">
    <div class="col-6">
      <form *ngIf="formFileUpload" [formGroup]="formFileUpload" (ngSubmit)="submitFiles()" class="form">
        <div class="row pb-4">
          <!-- File select -->
          <div class="col-4 pt-2">
            <div class="form-group">
              <div class="input-group">
                <div class="custom-file">
                  <input multiple type="file" class="custom-file-input" [lang]="currentLanguage" id="uploadFile"
                         formControlName="files" (change)="onFileSelect($event)">
                  <label class="custom-file-label" for="uploadFile">
                    {{ 'DOCUMENTS.choose-file' | translate }}
                  </label>
                </div>
              </div>
              <div *ngFor="let file of selectedUploadFiles"
                   class="d-flex justify-content-between align-items-center">
                <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                <button type="button" mat-icon-button color="warn" (click)="removeFile(file.index)">
                  <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- Client/Partner selection-->
          <div *ngIf="case.joint_application" class="col-1 inline-form-button d-flex justify-content-between">
            <div class="form-group upload-for" style="margin-right: 0">
              <label for="uploadFor">{{ "CASES.single.upload-for" | translate }}</label>
              <select id="uploadFor" class="form-control" formControlName="client_role">
                <option value="client">{{ "CASES.single.client" | translate }}</option>
                <option value="partner">{{ "CASES.single.partner" | translate }}</option>
              </select>
            </div>
          </div>
          <!-- Document Type Selector -->
          <div class="col-5 d-flex">
            <app-input *ngIf="caseDocumentTypeCategories?.length" type="select"
                       formControlName="app_document_type_id" appearance="outline" [searchable]="true"
                       [label]="'DOCUMENTS.select-category' | translate" [selectOptions]="caseDocumentTypeCategories"
                       [optGroupProperty]="'document_types'" [optGroupLabel]="'name_es'"
                       [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                       [showClear]="false" [fullWidth]="true">
            </app-input>
          </div>
          <div class="col-2 pt-1">
            <app-spinner-btn [loading]="filesAreUploading" [name]="'SHARED.submit' | translate"></app-spinner-btn>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <!-- Document Upload / Document List-->
    <div class="col-12 col-lg-6">
      <mat-expansion-panel class="mt-2" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Client Documents
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion multi>
          <!-- Basic -->
          <mat-expansion-panel class="mt-2" *ngFor="let documentTypeCategory of caseDocumentTypeCategories"
                               [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ documentTypeCategory.name_es }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel *ngFor="let documentType of documentTypeCategory.document_types"
                                 style="transition: none">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ documentType.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let document of documentType?.documents"
                   [ngStyle]="{border: documentType.type === 'client_case_creditor' && !document.case_creditor_id ? '1px solid red' : 'none'}">
                <div *ngIf="document.editing" class="d-flex flex-row justify-content-end" [formGroup]="formDocument">
                  <app-input *ngIf="case?.case_creditors?.length" type="select"
                             appearance="outline" [searchable]="true"
                             [label]="'DOCUMENTS.select-category' | translate"
                             formControlName="case_creditor_id"
                             [selectOptions]="case?.case_creditors"
                             [selectLabel]="'reference_number'" [selectValue]="'id'"
                             [showClear]="false">
                  </app-input>
                  <div>
                    <button type="button" mat-raised-button color="primary" class="ml-2 mt-1 mr-1"
                            (click)="reassignCaseCreditor(document, formDocument.get('case_creditor_id').value)">
                      Reassign
                    </button>
                    <button type="button" mat-raised-button class="ml-2 mt-1 mr-1"
                            (click)="editDocument(document, false)">
                      Close
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="documentType.type === 'client_case_creditor' && !document.case_creditor_id && !document.editing"
                  class="d-flex justify-content-end">
                  <!--                  <button type="button" mat-raised-button color="warn" class="ml-2 mt-1 mr-1"-->
                  <!--                          (click)="editDocument(document, true)">-->
                  <!--                    Edit Document-->
                  <!--                  </button>-->
                </div>
                <div *ngFor="let documentFile of document.files" [formGroup]="formDocumentFile"
                     class="row mb-3">
                  <div class="col-1 d-flex justify-content-center align-items-center">
                    <div class="document-file-order-handle" cdkDragHandle>
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="col-4">
                    <div *ngIf="documentFile.editing"
                         class="text-right d-flex flex-row align-items-center">
                      <app-input type="text" formControlName="name" [appearance]="'outline'"
                                 [label]="'SHARED.model.name' | translate" [extraLabel]="false">
                      </app-input>
                    </div>
                    <ng-container *ngIf="!documentFile.editing">
                      <a target="_blank" href="{{ storageUrl + documentFile.path }}"
                         (click)="selectPreviewFile($event, documentFile)">
                        {{ documentFile.name }}.{{ documentFile.extension }}
                      </a>
                      <label *ngIf="documentFile.status" class="pl-1">({{ documentFile.status | translate }})</label>
                      <div>
                        <button type="button" class="ml-2" mat-button color="primary"
                                (click)="downloadFile(documentFile)">
                          {{ "CASES.single.download" | translate }}
                        </button>
                        <button *ngIf="documentFile.converted_pdf" type="button" class="ml-2" mat-button color="primary"
                                (click)="downloadFile(documentFile.converted_pdf)">
                          {{ "CASES.single.download" | translate }} PDF
                        </button>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-5">
                    <div class="row">
                      <div *ngIf="documentFile.editing" class="col-6">
                        <app-input *ngIf="caseDocumentTypeCategories?.length" type="select"
                                   formControlName="app_document_type_id" appearance="outline" [searchable]="true"
                                   [label]="'DOCUMENTS.select-category' | translate"
                                   [selectOptions]="caseDocumentTypeCategories"
                                   [optGroupProperty]="'document_types'" [optGroupLabel]="'name_es'"
                                   [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                                   [showClear]="false" [fullWidth]="true">
                        </app-input>
                      </div>
                      <div *ngIf="documentFile.editing && false" class="col-6">
                        <app-input *ngIf="!case.joint_application" type="select" formControlName="user_id"
                                   appearance="outline" fullWidth="false"
                                   [label]="'Uploaded by'" [selectLabel]="'label'" [selectValue]="'value'"
                                   [selectOptions]="[{label: 'Client', value: case.user_id}]">
                        </app-input>
                        <app-input *ngIf="case.joint_application" type="select" formControlName="user_id"
                                   appearance="outline" fullWidth="false"
                                   [label]="'Uploaded by'" [selectLabel]="'label'" [selectValue]="'value'"
                                   [selectOptions]="[{label: 'Client', value: case.user_id},{label: 'Partner', value: case.partner_user_id}]">
                        </app-input>
                      </div>
                      <div *ngIf="documentFile.editing" class="col-6">
                        <app-input *ngIf="documentType.type === 'client_case_creditor' && !document.case_creditor_id &&
                                   case?.case_creditors?.length" type="select"
                                   appearance="outline" [searchable]="true"
                                   [label]="'Claim reference'"
                                   formControlName="case_creditor_id"
                                   [selectOptions]="case?.case_creditors"
                                   [selectLabel]="'reference_number'" [selectValue]="'id'"
                                   [showClear]="false">
                        </app-input>
                      </div>
                      <div *ngIf="!documentFile.editing" class="col-12">
                        <button type="button" *ngIf="documentType?.documents[0].app_document_request_id"
                                mat-raised-button color="primary"
                                (click)="updateDocumentFileStatus(documentType?.documents[0], documentFile, 'accepted')">
                          {{ "SHARED.accept" | translate }}
                        </button>
                        <button type="button" mat-raised-button color="warn" class="ml-2"
                                *ngIf="documentType?.documents[0].app_document_request_id && documentFile.status !== 'declined'"
                                (click)="updateDocumentFileStatus(documentType?.documents[0], documentFile, 'declined')">
                          {{ "SHARED.decline" | translate }}
                        </button>
                        <button type="button" mat-raised-button color="warn" class="ml-2"
                                (click)="deleteFile(documentType?.documents[0], documentFile)">
                          {{ "SHARED.delete" | translate }}
                        </button>
                        <!-- TODO: implement when document requests are ready-->
                        <!--                      <button type="button" mat-stroked-button color="primary" class="ml-2"-->
                        <!--                              (click)="resendRequest(documentType?.documents[0], documentFile)">-->
                        <!--                        {{ "CASES.single.resend-request" | translate }}-->
                        <!--                      </button>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-right text-end">
                    <button type="button" mat-button color="primary" *ngIf="!documentFile.editing"
                            (click)="toggleDocumentFileEdit(documentFile, true, documentType?.documents[0] )">
                      {{ "SHARED.edit" | translate }}
                    </button>
                    <button type="button" mat-button color="primary" *ngIf="documentFile.editing"
                            (click)="toggleDocumentFileEdit(documentFile, false, documentType?.documents[0])">
                      {{ "SHARED.cancel" | translate }}
                    </button>
                    <button type="button" mat-button color="primary" *ngIf="documentFile.editing"
                            (click)="updateDocumentFile(documentFile, documentType?.documents[0])">
                      {{ "SHARED.save" | translate }}
                    </button>
                  </div>
                  <div class="col-12 d-flex"
                       *ngIf="imageEditingAllowed && documentFile.editing && documentFile.mime_type.includes('image')">
                    <button type="button" class="ml-2" mat-raised-button color="primary"
                            (click)="openEditModal(documentType?.documents[0], documentFile)">
                      {{ "CASES.single.edit-image" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
    </div>
    <!-- File Preview -->
    <div class="col-12 col-lg-6" *ngIf="previewFileType === 'object'; else photo" style="height: auto">
      <div *ngIf="previewFileUrl" style="height: 100%">
        <div [ngClass]="{'height-50' : true, 'sticky' : previewIsSticky}">
          <object [data]="previewFileUrl" type="application/pdf" width="100%" height="100%">
            <iframe [src]="previewFileUrl" width="100%" height="100%" style="border: none;">
              <p>
                Your browser does not support PDFs.
                <a [href]="previewFileUrl">Download the PDF</a>
              </p>
            </iframe>
          </object>
        </div>
      </div>
    </div>
    <ng-template #photo>
      <div class="col-12 col-lg-6 d-flex justify-content-center">
        <div [ngClass]="{'height-50' : true, 'sticky' : previewIsSticky}">
          <img [src]="previewFileUrl" alt="" class="img-fluid" style="max-height: 83vh">
        </div>
      </div>
    </ng-template>
  </div>
</div>
