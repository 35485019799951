import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  RedirectToClientAppDynamicComponent,
} from './redirect-to-client-app-dynamic/redirect-to-client-app-dynamic.component';
import { RedirectToClientAppComponent } from './redirect-to-client-app/redirect-to-client-app.component';

const routes: Routes = [
  {
    path:     'client',
    children: [
      {path: 'login', component: RedirectToClientAppComponent, data: {routeUrl: 'auth/login'}},
      {path: 'sign-up', component: RedirectToClientAppComponent, data: {routeUrl: 'auth/sign-up'}},
      {path: 'forgot-password', component: RedirectToClientAppComponent, data: {routeUrl: 'auth/forgot-password'}},
      {path: 'new-password', component: RedirectToClientAppComponent, data: {routeUrl: 'auth/new-password'}},
      {path: 'dashboard', component: RedirectToClientAppComponent, data: {routeUrl: 'dashboard'}},
      {
        path:      'cases/:caseUuid/users/:userUuid/relation-validation',
        component: RedirectToClientAppDynamicComponent,
        data:      {readonly: false, routeUrl: 'relation-validation'},
      },
      {path: 'sign-contract', component: RedirectToClientAppComponent, data: {routeUrl: 'public/sign-contract'}},
      {path:       'cases/:caseUuid/profile',
        component: RedirectToClientAppDynamicComponent,
        data:      {routeUrl: 'profile'},
      },
      {
        path:      'cases/:caseUuid/users/:userUuid/your-case',
        component: RedirectToClientAppDynamicComponent,
        data:      {readonly: true, routeUrl: 'your-case'},
      },
      {path:       'cases/:caseUuid/users/:userUuid/document-upload',
        component: RedirectToClientAppDynamicComponent,
        data:      {routeUrl: 'documents'},
      },
      {path: 'unsubscribe', component: RedirectToClientAppComponent, data: {routeUrl: 'public/unsubscribe'}},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ClientRoutingModule {
}


