import { Component } from '@angular/core';

@Component({
  selector: 'app-case-creditor-installment-list',
  templateUrl: './case-creditor-installment-list.component.html',
  styleUrls: []
})
export class CaseCreditorInstallmentListComponent {

}
