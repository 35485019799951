import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PaymentPlanType } from '../../../../../../_base-shared/models/Product/PaymentPlanType';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { OneTimeFeeService } from '../one-time-fee.service';

@Component({
  selector:    'app-one-time-fee-list',
  templateUrl: './one-time-fee-list.component.html',
  styleUrls:   ['./one-time-fee-list.component.scss'],
})

export class OneTimeFeeListComponent implements OnInit, OnDestroy {
  public authUser: User;
  private subscriptions: Array<Subscription> = [];
  public oneTimeFees: PaymentPlanType[];
  public displayedColumns: string[]          = ['id', 'name', 'created_at', 'actions'];
  public actions: string[]                   = ['Edit', 'Delete'];
  public isLoading                           = 0;
  public dataSource: MatTableDataSource<PaymentPlanType>;
  public paginatorConfig                     = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search                              = new UntypedFormControl('');
  public searchFocus                         = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private oneTimeFeeService: OneTimeFeeService,
    private toastr: ToastrService,
    public translateService: TranslateService,
    private globalEventsService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));

    this.fetchOneTimeFees();
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.fetchOneTimeFees());
  }

  public fetchOneTimeFees(): void {
    this.dataSource = new MatTableDataSource<PaymentPlanType>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };
    this.subscriptions.push(
      this.oneTimeFeeService.index(data)
        .pipe(
          finalize(() => this.isLoading--),
        )
        .subscribe(res => {
          this.oneTimeFees            = res.data;
          this.dataSource             = new MatTableDataSource<PaymentPlanType>(res.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = res.meta.total;
        })
    );
  }

  public openDeleteDialog(affiliateId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('SHARED.delete-item'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteOneTimeFee(affiliateId);
      }
    });
  }

  public deleteOneTimeFee(creditorId: number): void {
    this.subscriptions.push(
      this.oneTimeFeeService.delete(creditorId)
        .subscribe(res => {
            this.fetchOneTimeFees();
            this.toastr.success(this.translateService.instant('CONFIG.one-time-fee.fee-deleted'));
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          })
    );
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchOneTimeFees();
  }

  public toggleFocus(setFocus: boolean): void {
    this.searchFocus = setFocus;
  }

  public onChange(): void {
    this.fetchOneTimeFees();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

}
