import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { PaymentPlanType } from '../../../../../_base-shared/models/Product/PaymentPlanType';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class OneTimeFeeService extends MainBaseApiService {

  public index(data = {}): Observable<LaravelResourceResponse<Array<PaymentPlanType>>> {
    return this.http.get<LaravelResourceResponse<Array<PaymentPlanType>>>(`${ this.apiUrl }/one-time-fee-type`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public get(paymentPlanTypeId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/payment-plan-type/${ paymentPlanTypeId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public store(oneTimeFeeType: PaymentPlanType): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<PaymentPlanType>>(`${ this.apiUrl }/one-time-fee-type`,
      oneTimeFeeType)
      .pipe(catchError(response => this.handleError(response)));
  }

  public update(paymentPlanTypeId: number, paymentPlanType: PaymentPlanType): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse<PaymentPlanType>>(
      `${ this.apiUrl }/one-time-fee-type/${ paymentPlanTypeId }`, paymentPlanType)
      .pipe(catchError(response => this.handleError(response)));
  }

  public delete(paymentPlanTypeId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/one-time-fee-type/${ paymentPlanTypeId }`)
      .pipe(catchError(response => this.handleError(response)));
  }
}
