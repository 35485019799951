<form [formGroup]="form" (ngSubmit)="submitForm(form)">
  <h3 class="modal-title">{{"CASES.single.payments.actions.produce_payment_plan" | translate}}</h3>
  <div class="row">
    <div class="col-12">
      <div class="d-flex">
        <div class="form-group mr-2">
            <app-input type="number" formControlName="monthly_amount" appearance="standard" [label]="'CASES.single.payments.change_payment_plan.monthly_amount' | translate">
            </app-input>
        </div>
        <div class="form-group mr-2">
          <app-input type="number" formControlName="term_duration" appearance="standard" [label]="'CASES.single.payments.change_payment_plan.number_of_installments' | translate"></app-input>
        </div>
        <div class="form-group">
          <app-input type="date" formControlName="start_date" appearance="standard" [label]="'CASES.single.payments.change_payment_plan.date_of_first_installment' | translate"></app-input>
        </div>
      </div>
      <div class="d-flex">
        <div class="form-group">
          <button type="button" mat-stroked-button color="primary" class="mr-3"
                  (click)="closeModal()">{{ "SHARED.close" | translate }}
          </button>
          <app-spinner-btn type="submit" [loading]="isSubmitting"
                           [name]="'SHARED.submit' | translate">
          </app-spinner-btn>
        </div>
      </div>
    </div>
  </div>
</form>
