import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class EntityService extends MainBaseApiService {
  public index(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/case-creditor-entities', {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  // show(id: number) {
  //   return this.http.get<LaravelResourceResponse<Solicitor>>(this.apiUrl + '/solicitors/' + id)
  //     .pipe(catchError(response => this.handleError(response)));
  // }
  //
  // store(data) {
  //   return this.http.post<LaravelResourceResponse<Solicitor>>(this.apiUrl + '/solicitors', data)
  //     .pipe(catchError(response => this.handleError(response)));
  // }
  //
  // update(id: number, data) {
  //   return this.http.put<LaravelResourceResponse<Solicitor>>(this.apiUrl + '/solicitors/' + id, data)
  //     .pipe(catchError(response => this.handleError(response)));
  // }
  //
  // delete(id: number) {
  //   return this.http.delete<LaravelResourceResponse<Solicitor>>(this.apiUrl + '/solicitors/' + id)
  //     .pipe(catchError(response => this.handleError(response)));
  // }
}
