import { Component, OnInit } from '@angular/core';

@Component({
  selector:    'app-trustbox',
  templateUrl: './trustbox.component.html',
  styleUrls:   ['./trustbox.component.scss']
})
export class TrustboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const trustboxRef = document.getElementById('trustbox');
    // @ts-ignore
    window.Trustpilot.loadFromElement(trustboxRef);
  }

}
