<div class="row" *ngIf="type === 'dni'">
  <div class="col-sm-3">
    <app-custom-dropzone-upload
      [uploader]="uploader"
      [uuId]="uuId"
      [type]="type"
      [allFiles]="files"
      side="front-side"
    ></app-custom-dropzone-upload>
  </div>
  <div class="col-sm-3">
    <app-custom-dropzone-upload
      [uploader]="uploader"
      [uuId]="uuId"
      [type]="type"
      [allFiles]="files"
      side="back-side"
    ></app-custom-dropzone-upload>
  </div>
</div>

<div class="row" *ngIf="type !== 'dni'">
  <div class="col-sm-3" *ngFor="let number of noOfFiles">
    <app-custom-dropzone-upload
      [uploader]="uploader"
      [uuId]="uuId"
      [type]="type"
      [file]="file"
      [allFiles]="files"
      [creditors]="creditors"
      [banks]="banks"
    ></app-custom-dropzone-upload>
  </div>
  <div class="col-sm-3">
    <div class="add-file-box" (click)="addFile()">
      + {{"DOCUMENTS.add-new-file" | translate}}
    </div>
  </div>
</div>

