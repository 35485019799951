import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SharedModule } from '../../_shared/shared.module';

import { OneTimeFeeRoutingModule } from './one-time-fee-routing.module';
import { OneTimeFeeEditorComponent } from './one-time-fee-editor/one-time-fee-editor.component';
import { OneTimeFeeListComponent } from './one-time-fee-list/one-time-fee-list.component';


@NgModule({
  declarations: [
    OneTimeFeeEditorComponent,
    OneTimeFeeListComponent
  ],
  imports: [
    SharedModule,
    MatAutocompleteModule,
    OneTimeFeeRoutingModule
  ]
})
export class OneTimeFeeModule { }
