<div class="d-flex flex-column h-100">
  <h2>{{"CASES.details.additional-data" | translate}}</h2>

  <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="formActive">
    <div class="row">
      <div class="col-md-6">
        <div class="col-md-6 col-lg col-xl">
          <!-- Creditor Status -->
          <app-input type="select" formControlName="status_id" appearance="standard" fullWidth="true"
                     [label]="'CASE_CREDITOR.model.response_received.label' | translate" [showClear]="true"
                     [selectOptions]="creditorStatusOptions" [selectLabel]="'name'" [selectValue]="'id'">
          </app-input>
        </div>
      </div>
    </div>
  </form>

  <div class="text-right mt-auto mb-5 d-flex justify-content-end align-items-center">

    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submit()">
      {{"SHARED.submit" | translate}}
    </button>
    <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
  </div>
</div>
