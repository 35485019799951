<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>Merge clients</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div *ngIf="serverResponse" class="col-12">
          <app-server-response [response]="serverResponse"></app-server-response>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col">First name</div>
                <div class="col"> {{ currentUser.first_name }}</div>
              </div>
              <div class="row">
                <div class="col">Last name</div>
                <div class="col"> {{ currentUser.last_name }}</div>
              </div>
              <div class="row">
                <div class="col">Email</div>
                <div class="col"> {{ currentUser.email }}</div>
              </div>
              <div class="row">
                <div class="col">Phone</div>
                <div class="col"> {{ currentUser.phone }}</div>
              </div>
              <div class="row">
                <div class="col">DNI</div>
                <div class="col"> {{ currentUser.id_card }}</div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col">First name</div>
                <div class="col"> {{ existingUser.first_name }}</div>
              </div>
              <div class="row">
                <div class="col">Last name</div>
                <div class="col"> {{ existingUser.last_name }}</div>
              </div>
              <div class="row">
                <div class="col">Email</div>
                <div class="col"> {{ existingUser.email }}</div>
              </div>
              <div class="row">
                <div class="col">Phone</div>
                <div class="col"> {{ existingUser.phone }}</div>
              </div>
              <div class="row">
                <div class="col">DNI</div>
                <div class="col"> {{ existingUser.id_card }}</div>
              </div>
              <div class="text-center">
                <button type="submit" class="mt-2" mat-raised-button color="primary"
                        (click)="confirmClientReplacement()">
                  Use this user
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
