<div class="card shadow mb-4 card-height">
  <div class="card-body">
    <div class="row">
      <!-- Tab Navigation -->
      <div class="col-2">
        <mat-list>
          <mat-list-item *ngFor="let tab of tabs">
            <button class="btn-block mb-2" color="primary" mat-raised-button *ngIf="selectedTab.value === tab.value">
              {{ tab.label }}
            </button>
            <button class="btn-block mb-2" color="primary" mat-stroked-button *ngIf="selectedTab.value !== tab.value"
                    (click)="selectTab(tab.value)">
              {{ tab.label }}
            </button>
          </mat-list-item>
        </mat-list>
      </div>
      <!-- Tab Contents -->
      <div class="col-10" [ngSwitch]="selectedTab.value" style="max-height: 600px; overflow: auto">
        <app-case-notes *ngSwitchCase="'notes'" noteType="note" [case]="case"></app-case-notes>
        <app-case-notes *ngSwitchCase="'payment_notes'" noteType="payment" [case]="case"></app-case-notes>
        <app-case-notes *ngSwitchCase="'draft_notes'" noteType="draft" [case]="case"></app-case-notes>
        <app-case-creditor-note-list *ngSwitchCase="'creditor_notes'" [case]="case"
                                     (caseCreditorsLoaded)="updateCaseCreditors($event)">
        </app-case-creditor-note-list>
        <app-case-notification-list *ngSwitchCase="'email_history'" [case]="case" [channel]="'email'"></app-case-notification-list>
        <app-case-notification-list *ngSwitchCase="'sms_history'" [case]="case" [channel]="'sms'"></app-case-notification-list>
        <app-case-notification-list *ngSwitchCase="'call_history'" [case]="case" [channel]="'call'"></app-case-notification-list>
        <app-case-status-log *ngSwitchCase="'status_logs'" [case]="case"></app-case-status-log>
        <app-case-call-status-log *ngSwitchCase="'call_status_logs'" [case]="case"></app-case-call-status-log>
        <app-case-payment-status-log *ngSwitchCase="'payment_status_logs'" [case]="case"></app-case-payment-status-log>
        <app-case-contract-list *ngSwitchCase="'contracts'" [case]="case"></app-case-contract-list>
        <app-case-proposal-list *ngSwitchCase="'proposals'" [case]="case"></app-case-proposal-list>
       <!-- <app-case-aep-list *ngSwitchCase="'aep_history'" [case]="case"></app-case-aep-list>-->
        <app-case-task-list *ngSwitchCase="'tasks'" [case]="case"></app-case-task-list>
        <app-case-notes *ngSwitchCase="'distribution'" noteType="distribution" [case]="case"></app-case-notes>
      </div>
    </div>
  </div>
</div>
