import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../../_base-shared/models/Status/StatusCategory';
import { StatusService } from '../status.service';

@Component({
  selector:    'app-status-editor',
  templateUrl: './status-editor.component.html',
  styleUrls:   ['./status-editor.component.scss'],
})
export class StatusEditorComponent implements OnInit {
  public isLoading                               = 0;
  public form: UntypedFormGroup;
  public editorType: 'create' | 'edit';
  public status: Status;
  public statusCategories: Array<StatusCategory> = [];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private statusService: StatusService,
    private toastr: ToastrService,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.fetchAllStatuses();
    this.editorType = this.route.snapshot.data.editor;
    if (this.editorType === 'edit') {
      this.fetchStatus(+this.route.snapshot.paramMap.get('id'));
    } else {
      this.status = new Status();
      this.buildForm();
    }
  }

  fetchAllStatuses() {
    this.isLoading++;
    this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.statusCategories = result.data);
  }

  fetchStatus(statusId) {
    this.isLoading++;
    this.statusService.get(statusId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.status = result.data;
      this.buildForm();
    });
  }

  buildForm() {
    this.form = this.fb.group({
      name:                              [this.status.name, Validators.required],
      status_id:                         [this.status.status_id],
      flag_case_active:                  [this.status.flag_case_active, Validators.required],
      allow_affiliate_overwrite_anytime: [
        this.editorType === 'create' ? false : this.status.allow_affiliate_overwrite_anytime, Validators.required],
    });
  }

  onSubmit() {
    if (this.editorType === 'create') {
      this.statusService.store(this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/status');
            this.toastr.success(
              `${ this.translateService.instant('CONFIG.status.status') } ${ this.translateService.instant(
                'SHARED.item-added') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          });
    } else {
      this.statusService.update(this.status.id, this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/status');
            this.toastr.success(
              `${ this.translateService.instant('CONFIG.status.status') } ${ this.translateService.instant(
                'SHARED.item-edited') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          },
        );
    }
  }

}
