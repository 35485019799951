<div *ngIf="isLoading">
  <h1 class="text-center py-5" style="font-size: 30px">{{ landingMessage }}</h1>

  <div class="text-center mt-5">
    <mat-spinner class="m-0 m-auto" diameter="100" color="accent"></mat-spinner>
  </div>
</div>

<div *ngIf="!isLoading && successMessage">
  <h1 class="text-center py-5" style="font-size: 30px">{{ successMessage }}</h1>

  <div class="text-center mt-5">
    <mat-icon class="text-success mat-icon" style="font-size: 100px">check</mat-icon>

  </div>
</div>

<div *ngIf="!isLoading && errorMessage">
  <h1 class="text-center py-5" style="font-size: 30px">{{ errorMessage }}</h1>
  <div class="text-center">
    <button mat-raised-button class="ml-3" type="submit" color="primary" (click)="sendData()">
      {{ "UNSUBSCRIBE.unsubscribe" | translate | uppercase }}
    </button>
  </div>
</div>
