import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Court } from '../../../../../../../../_base-shared/models/Entity/Court';
import { AdministratorsService } from '../../../../address-book/administrators/administrators.service';

@Component({
  selector:    'app-administrator-modal',
  templateUrl: './administrator-modal.component.html',
  styleUrls:   ['./administrator-modal.component.scss'],
})
export class AdministratorModalComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public form: UntypedFormGroup;
  public dataSource: MatTableDataSource<any>;
  public isLoading                  = 0;
  public isSending                  = 0;
  public searchFocus                = false;
  public displayedColumns: string[] = ['select', 'name', 'address'];
  public selection                  = new SelectionModel<any>(true, []);
  private caseCreditorId: number;

  public paginatorConfig = {
    pageIndex: 0,
    pageSize:  6,
    length:    1,
  };

  constructor(private fb: UntypedFormBuilder,
              private administratorsService: AdministratorsService,
              private toast: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<AdministratorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.caseCreditorId = this.data.caseCreditorId;
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchAdministrator();

    this.form.controls.search.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.fetchAdministrator();
      });
  }

  buildForm() {
    this.form = this.fb.group({
      administrator:    ['', Validators.required],
      search:           ['', null],
      case_creditor_id: [this.caseCreditorId],
    });
  }

  public paginatorChange($event: PageEvent) {
    this.dataSource                = new MatTableDataSource<Case>([]);
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchAdministrator();
  }

  fetchAdministrator() {
    this.selection.clear(); //  Remove selections
    this.form.patchValue({administrator: ''}); //  Reset value in form
    this.form.markAsUntouched();  //  Remove form errors
    this.dataSource = new MatTableDataSource<Case>([]); //  Empty data source
    this.isLoading++; //  Start spinner
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.form.value.search,
    };

    this.administratorsService.index(data).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.dataSource             = new MatTableDataSource<Court>(res.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = res.data.total;
    });
  }

  closeModal(value) {
    this.dialogRef.close(value);
  }

  selectAdministrator(row) {
    this.form.patchValue({administrator: row.id});
    this.form.updateValueAndValidity();
    this.selection.clear();
    this.selection.toggle(row);
  }

  onFocus() {
    this.searchFocus = true;
  }

  onBlur() {
    this.searchFocus = false;
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const data = {
      administrator_id: this.form.get('administrator').value,
    };
    this.administratorsService.saveAdministrator(this.data.case.id, this.form.get('administrator').value).subscribe(
      next => {
        this.toast.success(this.translateService.instant('CASES.details.administrator-save-success'));
        this.dialogRef.close(this.selection.selected[0]);
      },
      error => this.toast.error(this.translateService.instant('CASES.details.administrator-save-error')),
    );
  }

}
