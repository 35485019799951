<button mat-raised-button color="primary" (click)="refreshClientView()">Refresh</button>
<div *ngFor="let documentCategory of requestedDocumentTypeCategories">
  <ng-template [ngIf]="documentCategory.document_types?.length">
    <h2 class="text-primary m-0 mt-3"><b>{{ documentCategory.name }}</b></h2>
    <!-- Document Types -->
    <div class="row mb-2 pl-4 justify-content-between align-items-center"
         *ngFor="let documentType of documentCategory.document_types">
      <div class="col">
        <h3>{{ documentType.name }}</h3>
        <small class="text-warning" *ngIf="documentType.expires_after">
          Expires in {{ documentType.expires_after }} {{ documentType.expires_after_unit}}s
        </small>
      </div>
      <div class="col-2" *ngIf="!documentType.child_document_types?.length">
        <input id="singleFile" multiple type="file">
      </div>
      <div class="col-12">
      </div>
    </div>
    <!-- Child Categories -->
    <div class="pl-4" *ngFor="let childCategory of documentCategory.child_categories">
      <ng-template [ngIf]="childCategory.document_types?.length">
        <h4 class="text-primary m-0 mt-3"><b>{{ childCategory.name }}</b></h4>
        <!-- Document Types -->
        <div class="row mb-2 pl-4 justify-content-between align-items-center"
             *ngFor="let documentType of childCategory.document_types">
          <div class="col">
            <h3>{{ documentType.name }}</h3>
            <small class="text-warning" *ngIf="documentType.expires_after">
              Expires in {{ documentType.expires_after }} {{ documentType.expires_after_unit}}s
            </small>
          </div>
          <div class="col-2" *ngIf="!documentType.child_document_types?.length">
            <input multiple type="file">
          </div>
          <div class="col-12">
          </div>
          <div class="col-12 pl-5" *ngIf="documentType.child_document_types?.length">
            <div class="row mb-2 justify-content-between align-items-center"
                 *ngFor="let childDocumentType of documentType.child_document_types">
              <div class="col">
                <h3>{{ childDocumentType.name }}</h3>
              </div>
              <div class="col-2">
                <input multiple type="file">
              </div>
              <div class="col-12">
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
