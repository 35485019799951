import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CaseCreditorService} from '../../../case-creditor.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {Case} from '../../../../../../../../_base-shared/models/Case/Case';
import {finalize} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-court-fee-modal',
  templateUrl: './court-fee-modal.component.html',
  styleUrls: ['./court-fee-modal.component.scss']
})
export class CourtFeeModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public courtFeesOptions = [{name: 'Paid', value: 'paid'}, {name: 'Unpaid', value: 'unpaid'}];
  public isSubmitting: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private caseCreditorService: CaseCreditorService,
    private toastr: ToastrService,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {case: Case, caseCreditors: Array<number>}
  ) {
  }

  ngOnInit(): void {
    this.buildFrom();
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseCreditorService.updateCourtFees(this.data.case.id, this.form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        this.toastr.success(this.translateService.instant('SHARED.success'));
      }, err => {
        this.toastr.error(this.translateService.instant('SHARED.error'));
      });
  }

  private buildFrom(): void {
    this.form       = this.fb.group({
      court_fees:         [null, Validators.required],
      court_fees_status:  [null, Validators.required],
      case_creditor_ids:  [this.data.caseCreditors]
    });
  }
}
