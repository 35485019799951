import { AppFile } from '../File/AppFile';

export class NotificationChannelTemplate {
  id?: number;
  template_type: 'email_template' | 'sms_template';
  fromable_type: 'user' | 'department';
  fromable_id?: number;
  label?: string;
  description?: string;
  subject?: string;
  content: string;
  active?: boolean | 0 | 1;
  created_at: string | Date;
  updated_at: string | Date;

  attachments?: Array<AppFile>;
}
