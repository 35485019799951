import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { ConfigurationPackager } from '../../../../_base-shared/models/Packager/ConfigurationPackager';
import { Packager } from '../../../../_base-shared/models/Packager/Packager';
import { PackagerStatus } from '../../../../_base-shared/models/Status/PackagerStatus';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PackagerService extends MainBaseApiService {
  public showMaster(relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<Packager>>(this.apiUrl + '/packager/packagers/master', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public show(modelId: number, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<Packager>>(this.apiUrl + '/packager/packagers/' + modelId, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexConfigurations(packagerId: number, request: any) {
    const params = MainBaseApiService.convertFiltersForRequest(request, 'get');

    return this.http.get<LaravelResourceResponse<Array<ConfigurationPackager>>>(
      this.apiUrl + '/packager/packagers/' + packagerId + '/configurations', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public upsertConfigurations(packagerId: number, request: any) {
    return this.http.put<LaravelResourceResponse<Array<ConfigurationPackager>>>(
      this.apiUrl + '/packager/packagers/' + packagerId + '/configurations', request
    ).pipe(catchError(response => this.handleError(response)));
  }

  public uploadImage(data, packagerId: number): Observable<LaravelResourceResponse<Packager>> {
    return this.http.post<LaravelResourceResponse<Packager>>(this.apiUrl + '/packager/packagers/' + packagerId + '/upload-image', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public requestTransfer(caseUuid: string): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/packager/cases/' + caseUuid + '/transfer/request', {}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getPackagerSnapshotData(caseUuid: string): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse<any>>(
      this.apiUrl + '/packager/cases/' + caseUuid + '/case-snapshot')
      .pipe(catchError(response => this.handleError(response)));
  }

  public getPackagerStatuses(): Observable<LaravelResourceResponse<Array<PackagerStatus>>> {
    return this.http.get<LaravelResourceResponse<Array<PackagerStatus>>>(
      this.apiUrl + '/packager-statuses')
      .pipe(catchError(response => this.handleError(response)));
  }
}
