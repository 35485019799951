import { Observable } from 'rxjs';
import { CaseCreditorStatus } from '../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Status } from '../../../../../_base-shared/models/Status/Status';

@Injectable({
  providedIn: 'root',
})

export class CaseCreditorStatusService extends MainBaseApiService {
  index(data: any): Observable<LaravelResourceResponse<Array<CaseCreditorStatus>>> {
    return this.http.get<LaravelResourceResponse<Array<CaseCreditorStatus>>>(`${ this.apiUrl }/case-creditor-statuses`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  get(callStatusId: number): Observable<LaravelResourceResponse<CaseCreditorStatus>>{
    return this.http.get<LaravelResourceResponse<CaseCreditorStatus>>(`${ this.apiUrl }/case-creditor-statuses/${ callStatusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(status: Status): Observable<LaravelResourceResponse<CaseCreditorStatus>> {
    return this.http.post<LaravelResourceResponse<CaseCreditorStatus>>(`${ this.apiUrl }/case-creditor-statuses`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(statusId: number, status: Status): Observable<LaravelResourceResponse<CaseCreditorStatus>> {
    return this.http.patch<LaravelResourceResponse<CaseCreditorStatus>>(`${ this.apiUrl }/case-creditor-statuses/${ statusId }`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/case-creditor-statuses/${ statusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }
}
