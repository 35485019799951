import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MainGlobalEventService } from '../services/main-global-event.service';

@Injectable()
export class MainPermissionGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(public router: Router,
              private globalEventsService: MainGlobalEventService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('canActivate');
    // return this.check(route);
    return true;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('canActivateChild');
    return this.check(childRoute);
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    // console.log('canLoad');
    return this.check(route);
  }

  private check(route): Observable<any> | Promise<boolean> | boolean {
    return this.globalEventsService.authUser$.pipe(
      filter(result => (result !== null)),  // If null, ignore it
      map(res => this.checkPermission(res, route), take(1)),
    );
  }

  private checkPermission(user, route) {
    if (!user) {
      return false;
    }

    // If admin - allow all
    if (user.role_id === 1 || user.role_id === 5) {
      return true;
    }

    if (user.role_id === 2) {
      if (!route.data?.allowManager && history.state?.redirect) {
        this.router.navigate(['/']);
      }
      return !!route.data?.allowManager;
    }

    if (user.role_id === 3) {
      if (!route.data?.allowAgent && history.state?.redirect) {
        this.router.navigate(['/']);
      }
      return !!route.data?.allowAgent;
    }

    if (history.state?.redirect) {
      this.router.navigate(['/']);
    }
    return false;
  }

}
