import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { ScheduledTaskNotification } from '../../../../../_base-shared/models/Task/ScheduledTaskNotification';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduledTaskNotificationService extends MainBaseApiService {
  private notifications$      = new BehaviorSubject<Array<ScheduledTaskNotification>>([]);
  private totalRecords$       = new BehaviorSubject<number>(0);
  private unreadCount$        = new BehaviorSubject<number>(0);
  private unreadCountInternal = 0;

  constructor(public router: Router,
              public http: HttpClient,
              public globalEvents: MainGlobalEventService) {
    super(http, router, globalEvents);
  }

  get notifications(): Observable<Array<ScheduledTaskNotification>> {
    return this.notifications$.asObservable();
  }

  get totalRecords(): Observable<number> {
    return this.totalRecords$.asObservable();
  }

  get unreadCount(): Observable<number> {
    return this.unreadCount$.asObservable();
  }

  public loadNotifications(userId: number, filter = {}): Observable<Array<ScheduledTaskNotification>> {
    return this.index(userId, filter).pipe(
        take(1),
        tap(result => {
          this.totalRecords$.next(10); // TODO:
          this.unreadCountInternal = 10; // TODO:
          this.unreadCount$.next(this.unreadCountInternal);
        }),
        map(result => result.data),
        tap(notifications => this.notifications$.next(notifications)),
    );
  }

  public addLocalNotification(notification) {
    if (!notification.read_at) {
      this.unreadCount$.next(++this.unreadCountInternal);
    }
    return this.notifications.pipe(
        take(1),
        tap(notifications => this.notifications$.next([notification].concat(notifications))),
    );
  }

  public updateLocalNotifications(updatedNotifications: Array<ScheduledTaskNotification>) {
    // return this.updateNotifications(updatedNotifications);
  }

  public removeLocalNotifications(removedNotifications: Array<ScheduledTaskNotification>) {
    // return this.updateNotifications(removedNotifications, true);
  }

  // private updateNotifications(updatedNotifications, deleted = false) {
  //   return this.notifications.pipe(
  //       take(1),
  //       tap(notifications => {
  //         const newNotificationSet     = [...notifications];
  //         const oldUnreadCountInternal = this.unreadCountInternal;
  //
  //         for (const updatedNotification of updatedNotifications) {
  //           const updatedNotificationIndex = notifications.findIndex(n => n.id === updatedNotification.id);
  //           if (updatedNotificationIndex === -1) {
  //             continue;
  //           }
  //           const oldNotification = notifications[updatedNotificationIndex];
  //           if (deleted) {
  //             newNotificationSet.slice(updatedNotificationIndex, 1);
  //           } else {
  //             newNotificationSet[updatedNotificationIndex] = updatedNotification;
  //           }
  //           if (oldNotification.read_at && !updatedNotification.read_at) {
  //             this.unreadCountInternal++;
  //           }
  //           if (!oldNotification.read_at && updatedNotification.read_at) {
  //             this.unreadCountInternal--;
  //           }
  //         }
  //
  //         this.notifications$.next(newNotificationSet);
  //         if (oldUnreadCountInternal !== this.unreadCountInternal) {
  //           this.unreadCount$.next(this.unreadCountInternal);
  //         }
  //       }),
  //   );
  // }

  /**
   * Display a listing of notifications.
   */
  public index(userId: number, filters: any = {}, include: Array<string> = [], count: Array<string> = [])
    : Observable<LaravelResourceResponse<Array<ScheduledTaskNotification>>> {
    return this.http.get<LaravelResourceResponse<Array<ScheduledTaskNotification>>>(
        this.apiUrl + '/users/' + userId + '/scheduled-task-notifications')
        .pipe(catchError(response => this.handleError(response)));
  }

  /**
   * Mark given notifications as read.
   */
  public snooze(userId: number, ids: Array<number>, duration: number)
    : Observable<LaravelResourceResponse<Array<ScheduledTaskNotification>>> {
    return this.http.post<LaravelResourceResponse<Array<ScheduledTaskNotification>>>(
        this.apiUrl + '/users/' + userId + '/scheduled-task-notifications/snooze', {ids, duration})
        .pipe(catchError(response => this.handleError(response)));
  }

  public markAsCompleted(userId: number, ids: Array<number>): Observable<LaravelResourceResponse<Array<ScheduledTaskNotification>>> {
    return this.http.post<LaravelResourceResponse<Array<ScheduledTaskNotification>>>(
        this.apiUrl + '/users/' + userId + '/scheduled-task-notifications/mark-as-completed', {ids})
        .pipe(catchError(response => this.handleError(response)));
  }

  public markAsUncompleted(userId: number, ids: Array<number>): Observable<LaravelResourceResponse<Array<ScheduledTaskNotification>>> {
    return this.http.post<LaravelResourceResponse<Array<ScheduledTaskNotification>>>(
        this.apiUrl + '/users/' + userId + '/scheduled-task-notifications/mark-as-uncompleted', {ids})
        .pipe(catchError(response => this.handleError(response)));
  }

}
