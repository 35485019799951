import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { UserService } from '../../../../user/user.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-user-merge',
  templateUrl: './case-user-merge.component.html',
  styles:      [],
})
export class CaseUserMergeComponent implements OnInit {
  public case: Case;
  public currentUser: User;
  public existingUser: User;
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;
  public clientCaseRole: 'client' | 'partner';

  constructor(public dialogRef: MatDialogRef<CaseUserMergeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private toastr: ToastrService,
              private translate: TranslateService,
              private userService: UserService,
              private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.case           = this.data.case;
    this.clientCaseRole = this.data.clientCaseRole;
    this.currentUser    = this.data.currentUser;
    this.existingUser   = this.data.existingUser;
  }

  public confirmClientReplacement() {
    Swal.fire({
      text:               'Are you sure you wish to replace client data? This action cannot be reversed.',
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonText:  'OK',
      confirmButtonColor: '#4267b2',
      customClass:        {container: 'z-index-4000'},
    }).then(res => {
      if (res.isConfirmed) {
        this.replaceCaseClient();
      }
    });
  }

  private replaceCaseClient() {
    this.isLoading++;
    this.caseService.replaceCaseClient(this.case.id, this.clientCaseRole, this.existingUser.id)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.case = result.data;
        this.toastr.success('Case user replaced');
        this.dialogRef.close({data: this.case, dismiss: false});
      }, () => this.toastr.error(this.translate.instant('SHARED.went-wrong')));
  }
}
