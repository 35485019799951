<form [formGroup]="form" (ngSubmit)="submitForm(form)">
  <h4 class="modal-title">{{'CASES.single.payments.change_payment_status.heading' | translate}}</h4>
  <div class="form-group">
    <div class="pb-3">
      <app-input type="select" formControlName="case_creditor_payment_status_id" appearance="standard" [searchable]="true"
                 [label]="'CASES.single.general.status_editor.payment_status' | translate"
                 [selectOptions]="creditorPaymentStatuses" [selectLabel]="['name']" [selectValue]="'id'"
                 class="full-width" [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
      </app-input>
    </div>
    </div>
  <div class="modal-footer">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeModal()">{{ "SHARED.close" | translate }}</button>
    <app-spinner-btn type="submit" [loading]="isSubmitting"
                     [name]="'SHARED.submit' | translate">
    </app-spinner-btn>
  </div>
</form>
