import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DateTime} from 'luxon';
import {DashboardService} from '../dashboard.service';
import {debounceTime, distinctUntilChanged, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-claims-dashboard',
  templateUrl: './claims-dashboard.component.html',
  styleUrls: ['./claims-dashboard.component.scss']
})
export class ClaimsDashboardComponent implements OnInit {
  public isLoading = 0;
  public form: UntypedFormGroup;
  public claimsData;
  public displayedColumns: string [] = [
    'ppi_claims_sent',
    'ppi_claims_accepted',
    'value_of_ppi_accepted',
    'value_of_ppi_in_default',
    'ppi_claims_older_60_days',
    'ppi_demands_submitted',
    'ppi_court_wins',
    'right_of_access_older_30_days'
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private dashboardService: DashboardService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getClaimsData();
    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.getClaimsData();
      });
  }

  buildForm(): void {
    this.form = this.fb.group({
      from:      [new Date()],
      to:        [new Date()],
      dateRadio: ['today'],
    });
  }
  clearDatePicker($event, name): void {
    this.form.patchValue({[name]: ''});
  }
  onDateChange($event, type): void {
    if (type === 'from') {
      this.form.patchValue({
        from: new Date($event.value),
      });
    }
    if (type === 'to') {
      this.form.patchValue({
        to: new Date($event.value),
      });
    }
  }

  onRadioChange($event): void {
    if ($event.value === 'today') {
      this.form.patchValue({
        from: new Date(),
        to:   new Date(),
      });
    }
    if ($event.value === 'this-week') {
      const startOfWeek = DateTime.local().startOf('week').toISO();
      const endOfWeek   = DateTime.local().endOf('week').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
    if ($event.value === 'this-month') {
      const startOfWeek = DateTime.local().startOf('month').toISO();
      const endOfWeek   = DateTime.local().endOf('month').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
  }

  getClaimsData(): void {
    this.isLoading++;

    const dateRange = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date:   this.form.value.to ? this.form.value.to.toDateString() : '',
    };

    this.dashboardService.getClaimsData(dateRange)
      .pipe(finalize(() => (this.isLoading--)))
      .subscribe(res => {
        this.claimsData = res.data;
      });
  }
}
