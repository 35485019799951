import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DistributionBatch } from '../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { DistributionBatchService } from '../distribution-batch.service';

@Component({
  selector:    'app-batch-selector',
  templateUrl: './batch-selector.component.html',
  styles:      [],
})
export class BatchSelectorComponent implements OnInit, OnDestroy {
  public distributionBatches: Array<DistributionBatch> = [];
  public isLoading                                     = 0;
  public batchSelector: UntypedFormControl                    = new UntypedFormControl(null);

  private subscriptions: Array<Subscription> = [];

  constructor(
      private toastr: ToastrService,
      private translate: TranslateService,
      public dialogRef: MatDialogRef<BatchSelectorComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private distributionBatchService: DistributionBatchService,
  ) {
  }

  ngOnInit(): void {
    this.fetchDistributionBatches();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchDistributionBatches() {
    this.isLoading++;
    this.subscriptions.push(
        this.distributionBatchService.index({statuses: ['pending', 'rejected']}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.distributionBatches = result.data),
    );
  }

  public selectBatch(selectedBatchId: number) {
    const selectedBatch = this.distributionBatches.find(batch => batch.id === selectedBatchId);
    this.dialogRef.close({dismissed: false, data: {distribution_batch: selectedBatch}});
  }
}
