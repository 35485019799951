import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CaseVariable } from '../../../../../../_base-shared/models/Case/Case';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { NotificationChannel } from '../../../../../../_base-shared/models/Notification/NotificationChannel';
import { CallStatus } from '../../../../../../_base-shared/models/Status/CallStatus';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { StatusCategory } from '../../../../../../_base-shared/models/Status/StatusCategory';
import { TaskTemplate } from '../../../../../../_base-shared/models/Task/TaskTemplate';
import { TemplateNotification } from '../../../../../../_base-shared/models/Task/TemplateNotification';
import { User } from '../../../../../../_base-shared/models/User/User';
import { PaymentStatusService } from '../../payment-status/payment-status.service';
import { UploadService } from '../../app-file/upload.service';
import { CallStatusService } from '../../call-status/call-status.service';
import { CaseService } from '../../case/case.service';
import { StatusService } from '../../status/status.service';
import { DepartmentService } from '../../department/department.service';
import { NotificationService } from '../../notification/notification.service';
import { UserService } from '../../user/user.service';
import { TaskTemplateService } from '../task-template.service';

@Component({
  selector:    'app-task-template-editor',
  templateUrl: './task-template-editor.component.html',
  styleUrls:   ['./task-template-editor.component.scss'],
})
export class TaskTemplateEditorComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public isLoading                               = 0;
  public isSubmitting: boolean;
  public editorType: 'create' | 'edit'           = 'create';
  public taskTemplateId: number;
  public taskTemplate: TaskTemplate;
  public delayOptions: Array<{ label: any; value: string }>;
  public notifyOnOptions: Array<{ label: any; value: string }>;
  public statusableTypes: Array<{ label: any; value: string }>;
  public statusCategories: Array<StatusCategory> = [];
  public paymentStatuses: Array<PaymentStatus>   = [];
  public callStatuses: Array<CallStatus>                 = [];
  public users: Array<User>;
  public departmentCategories: Array<DepartmentCategory>;
  public notificationChannels: Array<NotificationChannel>;
  public templateVariables: Array<CaseVariable>;
  public quillModules                            = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };
  private subscriptions: Array<Subscription>     = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              public translateService: TranslateService,
              private uploadService: UploadService,
              private statusService: StatusService,
              private paymentStatusService: PaymentStatusService,
              private userService: UserService,
              private departmentService: DepartmentService,
              private taskTemplateService: TaskTemplateService,
              private caseService: CaseService,
              private notificationService: NotificationService,
              private callStatusService: CallStatusService) {
  }

  get templateNotificationsArray() {
    return this.form.get('template_notifications') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.type;
    this.buildDelayOptions();
    this.buildNotifyOnOptions();
    this.buildStatusableTypeOptions();
    this.fetchStatuses();
    this.fetchAssignables();
    this.fetchNotificationChannels();
    this.fetchVariables();
    this.fetchCallStatuses();
    if (this.editorType === 'edit') {
      this.subscriptions.push(this.route.params.subscribe(
          (param: any) => {
            this.taskTemplateId = +param.id;
            this.fetchTemplate(this.taskTemplateId);
          },
      ));
    } else {
      this.taskTemplate = this.setDefaultTemplateProperties(new TaskTemplate());
      this.buildForm(this.taskTemplate);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public addTemplateNotification($event) {
    $event.preventDefault();
    const formNotifications = this.form.get('template_notifications') as UntypedFormArray;
    formNotifications.push(this.initTemplateNotification(new TemplateNotification()));
  }

  public submitForm(taskTemplate) {
    this.formSubmitted = true;
    if (this.form.invalid) {
      console.error('Invalid task template form.');
      return false;
    }

    this.isSubmitting = true;

    if (this.editorType === 'create') {
      return this.taskTemplateService.store(taskTemplate).pipe(finalize(() => this.isSubmitting = false)).subscribe(
          () => this.handleFormResult(this.translateService.instant('TASK-TEMPLATE.editor.success-create')),
          error => this.handleFormError(error),
      );
    }

    if (this.editorType === 'edit') {
      return this.taskTemplateService.update(this.taskTemplateId, taskTemplate)
          .pipe(finalize(() => this.isSubmitting = false))
          .subscribe(
              () => this.handleFormResult(this.translateService.instant('TASK-TEMPLATE.editor.success-edit')),
              error => this.handleFormError(error),
          );
    }
  }

  public removeTemplateNotification(index: number) {
    this.templateNotificationsArray.removeAt(index);
  }

  public notifyOnChanged(index: number) {
    if (this.templateNotificationsArray.at(index).get('notify_on').value === 'assignment') {
      this.templateNotificationsArray.at(index).get('delay').setValue(0);
      this.templateNotificationsArray.at(index).get('delay_unit').setValue('second');
    }
    this.templateNotificationsArray.at(index).get('delay').updateValueAndValidity();
    this.templateNotificationsArray.at(index).get('delay_unit').updateValueAndValidity();
  }

  public statusableTypeChanged(newStatusableType: 'status' | 'payment_status' | 'call_status') {
    this.form.get('statusable_id').setValue(null);
    this.form.get('statusable_id').updateValueAndValidity();
  }

  public notificationChannelChanged(index: number) {

  }

  public assignableChanged(assignableType: 'user' | 'department') {
    if (!this.form.get('assigned_users_ids').value || !this.form.get('assigned_users_ids').value.length) {
      this.form.get('assigned_departments_ids').setValidators([Validators.required]);
    } else {
      this.form.get('assigned_departments_ids').setValidators([]);
    }
    if (!this.form.get('assigned_departments_ids').value || !this.form.get('assigned_departments_ids').value.length) {
      this.form.get('assigned_users_ids').setValidators([Validators.required]);
    } else {
      this.form.get('assigned_users_ids').setValidators([]);
    }
    this.form.get('assigned_users_ids').updateValueAndValidity();
    this.form.get('assigned_departments_ids').updateValueAndValidity();
  }

  private setDefaultTemplateProperties(taskTemplate: TaskTemplate): TaskTemplate {
    taskTemplate.statusable_type         = 'status';
    taskTemplate.delay                   = 2;
    taskTemplate.delay_unit              = 'week';
    taskTemplate.active                  = true;
    taskTemplate.user_assisted           = false;
    taskTemplate.force_task_creation     = false;
    taskTemplate.require_completion_note = false;

    return taskTemplate;
  }

  private buildDelayOptions() {
    this.delayOptions = [
      {
        value: 'minute',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.minute'),
      },
      {
        value: 'hour',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.hour'),
      },
      {
        value: 'day',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translateService.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ]
    ;
  }

  private buildNotifyOnOptions() {
    this.notifyOnOptions = [
      {
        value: 'assignment',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.task-template-notification.notify_on.options.assignment',
        ),
      },
      {
        value: 'before_due_date',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.task-template-notification.notify_on.options.before_due_date',
        ),
      },
      {
        value: 'after_due_date',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.task-template-notification.notify_on.options.after_due_date',
        ),
      },
    ];
  }

  private buildStatusableTypeOptions() {
    this.statusableTypes = [
      {
        value: 'status',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.statusable_type.options.status',
        ),
      },
      {
        value: 'payment_status',
        label: this.translateService.instant(
            'TASK-TEMPLATE.editor.statusable_type.options.payment_status',
        ),
      },
      {
        value: 'call_status',
        label: this.translateService.instant(
          'CONFIG.drip-campaign.list.table-data.statusable_type.call_status',
        ),
      },
    ];
  }

  private fetchStatuses() {
    this.isLoading++;
    this.subscriptions.push(
        this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.statusCategories = result.data),
    );
    this.isLoading++;
    this.subscriptions.push(
        this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.paymentStatuses = result.data),
    );
  }

  private fetchTemplate(id: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.taskTemplateService.show(id).pipe(finalize(() => this.isLoading--)).subscribe(
            result => {
              this.taskTemplate = result.data;
              this.buildForm(this.taskTemplate);
            },
            error => console.error(error),
        ),
    );
  }

  private fetchAssignables() {
    this.isLoading++;
    this.subscriptions.push(
        this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.users = result.data),
    );
    this.isLoading++;
    this.subscriptions.push(
        this.departmentService.categoryIndex(['departments']).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.departmentCategories = result.data),
    );
  }

  private fetchNotificationChannels() {
    this.isLoading++;
    this.subscriptions.push(
        this.notificationService.indexChannels().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.notificationChannels = result.data),
    );
  }

  private fetchVariables() {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.indexCaseVariables().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.templateVariables = result.data),
    );
  }

  private buildForm(taskTemplate: TaskTemplate) {
    this.form = this.fb.group({
      case_ref_number:          [taskTemplate.watch_case?.ref_number],
      statusable_type:          [taskTemplate.statusable_type, [Validators.required]],
      statusable_id:            [taskTemplate.statusable_id, [Validators.required]],
      assigned_users_ids:       [
        taskTemplate.assigned_users ? taskTemplate.assigned_users.map(user => user.id) : null,
        !taskTemplate.assigned_departments ? [Validators.required] : [],
      ],
      assigned_departments_ids: [
        taskTemplate.assigned_departments ?
            taskTemplate.assigned_departments.map(department => department.id) :
            null,
        !taskTemplate.assigned_users ? [Validators.required] : [],
      ],
      name:                     [taskTemplate.name, [Validators.required]],
      notes:                    [taskTemplate.notes],
      active:                   [!!taskTemplate.active, [Validators.required]],
      user_assisted:            [!!taskTemplate.user_assisted, [Validators.required]],
      force_task_creation:      [!!taskTemplate.force_task_creation, [Validators.required]],
      require_completion_note:  [!!taskTemplate.require_completion_note, [Validators.required]],
      notify_on_completion_via: [taskTemplate.notify_on_completion_via],
      delay:                    [taskTemplate.delay, Validators.required],
      delay_unit:               [taskTemplate.delay_unit, Validators.required],
      template_notifications:   this.fb.array([]),
    });

    const formNotifications = this.form.get('template_notifications') as UntypedFormArray;
    if (taskTemplate.template_notifications && taskTemplate.template_notifications.length) {
      taskTemplate.template_notifications.forEach(templateNotification => {
        formNotifications.push(this.initTemplateNotification(templateNotification));
      });
    } else {
      const initialNotification      = new TemplateNotification();
      initialNotification.delay      = 10;
      initialNotification.delay_unit = 'minute';
      formNotifications.push(this.initTemplateNotification(initialNotification));
    }
  }

  private initTemplateNotification(templateNotification: TemplateNotification) {
    return this.fb.group({
      id:         [templateNotification.id ? templateNotification.id : null],
      channel:    [
        templateNotification.channel ? templateNotification.channel : 'toast',
        Validators.required,
      ],
      notify_on:  [
        templateNotification.notify_on ? templateNotification.notify_on : 'before_due_date',
        Validators.required,
      ],
      delay:      [templateNotification.delay, Validators.required],
      delay_unit: [templateNotification.delay_unit ? templateNotification.delay_unit : 'minute', Validators.required],
    });
  }

  private handleFormResult(message: string) {
    this.toastr.success(message);
    this.router.navigate(['/task-templates']);
  }

  private handleFormError(error: any) {
    console.error(error);
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public addVariable($event) {
    const content = this.form.get('notes').value || '';
    this.form.get('notes').setValue(content + $event.target.innerText + ' ');
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.subscriptions.push(this.callStatusService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.callStatuses = result.data;
      })
    );
  }
}
