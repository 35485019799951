import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { CaseVariable } from '../../../../../../../_base-shared/models/Case/Case';
import { DepartmentCategory } from '../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { AppFile, PickedFile } from '../../../../../../../_base-shared/models/File/AppFile';
import { DripCampaign } from '../../../../../../../_base-shared/models/Notification/DripCampaign';
import { DripNotification } from '../../../../../../../_base-shared/models/Notification/DripNotification';
import { NotificationChannel } from '../../../../../../../_base-shared/models/Notification/NotificationChannel';
import { Packager } from '../../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { CaseCreditorProduct } from '../../../../../../../_base-shared/models/Product/CaseCreditorProduct';
import { CallStatus } from '../../../../../../../_base-shared/models/Status/CallStatus';
import { CaseCreditorInvoiceStatus } from '../../../../../../../_base-shared/models/Status/CaseCreditorInvoiceStatus';
import { CaseCreditorPaymentStatus } from '../../../../../../../_base-shared/models/Status/CaseCreditorPaymentStatus';
import { CaseCreditorStatus } from '../../../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../../../_base-shared/models/Status/StatusCategory';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../../_shared/services/main-base-api.service';
import { AdminPackagerService } from '../../../admin-packager/admin-packager.service';
import { CallStatusService } from '../../../call-status/call-status.service';
import { CaseCreditorService } from '../../../case/case-creditor.service';
import { CaseService } from '../../../case/case.service';
import { ProductService } from '../../../case/product.service';
import { DepartmentService } from '../../../department/department.service';
import { NotificationService } from '../../../notification/notification.service';
import { PaymentStatusService } from '../../../payment-status/payment-status.service';
import { StatusService } from '../../../status/status.service';
import { UserService } from '../../../user/user.service';
import { DripCampaignService } from '../drip-campaign.service';

interface PickedKeyFile {
  [key: number]: Array<PickedFile>;
}

@Component({
  selector: 'app-drip-campaign-editor',
  templateUrl: './drip-campaign-editor.component.html',
  styles: [],
})
export class DripCampaignEditorComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public isLoading                                                     = 0;
  public isSubmitting: boolean;
  public editorType: 'create' | 'edit'                                 = 'create';
  public dripCampaignId: number;
  public dripCampaign: DripCampaign;
  public statusableTypes: Array<AppSelectOption>;
  public fromableTypes: Array<AppSelectOption>;
  public delayOptions: Array<AppSelectOption>;
  public pickedFiles: Array<any>                                       = []; // Array<[key: number]: Array<PickedFile>?
  public statusCategories: Array<StatusCategory>                       = [];
  public statuses: Array<Status>                                       = [];
  public paymentStatuses: Array<PaymentStatus>                         = [];
  public callStatuses: Array<CallStatus>                               = [];
  public caseCreditorStatuses: Array<CaseCreditorStatus>               = [];
  public caseCreditorPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];
  public invoiceStatuses: Array<CaseCreditorInvoiceStatus>             = [];
  public notificationChannels: Array<NotificationChannel>              = [];
  public users: Array<User>                                            = [];
  public departmentCategories: Array<DepartmentCategory>               = [];
  public templateVariables: Array<CaseVariable>                        = [];
  public products: Array<Product>                                      = [];
  public caseCreditorProducts: Array<CaseCreditorProduct>              = [];
  public packagers: Array<Packager>                                    = [];

  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService,
              private statusService: StatusService,
              private paymentStatusService: PaymentStatusService,
              private userService: UserService,
              private departmentService: DepartmentService,
              private dripCampaignService: DripCampaignService,
              private caseService: CaseService,
              private notificationService: NotificationService,
              private productService: ProductService,
              private callStatusService: CallStatusService,
              private caseCreditorService: CaseCreditorService,
              private packagerService: AdminPackagerService) {
  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.type;
    this.buildDelayOptions();
    this.buildStatusableTypeOptions();
    this.buildFromableTypes();
    this.fetchStatuses();
    this.fetchNotificationChannels();
    this.fetchFromables();
    this.fetchVariables();
    this.fetchProducts();
    this.fetchCaseCreditorProducts();
    this.fetchPackagers();
    if (this.editorType === 'edit') {
      this.subscriptions.push(this.route.params.subscribe(
        (param: any) => {
          this.dripCampaignId = +param.id;
          this.fetchCampaign(this.dripCampaignId);
        },
      ));
    } else {
      this.dripCampaign = new DripCampaign();
      this.buildForm(this.dripCampaign);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private buildDelayOptions() {
    this.delayOptions = [
      {
        value: 'minute',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.minute'),
      },
      {
        value: 'hour',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.hour'),
      },
      {
        value: 'day',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ]
    ;
  }

  private buildStatusableTypeOptions() {
    this.statusableTypes = [
      {
        value: 'status',
        label: this.translate.instant(
          'TASK-TEMPLATE.editor.statusable_type.options.status',
        ),
      },
      {
        value: 'payment_status',
        label: this.translate.instant(
          'TASK-TEMPLATE.editor.statusable_type.options.payment_status',
        ),
      },
      {
        value: 'call_status',
        label: this.translate.instant('CONFIG.drip-campaign.list.table-data.statusable_type.call_status'),
      },
      {
        value: 'case_creditor_status',
        label: this.translate.instant('CASE_CREDITOR.case_creditor_status.model_name.singular'),
      },
      {
        value: 'case_creditor_payment_status',
        label: this.translate.instant(
          'CONFIG.drip-campaign.list.table-data.statusable_type.case_creditor_payment_status',
        ),
      },
      {
        value: 'case_creditor_invoice_status',
        label: this.translate.instant('CASE_CREDITOR.invoice_status.model_name.singular'),
      },
    ];
  }

  private buildFromableTypes() {
    this.fromableTypes = [
      {
        value: '',
        label: this.translate.instant(
          'CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.none',
        ),
      },
      {
        value: 'user',
        label: this.translate.instant(
          'CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.user',
        ),
      },
      {
        value: 'department',
        label: this.translate.instant(
          'CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.department',
        ),
      },
    ];
  }

  private fetchStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.statusCategories = result.data),
    );
    this.isLoading++;
    this.subscriptions.push(
      this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.paymentStatuses = result.data),
    );

    this.isLoading++;
    this.subscriptions.push(
      this.callStatusService.index().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.callStatuses = result.data),
    );

    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.getCaseCreditorStatuses({select_all: 1}, [], ['name'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseCreditorStatuses = result.data),
    );

    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.getCaseCreditorPaymentStatuses({select_all: 1}, [], ['name'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseCreditorPaymentStatuses = result.data),
    );

    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.getCaseCreditorInvoiceStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.invoiceStatuses = result.data),
    );
  }

  private fetchNotificationChannels() {
    this.isLoading++;
    this.subscriptions.push(
      this.notificationService.indexChannels().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.notificationChannels = result.data),
    );
  }

  private fetchFromables() {
    this.isLoading++;
    this.subscriptions.push(
      this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.users = result.data),
    );
    this.isLoading++;
    this.subscriptions.push(
      this.departmentService.categoryIndex(['departments']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.departmentCategories = result.data),
    );
  }

  private fetchVariables() {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.indexCaseVariables().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.templateVariables = result.data),
    );
  }

  private fetchCampaign(id: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.dripCampaignService.show(id, ['case_creditor_products']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.dripCampaign = result.data;
          this.buildForm(this.dripCampaign);
        },
        error => console.error(error),
      ),
    );
  }

  private buildForm(dripCampaign: DripCampaign) {
    const productIds         = [];
    const creditorProductIds = [];

    dripCampaign?.products?.forEach(product => {
      productIds.push(product.id);
    });
    dripCampaign?.case_creditor_products?.forEach(cProduct => {
      creditorProductIds.push(cProduct.id);
    });

    this.form = this.fb.group({
      packager_id: [dripCampaign.packager_id, [Validators.required]],
      product_ids: [productIds, [Validators.required]],
      case_creditor_product_ids: [creditorProductIds],
      statusable_type: [
        dripCampaign.statusable_type ? dripCampaign.statusable_type : 'status',
        [Validators.required],
      ],
      statusable_id: [dripCampaign.statusable_id, [Validators.required]],
      name: [dripCampaign.name, [Validators.required]],
      active: [!!dripCampaign.active, [Validators.required]],
      allow_on_weekend: [!!dripCampaign.allow_on_weekend, [Validators.required]],
      drip_notifications: this.fb.array([]),
    });

    const formNotifications = this.form.get('drip_notifications') as UntypedFormArray;
    if (dripCampaign.drip_notifications && dripCampaign.drip_notifications.length) {
      dripCampaign.drip_notifications.forEach(dripNotification => {
        formNotifications.push(this.initDripNotification(dripNotification));
      });
    } else {
      formNotifications.push(this.initDripNotification(new DripNotification()));
    }
  }

  public addDripNotification($event) {
    $event.preventDefault();
    const formNotifications = this.form.get('drip_notifications') as UntypedFormArray;
    formNotifications.push(this.initDripNotification(null));
  }

  private initDripNotification(dripNotification: DripNotification = null) {
    if (!dripNotification) {
      dripNotification            = new DripNotification();
      dripNotification.channel    = 'email';
      dripNotification.delay_unit = 'minute';
    }

    const dripNotificationGroup = this.fb.group({
      id: [dripNotification.id ? dripNotification.id : null],
      fromable_type: [dripNotification.fromable_type],
      fromable_id: [dripNotification.fromable_id],
      subject: [dripNotification.subject, dripNotification.channel === 'sms' ? [] : [Validators.required]],
      body: [dripNotification.body, Validators.required],
      attachments: this.fb.array([]),
      channel: [dripNotification.channel, Validators.required],
      delay: [dripNotification.delay, Validators.required],
      delay_unit: [dripNotification.delay_unit, Validators.required],
      bcc: [dripNotification.bcc ? dripNotification.bcc.join(', ') : null],
      sendgrid_template_id: [dripNotification.sendgrid_template_id],
    });

    const attachmentsArray = dripNotificationGroup.get('attachments') as UntypedFormArray;
    if (dripNotification.attachments && dripNotification.attachments.length) {
      dripNotification.attachments.forEach(attachment => {
        attachmentsArray.push(this.initAttachment(attachment));
      });
    }

    return dripNotificationGroup;
  }

  private initAttachment(attachment: AppFile) {
    return this.fb.group({
      id: [attachment.id],
      name: [attachment.name],
      extension: [attachment.extension],
      size: [attachment.size],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    this.formSubmitted = true;
    if (form.invalid) {
      form.markAllAsTouched();
      console.error('Invalid campaign form.');
      return false;
    }

    const data        = this.getFormData(form.value, this.pickedFiles);
    this.isSubmitting = true;
    if (this.editorType === 'create') {
      return this.dripCampaignService.store(data).pipe(finalize(() => this.isSubmitting = false)).subscribe(
        () => this.handleFormResult(this.translate.instant('CONFIG.drip-campaign.editor.success-create')),
        error => this.handleFormError(error),
      );
    }

    if (this.editorType === 'edit') {
      return this.dripCampaignService.update(this.dripCampaignId, data)
        .pipe(finalize(() => this.isSubmitting = false))
        .subscribe(
          () => this.handleFormResult(this.translate.instant('CONFIG.drip-campaign.editor.success-edit')),
          error => this.handleFormError(error),
        );
    }
  }

  private handleFormResult(message: string) {
    this.toastr.success(message);
    this.router.navigate(['/drip-campaigns']);
  }

  private handleFormError(error: any) {
    console.error(error);
  }

  get dripNotificationsArray() {
    return this.form.get('drip_notifications') as UntypedFormArray;
  }

  public removeDripNotification(index: number) {
    this.dripNotificationsArray.removeAt(index);
  }

  public channelChanged($event: Event, index: number) {
    if (this.dripNotificationsArray.at(index).get('channel').value === 'sms') {
      this.dripNotificationsArray.at(index).get('subject').setValue(null);
      this.dripNotificationsArray.at(index).get('subject').setValidators([]);
    } else {
      this.dripNotificationsArray.at(index).get('fromable_type').setValue(null);
      this.dripNotificationsArray.at(index).get('fromable_type').updateValueAndValidity();
      this.fromableTypeChanged(index);
      this.dripNotificationsArray.at(index).get('subject').setValidators([Validators.required]);
    }
    this.dripNotificationsArray.at(index).get('subject').updateValueAndValidity();
  }

  public statusableTypeChanged(newStatusableType: 'status' | 'payment_status' | 'call_status') {
    this.form.get('statusable_id').setValue(null);
    this.form.get('statusable_id').updateValueAndValidity();
  }

  public fromableTypeChanged(index: number) {
    this.dripNotificationsArray.at(index).get('fromable_id').setValue(null);
    this.dripNotificationsArray.at(index).get('fromable_id').updateValueAndValidity();
  }

  public addVariable($event, index: number) {
    const content = this.dripNotificationsArray.at(index).get('body').value || '';
    this.dripNotificationsArray.at(index).get('body').setValue(content + $event.target.innerText + ' ');
  }

  public onAttachmentsChange($event, index: number) {
    const fileList: FileList       = ($event.target as HTMLInputElement).files;
    const files: Array<PickedFile> = [];
    for (let i = 0; i < fileList.length; i++) {
      const file      = fileList[i];
      const fileName  = file.name;
      const lastDot   = fileName.lastIndexOf('.');
      const name      = fileName.substring(0, lastDot);
      const extension = fileName.substring(lastDot + 1);
      files.push({name, extension, type: file.type, size: file.size, file});
    }
    this.pickedFiles[index] = files;
  }

  private getFormData(formValue, pickedFiles: Array<any>): FormData {
    const formData = MainBaseApiService.getFormData(formValue);
    const indexKey = Object.keys(pickedFiles);

    for (const index of indexKey) {
      for (const picked of pickedFiles[index]) {
        formData.append('new_attachments[' + index + '][]', picked.file, picked.name + '.' + picked.extension);
      }
    }

    return formData;
  }

  public updateFileName($event, keyIndex: number, fileIndex: number) {
    this.pickedFiles[keyIndex][fileIndex].name = $event.target.value;
  }

  public removeAttachment(dripIndex: number, index: number) {
    const notificationAttachments = this.dripNotificationsArray.at(dripIndex).get('attachments') as UntypedFormArray;
    notificationAttachments.removeAt(index);
  }

  public removeLocalAttachment(keyIndex: number, fileIndex: number) {
    this.pickedFiles[keyIndex].splice(fileIndex, 1);
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.products = result.data;
      });
  }

  private fetchCaseCreditorProducts(): void {
    this.isLoading++;
    this.caseCreditorService.indexProducts({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.caseCreditorProducts = result.data);
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.packagers = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }
}
