<h1 class="pb-5">{{ "REPORTS.financial-reports.title" | translate }}</h1>
<div class="row px-1">
  <div class="col-md-4" style="padding: 0">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="card-title text-primary">{{ "REPORTS.financial-reports.list.breakage-report.title" | translate }}</h2>
      </div>
      <div class="card-body">
        <p>
          <small>{{ "REPORTS.financial-reports.list.breakage-report.description" | translate }}</small>
        </p>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary" (click)="generateBreakageReport()"
                  [disabled]="generatingBreakageReport">
            {{ "REPORTS.financial-reports.list.breakage-report.actions.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ "REPORTS.financial-reports.list.payment-received-report.title" | translate }}</h2>
      </div>
      <div class="card-body">
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary" (click)="paymentReceivedModal($event)">
            {{ "REPORTS.financial-reports.list.payment-received-report.actions.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3 offset-1" style="padding: 0">
    <div class="card mb-4 h-100">
      <div class="card-header">
        <h2 class="card-title text-primary">
          {{ "REPORTS.financial-reports.jobs-list.title" | translate }}
        </h2>
        <div class="text-right" style="float: right">
          <button type="button" mat-icon-button color="primary" (click)="fetchQueuedJobs()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div *ngIf="isLoadingJobs">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div [hidden]="isLoadingJobs"></div>
        <div *ngIf="!appJobs.length" class="pt-5">
          <h3>{{ "REPORTS.financial-reports.jobs-list.no-items" | translate }}</h3>
        </div>
        <mat-list *ngIf="appJobs.length">
          <mat-list-item *ngFor="let appJob of appJobs">
            <div matLine class="text-left">
              <span *ngIf="!appJob.user_id">{{ "SHARED.system" | translate }}</span>
              <span *ngIf="appJob.user_id">
                {{ appJob.user.first_name + ' ' + appJob.user.last_name }}
              </span>
              <span *ngIf="appJob.processing_start_at">
                {{"REPORTS.financial-reports.jobs-list.status.progress" | translate }}&nbsp;
              </span>
              <span *ngIf="!appJob.processing_start_at">
                {{ "REPORTS.financial-reports.jobs-list.status.pending" | translate }}&nbsp;
              </span>
              <span>{{ appJob.label }}</span>
            </div>
            <div *ngIf="appJob.progress > 0" matLine>
              <mat-progress-bar mode="determinate" [value]="appJob.progress"></mat-progress-bar>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>

  <div class="col-md-4 mt-4" style="padding: 0">
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ "REPORTS.financial-reports.list.sales-report.title" | translate }}</h2>
      </div>
      <div class="card-body">
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary" (click)="salesReportModal($event)">
            {{ "REPORTS.financial-reports.list.sales-report.actions.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-4 mt-4" >
    <div class="card mb-4 h-100">
      <div class="card-header text-center">
        <h2 class="cart-title text-primary mb-0">{{ "REPORTS.financial-reports.list.run-off-report.title" | translate }}</h2>
      </div>
      <div class="card-body">
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" mat-raised-button color="primary" (click)="runOffReportModal()">
            {{ "REPORTS.financial-reports.list.run-off-report.actions.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
