import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthPaymentComponent } from './auth-payment/auth-payment.component';
import { ClientPaymentComponent } from './client-payment/client-payment.component';
import { PaymentOutcomeComponent } from './client-payment/payment-outcome/payment-outcome.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { SepaPaymentComponent } from './sepa-payment/sepa-payment.component';
import { ClientPaymentRedirectComponent } from './client-payment-redirect/client-payment-redirect.component';

const routes: Routes = [
  {
    path: 'client', children: [
      {
        path:     'cases',
        children: [
          {path: ':caseUuid/payments', component: AuthPaymentComponent},
        ],
      },
      {
        path:     'payments',
        children: [
          {path: 'confirm', component: PaymentConfirmationComponent},
        ],
      },
      // Old Routes
      {
        path: 'payment', children: [
          {path: '', component: ClientPaymentRedirectComponent},
          // {path: '', component: ClientPaymentComponent, data: {paymentType: 'fee'}},
          {path: 'verify', component: ClientPaymentComponent, data: {paymentType: 'verify'}},
          {path: 'outcome', component: PaymentOutcomeComponent},
        ],
      },
      {
        path: 'sepa', children: [
          {path: '', component: SepaPaymentComponent},
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PaymentRoutingModule {
}
