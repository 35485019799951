import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { DialerTriggersComponent } from './dialer-config-triggers/dialer-triggers.component';
import { DialerListsComponent } from './dialer-configuration/dialer-lists.component';
import { DialerRoutingModule } from './dialer-routing.module';
import { DialerComponent } from './dialer.component';

@NgModule({
  declarations: [
    DialerComponent,
    DialerListsComponent,
    DialerTriggersComponent,
  ],
  imports:      [
    SharedModule,
    DialerRoutingModule,
  ],
})
export class DialerModule {
}
