<div class="container-fluid payment-page-wrap client-upload">
  <div class="container col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div *ngIf="!isLoadingOrder && iFrameSrcSecured">
          <iframe #iframe class="mymoid-iframe" [src]="iFrameSrcSecured" frameborder="0" width="100%"
                  height="800"></iframe>
        </div>

        <div *ngIf="isLoadingOrder" class="d-flex justify-content-center align-items-center h-100">
          <div>
            <mat-spinner style="margin: auto"></mat-spinner>
          </div>
        </div>

      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 col-icons">
        <p class="pb-3"><i class="fas fa-lock"></i>Pago seguro.</p>
        <p class="pb-3"><i class="fas fa-shield-alt"></i>Su información es segura.</p>
        <p class="pb-1"><i class="fab fa-cc-visa"></i>Aceptamos Visa/Mastercard</p>
        <img src="assets/img/visa-master.png" alt="" width="100" style="padding-top: 50px">
      </div>
    </div>
  </div>
</div>
<div class="payment-page-footer">
  <div class="container footer-links" style="border-bottom: 1px solid #e7eaf3;">
    <div class="row">
      <div class="col-lg-3 mb-7 mb-lg-0 pb-5 pb-lg-0">
        <div class="d-inline-flex align-self-start flex-column h-100">
          <a class="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
             href="https://{{ generalSiteUrl }}/index.php">
            <img class="img-fluid" src="assets/img/logos/devuelve_logo_blue.png" alt="Logo" width="150">
          </a>
          <div class="mt-3">
            <a href="https://www.confianzaonline.es/empresas/deudafix.htm" target="_blank"
               style="padding-left: 10px">
              <img src="assets/img/3rdparty/ecommerceeu-confianza-fondo-claro-100.png" width="130px">
            </a>
          </div>
          <div class="mt-3">
            <a href="https://www.boe.es/buscar/mediadores.php?campo%5B0%5D=NOMBRE&amp;dato%5B0%5D=DEUDAFIX&amp;operador%5B0%5D=and&amp;campo%5B1%5D=ID_PROV_TRABAJO&amp;dato%5B1%5D=&amp;page_hits=50&amp;accion=Buscar"
               target="_blank" style="padding-left: 0px">
              <img src="assets/img/3rdparty/ministerio-justicia.jpg" width="155px"></a>
            <p style="font-size: 13px!important; width: 155px">Registro del Ministerio De Justicia</p>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4 mb-md-0">
        <h4 class="h6 font-weight-semi-bold">Recursos</h4>
        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/registrarse.php">
              Acceso cliente
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/quejas.php">
              Quejas
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/blog.php">
              Noticias
            </a>
          </li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4 mb-md-0">
        <h4 class="h6 font-weight-semi-bold">Empresa</h4>
        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/aviso_legal.php">
              Aviso legal
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/condiciones.php">
              Condiciones generales
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/privacidad.php">
              Privacidad
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/cookies.php">
              Cookies
            </a>
          </li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-sm-4 col-md-3 col-lg-2 mb-4 mb-md-0">
        <h4 class="h6 font-weight-semi-bold">Empleo</h4>
        <!-- List Group -->
        <ul class="list-group list-group-flush list-group-borderless mb-0">
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/careers.php">
              Legal
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/careers.php">
              Tecnología
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/careers.php">
              Finanzas
            </a>
          </li>
          <li>
            <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/careers.php">
              Operaciones
            </a>
          </li>
        </ul>
        <!-- End List Group -->
      </div>

      <div class="col-md-3 col-lg-2">
        <h4 class="h6 font-weight-semi-bold">Contáctenos</h4>

        <!-- Address -->
        <address>
          <ul class="list-group list-group-flush list-group-borderless mb-0">
            <li>
              <a class="list-group-item list-group-item-action" href="https://{{ generalSiteUrl }}/contactanos.php">
                Contacto
              </a>
            </li>
            <li><a class="list-group-item list-group-item-action" href="#">Tel. {{ companyPhoneLegal }}</a></li>
            <li>
              <a class="list-group-item list-group-item-action">{{ companyAddress }}</a>
            </li>
          </ul>
        </address>

        <!-- Social Networks -->
        <div class="row">
          <ul class="list-inline text-sm-left mb-0">
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://www.facebook.com/deudafix/">
                <span class="fab fa-facebook-f btn-icon__inner"></span>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://www.instagram.com/deudafix/">
                <span class="fab fa-instagram btn-icon__inner"></span>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://twitter.com/deudafix">
                <span class="fab fa-twitter btn-icon__inner"></span>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-sm btn-icon btn-soft-secondary btn-bg-transparent rounded-circle"
                 href="https://www.linkedin.com/company/deudafix">
                <span class="fab fa-linkedin btn-icon__inner"></span>
              </a>
            </li>
          </ul>
        </div>
        <!-- End Social Networks -->

        <!-- End Address -->
      </div>
    </div>
  </div>
  <div class="container footer-text">
    <p class="mb-4">{{ companyNameLegal }}, está inscrito como Mediador Concursal en el Ministerio de
      Justicia, número de
      registro 1953. {{ companyNameLegal }}, CIF: B88315890, inscrita en el Registro Mercantil de Madrid,
      Hoja M-688444, Tomo 38720, Folio 164.</p>
    <p class="mb-4" style="color: #1E88E5">Más de 500,000 deudas reducidas en dos países, cambiamos vidas.</p>
    <p class="mb-4">
      Siguiendo nuestra política de buenas practicas y cumplimiento, para que usted pueda acogerse a la Ley de la
      Segunda Oportunidad y obtener la cancelación de sus deudas, debe: (i) tener deudas calificadas como no
      garantizadas, por importes de entre un mínimo de 15.000 € y máximo de 5.000.000 €, además (ii) dichas deudas deben
      estar reconocidas y distribuidas entre un mínimo de dos o más acreedores.
    </p>
    <p class="mb-0">
      Cumplidos los requisitos legales del procedimiento y demostrada la buena fé del postulante, la cancelación de
      hasta un 75% de sus deudas es realista, sin embargo, el monto final de la cancelación de la deuda para cada
      cliente difiere según sus circunstancias financieras individuales y está en todo caso sujeto a la aprobación de
      sus acreedores o los tribunales.
    </p>
  </div>
</div>
