import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditorEntity } from '../../../../../../../../../_base-shared/models/Case/CaseCreditorEntity';
import { Entity } from '../../../../../../../../../_base-shared/models/Entity/Entity';
import { EntityService } from '../../../../../address-book/solicitors/entity.service';
import { CaseCreditorService } from '../../../../case-creditor.service';

@Component({
  selector: 'app-solicitor-modal',
  templateUrl: './case-creditor-entity-editor.component.html',
  styles: [],
})
export class CaseCreditorEntityEditorComponent implements OnInit {
  @Input() entityType: 'solicitor' | 'entity_administrator' | 'notary';
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public case: Case;
  public caseCreditorEntities: CaseCreditorEntity;
  public form: UntypedFormGroup;
  public searchControl: UntypedFormControl = new UntypedFormControl();
  public dataSource: MatTableDataSource<any>;
  public isLoading                         = 0;
  public isSubmitting                      = false;
  public displayedColumns: Array<string>   = [];
  public selection                         = new SelectionModel<any>(true, []);
  public paginatorConfig                   = {
    pageIndex: 0,
    pageSize: 6,
    length: 1,
  };

  constructor(private fb: UntypedFormBuilder,
              private toast: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<CaseCreditorEntityEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private entityService: EntityService,
              private caseCreditorService: CaseCreditorService,
  ) {
    this.entityType           = this.data.entityType;
    this.case                 = this.data.case;
    this.caseCreditorEntities = this.data.caseCreditorEntities;
    if (!this.caseCreditorEntities) {
      this.caseCreditorEntities                  = new CaseCreditorEntity();
      this.caseCreditorEntities.administrator_id = null;
      this.caseCreditorEntities.solicitor_id     = null;
      this.caseCreditorEntities.notary_id        = null;
    }
  }

  ngOnInit(): void {
    this.setDisplayedColumns(this.entityType);
    this.fetchLegalEntities(this.entityType);
    this.buildForm(this.caseCreditorEntities);

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.fetchLegalEntities(this.entityType);
    });
  }

  private setDisplayedColumns(entityType: string): void {
    const columns = ['select'];
    if (entityType === 'solicitor') {
      columns.push('name');
      columns.push('address');
    } else {
      columns.push('name');
      columns.push('address');
    }

    this.displayedColumns = columns;
  }

  private buildForm(caseCreditorEntity: CaseCreditorEntity): void {
    this.form = this.fb.group({
      notary_id: [caseCreditorEntity.notary_id, Validators.required],
      solicitor_id: [caseCreditorEntity.solicitor_id, Validators.required],
      administrator_id: [caseCreditorEntity.administrator_id, Validators.required],
    });
  }

  public paginatorChange($event: PageEvent): void {
    this.dataSource                = new MatTableDataSource<Entity>([]);
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchLegalEntities(this.entityType);
  }

  private fetchLegalEntities(entityType: 'solicitor' | 'entity_administrator' | 'notary'): void {
    this.selection.clear();
    this.dataSource = new MatTableDataSource<Entity>([]);
    const request   = {
      entity_type: entityType,
      search: this.form ? this.form.get('search').value : null,
      per_page: this.paginatorConfig.pageSize,
      page: this.paginatorConfig.pageIndex + 1,
    };
    this.isLoading++;
    this.entityService.index(request).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.dataSource             = new MatTableDataSource<Entity>(result.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = result.meta.total;
    });
  }

  public closeModal(value): void {
    this.dialogRef.close(value);
  }

  public selectEntity(entity): void {
    this.form.get(this.entityType + '_id').patchValue(entity.id);
    this.form.get(this.entityType + '_id').updateValueAndValidity();
    this.selection.clear();
    this.selection.toggle(entity);
  }

  public submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.caseCreditorService.updateCaseCreditorEntity(this.case.id, this.caseCreditorEntities.case_creditor_id, this.form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        next => {
          this.toast.success(
            this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.success', {
              entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
            }));
          this.dialogRef.close({data: this.selection.selected[0]});
        },
        error => {
          this.toast.error(
            this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
              entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
            }));
        }
      );
  }

}
