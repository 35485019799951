import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';

import { Task } from '../../../../../_base-shared/models/Task/Task';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends MainBaseApiService {

  public show(taskId: number, params = {}): Observable<LaravelResourceResponse<Task>> {
    return this.http.get<LaravelResourceResponse<Task>>(this.apiUrl + '/tasks/' + taskId, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  // TODO: remove after response refactor
  indexAllUserTasks(userId: number, params = {}): Observable<LaravelResourceResponse<Array<Task>>> {
    return this.http.get<LaravelResourceResponse<Array<Task>>>(this.apiUrl + '/users/' + userId + '/tasks', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  indexUserTasks(userId: number, params = {}): Observable<LaravelResourceResponse<Array<Task>>> {
    params = MainBaseApiService.convertFiltersForRequest(params, 'get');
    return this.http.get<LaravelResourceResponse<Array<Task>>>(this.apiUrl + '/users/' + userId + '/tasks', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCaseTasks(caseId: number, params = {}): Observable<LaravelResourceResponse<Array<Task>>> {
    return this.http.get<LaravelResourceResponse<Array<Task>>>(this.apiUrl + '/cases/' + caseId + '/tasks', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  storeCaseTask(caseId: number, task: Task, params = {}): Observable<LaravelResourceResponse<Task>> {
    return this.http.post<LaravelResourceResponse<Task>>(this.apiUrl + '/cases/' + caseId + '/tasks', task, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  showCaseTask(caseId: number, taskId: number, params = {}): Observable<LaravelResourceResponse<Task>> {
    return this.http.get<LaravelResourceResponse<Task>>(this.apiUrl + '/cases/' + caseId + '/tasks/' + taskId, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  updateCaseTask(caseId: number, taskId: number, task: Task, params = {}): Observable<LaravelResourceResponse<Task>> {
    return this.http.patch<LaravelResourceResponse<Task>>(
      this.apiUrl + '/cases/' + caseId + '/tasks/' + taskId, task, {params},
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  destroyCaseTask(caseId: number, taskId: number, params = {}): Observable<LaravelResourceResponse<any>> {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/tasks/' + taskId, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  completeCaseTask(caseId: number, taskId: number, requestData, params = {}): Observable<LaravelResourceResponse<Task>> {
    return this.http.patch<LaravelResourceResponse<Task>>(
      this.apiUrl + '/cases/' + caseId + '/tasks/' + taskId + '/completed_at', requestData, {params},
    )
      .pipe(catchError(response => this.handleError(response)));
  }

  parseTaskVariables(caseId: number, data): Observable<LaravelResourceResponse<Task>> {
    return this.http.post<LaravelResourceResponse<Task>>(this.apiUrl + '/cases/' + caseId + '/parse-task-variables', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  loadMissing(caseId: number, taskId: number, params = {}): Observable<LaravelResourceResponse<Task>> {
    return this.http.get<LaravelResourceResponse<Task>>(this.apiUrl + '/cases/' + caseId + '/tasks/' + taskId + '/relations')
      .pipe(catchError(response => this.handleError(response)));
  }

  exportTasks(filters, userId): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + `/users/${ userId }/tasks/export`, {params: filters})
      .pipe(catchError(response => this.handleError(response)));
  }

}
