<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoading" class="row" [formGroup]="smsForm">
  <div class="col-12">
    <h1 *ngIf="editorType === 'edit'" class="pl-2">{{"CONFIG.sms-template.edit-sms-template" | translate}}</h1>
    <h1 *ngIf="editorType !== 'edit'" class="pl-2">{{"CONFIG.sms-template.add-sms-template" | translate}}</h1>
  </div>
  <div class="col-8">
    <div class="card shadow mb-4">
      <div class="card-body">
        <div class="row pb-4">
          <div class="col-12">
            <label for="label">{{"CONFIG.sms-template.label" | translate}}</label>
            <input class="form-control" type="text" id="label" formControlName="label">
          </div>
        </div>
        <!-- Description -->
        <div class="row pb-4">
          <div class="col-12">
            <label for="description">{{"CONFIG.sms-template.description" | translate}}</label>
            <input class="form-control" type="text" id="description" formControlName="description">
          </div>
        </div>
        <!-- Subject -->
        <div class="row pb-4">
          <div class="col-12">
            <label for="subject">{{"CONFIG.sms-template.subject" | translate}}</label>
            <input class="form-control" type="text" id="subject" formControlName="subject">
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-12">
            <label for="content">{{"CONFIG.sms-template.content" | translate}}</label>
            <textarea rows="7" class="form-control" id="content" formControlName="content"></textarea>
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-12">
            <label for="status">{{"CONFIG.sms-template.status" | translate}}</label>
            <select class="form-control" id="status" formControlName="active" >
              <option value="1">{{"AFFILIATES.active" | translate}}</option>
              <option value="0">{{"AFFILIATES.inactive" | translate}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
           <button mat-raised-button color="primary" (click)="submit()">{{"SHARED.submit" | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 mt-sm-4 mt-md-0">
    <div class="card shadow">
      <div class="card-header">{{"CONFIG.sms-template.variables" | translate}}</div>
      <div class="card-body">
        <h3 class="text-center" *ngFor="let template of templateVariables">
          <code (click)="addVariable($event)">{{ template.label }}</code>
          <span class="small" *ngIf="template.description"> - {{ template.description }}</span>
        </h3>
      </div>
    </div>
  </div>
</div>
