import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-staff-navigation',
  templateUrl: './staff-navigation.component.html',
  styleUrls:   ['./staff-navigation.component.scss'],
})
export class StaffNavigationComponent implements OnInit {
  @Input() authUser: User;
  public unapproved: boolean;

  constructor(public globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.unapprovedCreditors$.subscribe(unapproved => this.unapproved = unapproved);
  }

  public userIsAMami() {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }
}
